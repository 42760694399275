import Dialog from "./dialog";
import {useEffect, useState} from "react";
import {recordEvent} from "../libs/analytics";

export function IframeDialog(props) {

    const { title, url, open, ...rest } = props;
    const [prevState, setPrevState] = useState(false);


    useEffect(() => {

        if(prevState === open) return;

        if(open){
            // recordEvent("click-session-dialog-open")

        }else{
            // recordEvent("click-session-dialog-close")
        }

        setPrevState(open);
    }, [open, prevState])

    return (
        <Dialog open={open} {...rest}>

            {/*//todo - we need a key and onboarding - and full brief*/}
            {/*You schedule a session in Wordly*/}
            {/*https://portal.wordly.ai/#/sessions*/}
            {/*Click "+ Add Session"*/}
            {/*When scheduling the test session, make sure to set it as permanent.*/}
            {/*Once scheduled, you will generate a session.*/}
            {/*Grab the Session ID and passcode.*/}

            {/*Title:HaematologyMasterclassTest*/}
            {/*Presenter:Chris*/}
            {/*Session ID: JYJB-8823*/}
            {/*QLHW-2730*/}
            {/*Passcode:754626*/}

            {/*Next, you/they can download the Wordly mobile application:*/}
            {/*https://apps.apple.com/us/app/wordly-inc/id1507412881*/}
            {/*https://play.google.com/store/apps/details?id=tech.wordly.android.present*/}


            {/*On the mobile application, no sign-in is required.*/}
            {/*You enter the Session ID, the Passcode, a name and then click*/}
            {/*"Manage"*/}
            {/*Once you click "Manage"*/}
            {/*You will then be given the option to "Start" (or "Restart" if you have previously started it)*/}
            {/*The first time you will likely need to give permission to use the microphone.*/}
            {/*After that, you will be able to speak into the mobile phone and see the resulting translation in the iframe.*/}

            <iframe
                title={title}
                style={{ marginTop: "45px", border: "none", minHeight: "75vh" }}
                src={url}
                // width="100%" height="100%"
                scrolling="no"
                allowFullScreen={true}
            />
            <iframe src="https://attend.wordly.ai/frame/PHDP-6653?&fgcolor=FFFFFF&bgcolor=33ACFF"
                    style={{marginTop: "0px", border: "none", minHeight: "4vh"}}
                    scrolling="no"
                    name="myiFrame"
                    width="100%" allowFullScreen/>
        </Dialog>
    );
}
