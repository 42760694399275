import { ImageMap } from '@qiuz/react-image-map';
import React, {useEffect, useState} from 'react';
import {Backdrop, Box, CircularProgress, Divider, Fade, Hidden, List, ListItem, ListItemText} from '@material-ui/core';
import { navigate } from '@reach/router'
import { useIntercom } from 'react-use-intercom';
import {isTokenValid, logout, parseToken} from "../libs/authLib";
import {ExternalLinkDialog} from "../components/ExternalLinkDialog";

import {ExternalLinkDialogCrysvita} from "../components/ExternalLinkDialogCrysvita";
import {ExternalLinkDialogXlhlink} from "../components/ExternalLinkDialogXlhlink";

import {MedicalInfoAeMessageDialog} from "../components/MedicalInfoAeMessageDialog";
import {AgendaDialog} from "../components/AgendaDialog";
import {FaqDialogDisplay} from "../components/FaqDialogDisplay";
import {PointsDialogDisplay} from "../components/PointsDialogDisplay";
import {PosterChooserDialog} from "../components/PosterChooserDialog";
import {BioDialog} from "../components/BioDialog";
import {CertificateDialog} from "../components/CertificateDialog";
import {MedinfoRequest} from "../components/MedinfoRequest";
import {AdverseDialog} from "../components/AdverseDialog";
import {CookiePolicy} from "../components/CookiePolicy";
import {RecentPublications} from "../components/Recentpublications";
import {ResourceGallery} from "../components/ResourceGallery";

import {TechnicalHelp} from "../components/TechnicalHelp";


import {ExternalLinkChooserDialog} from "../components/ExternalLinkChooserDialog";
import * as analytics from "../libs/analytics";
// import {PDFDialog} from "../components/PDFDialog";
import {IframeDialog} from "../components/IframeDialog";
import OARoomImageLink from "../components/OARoomImageLink";
import URLImageLink from "../components/URLImageLink";
import LiveImageLink from "../components/LiveImageLink";
import * as Sentry from "@sentry/react";
import OARoomListLink from "../components/OARoomListLink";
import OARoomListLinkBuild from "../components/OARoomListLinkBuild";
import {VideoDialog} from "../components/VideoDialog";
import {OAtubeDialog} from "../components/OAtubeDialog";
import {MedicalInfoAeMessageDialogSessions} from "../components/MedicalInfoAeMessageDialogSessions";
import * as PropTypes from "prop-types";
import {OAVideosDialog} from "../components/OAVideosDialog";
import {OAVideosDialog2023} from "../components/OAVideosDialog2023";
// import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

// legacy components - kept for use in future projects
// import { IframeDialog } from '../components/IframeDialog';

// import {PeopleDialog} from "../components/PeopleDialog";
// import {FaqDialog} from "../components/FaqDialog";
// import {getLinkData} from "../libs/openAudienceLib";

// import LiveBanner from "../components/LiveBanner";
// import Link from "@material-ui/core/Link";
// import TimeBasedImage from "../components/TimeBasedImage";

//INFO - mouseflow - find api key in index.html
//INFO - Sentry.init - find  api key in App.jsx
//INFO - intercom- find  api key in ??

//todo
// add caption liveimage to each video
// fix iframe formatting
// full cookie control


//HARRY

//CHRIS
//todo - only run "yarn start" in "site"
//todo - test analytics on desktop and mobile for ALL OA links- need input from client
//todo - rename images to match project id?
//todo - final cookie and data storage audit - approved?
//todo - mailto - check for "_self" replace with "_blank"

//todo - we've redeployed to frankfurt region - this will reconnection of DNS.
//todo - make sure all images served from ff aws
//GEORGE
//todo - preload not working? Exhibitions modal is a good example - use app.jsx
//todo - special shaped css borders - OR... hover pngs
//todo - css borders on live links

//NOT IMPORTANT
//todo - smaller live banner on mobile
//todo - check for console errors and clean up
//todo - button to hide/show wordly iframe (at base of OA modal)

const gridx=1;

const mapArea = [
    // {
    //     "id": "video",
    //     "width": "23.0%",
    //     "height": "23.0%",
    //     "left": "3.0%",
    //     "top": "12.0%",
    //     "href": "101"
    // },
    {
        "id": "OAvideo",
        "width": "22.3%",
        "height": "33.4%",
        "left": "50.5%",
        "top": "47.8%",
        "href": "101"
    },
    {
        "id": "OAvideo2023",
        "width": "22.3%",
        "height": "33.4%",
        "left": "26.4%",
        "top": "47.8%",
        "href": "101"
    },
    // {
    //     "id": "points",
    //     "width": "31.2%",
    //     "height": "4.8%",
    //     "left": "28.9%",
    //     "top": "32.9%",
    //     "href": "11"
    // },
    // {
    //     "id": "agenda",
    //     "width": "20.0%",
    //     "height": "49.0%",
    //     "left": "78.6%",
    //     "top": "39.6%",
    //     "href": "01"
    //
    // },
    // {
    //     "id": "breakouts",
    //     "width": "11.0%",
    //     "height": "10.0%",
    //     "left": "20.7%",
    //     "top": "44.0%",
    //     "href": "01"
    // },
    // {
    //     "id": "breakouts2",
    //     "width": "5.3%",
    //     "height": "10.9%",
    //     "left": "0.9%",
    //     "top": "44.0%",
    //     "href": "01"
    // },
    // {
    //     "id": "workshops2",
    //     "width": "5.3%",
    //     "height": "10.9%",
    //     "left": "5.9%",
    //     "top": "44.0%",
    //     "href": "01111"
    // },

    // {
    //     "id": "plenary",
    //     "width": "10.8%",
    //     "height": "10.0%",
    //     "left": "43.8%",
    //     "top": "44.0%",
    //     "href": "05"
    //
    // },
    // {
    //     "id": "workshops",
    //     "width": "11.0%",
    //     "height": "10.0%",
    //     "left": "66.5%",
    //     "top": "44.0%",
    //     "href": "07"
    // },
    // {
    //     "id": "resource",
    //     "width": "16.7%",
    //     "height": "16.0%",
    //     "left": "7.2%",
    //     "top": "24.3%",
    //     "href": "07"
    // },
    // {
    //     "id": "publications",
    //     "width": "16.7%",
    //     "height": "16.0%",
    //     "left": "29.0%",
    //     "top": "24.3%",
    //     "href": "07"
    // },
    // {
    //     "id": "crysvita",
    //     "width": "16.7%",
    //     "height": "16.0%",
    //     "left": "53.5%",
    //     "top": "24.3%",
    //     "href": "07"
    // },
    // {
    //     "id": "xlh",
    //     "width": "5.1%",
    //     "height": "12.0%",
    //     "left": "67.0%",
    //     "top": "67.5%",
    //     "href": "07"
    // },
    // {
    //     "id": "faq",
    //     "width": "4.0%",
    //     "height": "1.8%",
    //     "left": "10.5%",
    //     "top": "70.8%",
    //     "href": "07"
    // },
    // {
    //     "id": "bio",
    //     "width": "6.0%",
    //     "height": "1.8%",
    //     "left": "10.5%",
    //     "top": "73.2%",
    //     "href": "07"
    // },
    // {
    //     "id": "survey",
    //     "width": "5.5%",
    //     "height": "1.8%",
    //     "left": "10.5%",
    //     "top": "75.9%",
    //     "href": "07"
    // },
    // {
    //     "id": "certificate",
    //     "width": "8.5%",
    //     "height": "1.8%",
    //     "left": "10.5%",
    //     "top": "78.8%",
    //     "href": "07"
    // },
    // {
    //     "id": "contact",
    //     "width": "6.0%",
    //     "height": "1.8%",
    //     "left": "20.5%",
    //     "top": "70.8%",
    //     "href": "07"
    // },
    {
        "id": "medicalInfoAe",
        "width": "16.7%",
        "height": "16.0%",
        "left": "42.0%",
        "top": "24.3%",
        "href": "07"
    },
    // {
    //     "id": "chat",
    //     "width": "4.5%",
    //     "height": "1.8%",
    //     "left": "22.0%",
    //     "top": "75.9%",
    //     "href": "07"
    // },
    // //////////////////////////////////////////////////////////////
    {
        "id": "medicalInfoAeBG",
        "width": "2.25%",
        "height": "6.0%",
        "left": "10.0%",
        "top": "92.5%",
        "href": "07"
    },

    // {
    //     "id": "medicalInfoAeBE",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "10.0%",
    //     "top": "92.5%",
    //     "href": "07"
    // },


    {
        "id": "medicalInfoAeCZ",
        "width": "2.25%",
        "height": "6.0%",
        "left": "12.43%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeGR",
        "width": "2.25%",
        "height": "6.0%",
        "left": "14.86%",
        "top": "92.5%",
        "href": "07"
    },


    {
        "id": "medicalInfoAeHR",
        "width": "2.25%",
        "height": "6.0%",
        "left": "17.29%",
        "top": "92.5%",
        "href": "07"
    },


    {
        "id": "medicalInfoAeHU",
        "width": "2.25%",
        "height": "6.0%",
        "left": "19.72%",
        "top": "92.5%",
        "href": "07"
    },

    {
        "id": "medicalInfoAeIL",
        "width": "2.25%",
        "height": "6.0%",
        "left": "22.15%",
        "top": "92.5%",
        "href": "07"
    },


    {
        "id": "medicalInfoAePO",
        "width": "2.25%",
        "height": "6.0%",
        "left": "24.58%",
        "top": "92.5%",
        "href": "07"
    },

    {
        "id": "medicalInfoAeRO",
        "width": "2.25%",
        "height": "6.0%",
        "left": "27.01%",
        "top": "92.5%",
        "href": "07"
    },


    {
        "id": "medicalInfoAeSL",
        "width": "2.25%",
        "height": "6.0%",
        "left": "29.34%",
        "top": "92.5%",
        "href": "07"
    },


    {
        "id": "medicalInfoAeSK",
        "width": "2.25%",
        "height": "6.0%",
        "left": "31.87%",
        "top": "92.5%",
        "href": "07"
    },

    // //////////////////////////////////////////////////////////////
    // {
    //     "id": "medicalInfoAeAE",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "10.0%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    // {
    //     "id": "medicalInfoAeBE",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "12.43%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    // {
    //     "id": "medicalInfoAeBG",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "14.86%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    // {
    //     "id": "medicalInfoAeBH",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "17.29%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    // {
    //     "id": "medicalInfoAeCZ",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "19.72%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    // {
    //     "id": "medicalInfoAeDE",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "22.15%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    // {
    //     "id": "medicalInfoAeDK",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "24.58%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    // {
    //     "id": "medicalInfoAeES",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "27.01%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    // {
    //     "id": "medicalInfoAeFI",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "29.34%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    // {
    //     "id": "medicalInfoAeGR",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "31.87%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    // {
    //     "id": "medicalInfoAeHR",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "34.3%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    // {
    //     "id": "medicalInfoAeHU",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "36.73%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    // {
    //     "id": "medicalInfoAeIE",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "39.16%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    // {
    //     "id": "medicalInfoAeIL",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "41.59%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    // {
    //     "id": "medicalInfoAeKW",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "44.02%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    //
    // {
    //     "id": "medicalInfoAeLU",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "46.45%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    // {
    //     "id": "medicalInfoAeNL",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "48.88%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    // {
    //     "id": "medicalInfoAeNO",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "51.31%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    // {
    //     "id": "medicalInfoAeOM",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "53.74%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    // {
    //     "id": "medicalInfoAePO",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "56.17%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    // {
    //     "id": "medicalInfoAePT",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "58.60%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    //
    // {
    //     "id": "medicalInfoAeQA",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "61.3%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    //
    // {
    //     "id": "medicalInfoAeRO",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "63.46%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    //
    //
    // {
    //     "id": "medicalInfoAeSA",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "65.89%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    //
    // {
    //     "id": "medicalInfoAeSE",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "68.32%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
    //
    //
    //
    //
    // {
    //     "id": "medicalInfoAeSL",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "70.75%",
    //     "top": "92.5%",
    //     "href": "07"
    //
    // },
    //
    //
    //
    //
    // {
    //     "id": "medicalInfoAeSK",
    //     "width": "2.25%",
    //     "height": "6.0%",
    //     "left": "73.25%",
    //     "top": "92.5%",
    //     "href": "07"
    // },
/////////////////////////////////////////////////
    {
        "id": "privacy",
        "width": "4.0%",
        "height": "6.0%",
        "left": "76.25%",
        "top": "92.4%",
        "href": "07"
    },
    {
        "id": "cookie",
        "width": "3.0%",
        "height": "6.0%",
        "left": "81.0%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "adverse",
        "width": "14.9%",
        "height": "5.7%",
        "left": "84.75%",
        "top": "92.6%",
        "href": "07"
    },
    // {
    //     "id" : "exhibitions",
    //     "width": "24.4%",
    //     "height": "6.0%",
    //     "left": "73.1%",
    //     "top": "44.1%",
    //     "href": "06"
    // },
    // {
    //     "id": "faq",
    //     // "width": "8.7%",
    //     // "height": "8.0%",
    //     // "left": "11.2%",
    //     // "top": "68.0%",
    //         "width": "23.8%",
    //         "height": "6.0%",
    //         "left": "4.7%",
    //         "top": "43.5%",
    //     "href": "11"
    // },
    // {

    // {
    //     "id": "feedback",
    //         "width": "7.0%",
    //         "height": "8.0%",
    //         "left": "30.5%",
    //         "top": "70.0%",
    //         "href": "09"
    // },
    // {
    //     "id": "certificate",
    //     "width": "7.0%",
    //     "height": "8.0%",
    //     "left": "30.5%",
    //     "top": "79.0%",
    //     "href": "10"
    // },
    // {
    //     "id": "visitHemeHub",
    //     "width": "6.7%",
    //     "height": "13.1%",
    //     "left": "66.0%",
    //     "top": "55.5%",
    //     "href": "12"
    // }
    // {
    //     //not used
    //     "id": "chat",
    //     "width": "6.7%",
    //     "height": "13.1%",
    //     "left": "0.0%",
    //     "top": "55.5%",
    //     "href": "12"
    // }
    // {
    //     "id" : "iDontWantToWriteThisCodeItIs_a_TRAVESTY",
    //     "width": "17.0%",
    //     "height": "36.0%",
    //     "left": "62.8%",
    //     "top": "41.0%",
    //     "href": "10"
    // },
];

//not used
// const baseUrl = "https://openmeet.com/prostate_cancer_uk_summit_2021"


export default function Home() {

    const [loading, setLoading] = useState(2);
    const loaded = () => setLoading(count => count - 1);

    const [PointsDialogDisplayOpen, setPointsDialogDisplayOpen] = useState(false);
    const [agendaOpen, setAgendaOpen] = useState(false);
    const [PostersOpen, setPostersOpen] = useState(false);
    // parallel-sessions - just a link
    // exhibitions - just a link
    const [FaqDialogDisplayOpen, setFaqDialogDisplayOpen] = useState(false);
    const [medicalInfoAeOpen, setMedicalInfoAeOpen] = useState(false);
    const [feedbackSessionOpen, setFeedbackSessionOpen] = useState(false);
    const [pdfOpen, setPdfOpen] = useState(false);    // certificate
    const [visitHemeHubOpen, setvisitHemeHubOpen] = useState(false);

    const [bioOpen, setBioOpen] = useState(false);
    const [certificateOpen, setCertificateOpen] = useState(false);

    const [CookiePolicyOpen, setCookiePolicyOpen] = useState(false);

    const [crysvitaOpen, setCrysvitaOpen] = useState(false);
    const [xlhOpen, setXlhOpen] = useState(false);
    const [videoOpen, setVideoOpen] = useState(false);
    const [OAvideoOpen, setOAVideoOpen] = useState(false);
    const [OAvideoOpen2023, setOAVideo2023Open] = useState(false);
    const [resourceOpen, setResourceOpen] = useState(false);
    const [publicationOpen, setPublicationOpen] = useState(false);
    const [adverseOpen, setAdverseOpen] = useState(false);
    const [TechnicalHelpOpen, setTechnicalHelpOpen] = useState(false);
    // const [ medicalInfoAeAT_Open, set_medicalInfoAeAT_Open] = useState(false);
    // const [ medicalInfoAeBE_Open, set_medicalInfoAeBE_Open] = useState(false);
    // const [ medicalInfoAeCH_Open, set_medicalInfoAeCH_Open] = useState(false);
    // const [ medicalInfoAeCZ_Open, set_medicalInfoAeCZ_Open] = useState(false);
    // const [ medicalInfoAeDE_Open, set_medicalInfoAeDE_Open] = useState(false);
    const [iDontWantToWriteThisCodeItIs_a_TRAVESTY, setiDontWantToWriteThisCodeItIs_a_TRAVESTYOpen] = useState(false);
    const { boot, show } = useIntercom();

    // not used in this project
    // const [faqOpen, setFaqOpen] = useState(false);
    // const [certficateOpen, setCertficateOpen] = useState(false);
    // const [exhibitionOpen, setExhibitionOpen] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    useEffect(() => {
        if(!isTokenValid()) {
            logout()
        }else {
            const tokenParsed = parseToken();

            boot({
                userId: tokenParsed.code,
                email: tokenParsed.email
            })
        }
    }, [])

    useEffect(() => {
        //track entry
        analytics.recordEvent("navigate-enter-home")

        return () => {
            //track exit
            analytics.recordEvent("navigate-exit-home")
        }
    }, [])

    const onMapClick = async (area) => {
        if (area.id) {
            switch (area.id) {
                case "points": {
                    handleClickPointsDialogDisplay();
                    break;
                }
                case "agenda": {
                    handleClickAgenda();
                    break;
                }
                case "breakouts": {
                    handleClickBreakouts();
                    break;
                }
                case "breakouts2": {
                    handleClickBreakouts2();
                    break;
                }
                case "workshops": {
                    handleClickWorkshops();
                    break;
                }

                case "workshops2": {
                    handleClickWorkshops2();
                    break;
                }
                // case "parallel-sessions": {
                //     handleClickSessions();
                //     break;
                // }
                // case "exhibitions": {
                //     handleClickExhibitions();
                //     break;
                // }
                case "faq": {
                    handleClickFaqDialogDisplay();
                    break;
                }
                case "medicalInfoAe": {
                    handleClickMedicalInfoAe();
                    break;
                }
                case "feedback": {
                    // TODO - is this being recorded?
                    handleClickFeedback();
                    // show()
                    break;
                }
                case "certificate": {
                    handleClickCertificate();
                    break;
                }
                case "visitHemeHub": {
                    handleClickvisitHemeHub();
                    break;
                }
                case "chat": {
                    // TODO - can we record this? - and chat widget clicks?
                    handleClickChat();
                    // show()
                    break;
                }
                case "bio": {
                    handleClickBio();
                    break;
                }
                case "certificate": {
                    handleClickCertificate();
                    break;
                }
                case "crysvita": {
                        handleClickCrysvita();
                        break;
                }
                case "xlh": {
                        handleClickXlh();
                        break;
                    }
                case "video": {
                    handleClickVideo();
                    // setVideoOpen(true);
                    break;
                }
                case "OAvideo": {
                    handleClickOAVideo();
                    // setVideoOpen(true);
                    break;
                }
                case "OAvideo2023": {
                    handleClickOAVideo2023();
                    // setVideoOpen(true);
                    break;
                }
                case "resource": {
                    handleClickResource();
                    break;
                }

                case "publications": {
                    handleClickPublication();
                    break;
                }

                case "medicalInfoAeAE": {handleClickmedicalInfoAe_AE();break;}
                case "medicalInfoAeBE": {handleClickmedicalInfoAe_BE();break;}
                case "medicalInfoAeBG": {handleClickmedicalInfoAe_BG();break;}
                case "medicalInfoAeCZ": {handleClickmedicalInfoAe_CZ();break;}
                case "medicalInfoAeBH": {handleClickmedicalInfoAe_BH();break;}

                case "medicalInfoAeDE": {handleClickmedicalInfoAe_DE();break;}
                case "medicalInfoAeDK": {handleClickmedicalInfoAe_DK();break;}
                case "medicalInfoAeES": {handleClickmedicalInfoAe_ES();break;}
                case "medicalInfoAeFI": {handleClickmedicalInfoAe_FI();break;}
                case "medicalInfoAeGR": {handleClickmedicalInfoAe_GR();break;}


                case "medicalInfoAeHR": {handleClickmedicalInfoAe_HR();break;}
                case "medicalInfoAeHU": {handleClickmedicalInfoAe_HU();break;}
                case "medicalInfoAeIE": {handleClickmedicalInfoAe_IE();break;}
                case "medicalInfoAeIL": {handleClickmedicalInfoAe_IL();break;}
                case "medicalInfoAeLU": {handleClickmedicalInfoAe_LU();break;}

                case "medicalInfoAeNL": {handleClickmedicalInfoAe_NL();break;}
                case "medicalInfoAeNO": {handleClickmedicalInfoAe_NO();break;}
                case "medicalInfoAePO": {handleClickmedicalInfoAe_PO();break;}
                case "medicalInfoAePT": {handleClickmedicalInfoAe_PT();break;}
                case "medicalInfoAeQA": {handleClickmedicalInfoAe_QA();break;}


                case "medicalInfoAeRO": {handleClickmedicalInfoAe_RO();break;}
                case "medicalInfoAeSA": {handleClickmedicalInfoAe_SA();break;}
                case "medicalInfoAeSE": {handleClickmedicalInfoAe_SE();break;}
                case "medicalInfoAeSK": {handleClickmedicalInfoAe_SK();break;}
                case "medicalInfoAeSL": {handleClickmedicalInfoAe_SL();break;}

                case "medicalInfoAeKW": {handleClickmedicalInfoAe_KW();break;}
                case "medicalInfoAeOM": {handleClickmedicalInfoAe_OM();break;}

                case "privacy": {
                    handleClickPrivacy();
                    break;
                }

                case "cookie": {
                    handleClickCookie();
                    break;
                }
                case "adverse": {
                    handleClickAdverse();
                    break;
                }

                case "iDontWantToWriteThisCodeItIs_a_TRAVESTY": {
                    // TODO - think about life choices
                    handleClickiDontWantToWriteThisCodeItIs_a_TRAVESTY();
                    // navigate("/")
                }
            }
        }
    };

    // Replicate these function for each click -- it reuses the logic for both mobile and desktop
    const handleClickPointsDialogDisplay = () => {
        analytics.recordEvent("click-cpdCredits")
        setPointsDialogDisplayOpen(true);
    }

    const handleClickAgenda = () => {
        analytics.recordEvent("click-agenda")
        setAgendaOpen(true);
    }

    const handleClickBreakouts = () => {
        analytics.recordEvent("click-breakouts")
        // window.open("pdf/CERTIFIED ePosters PDF V3.pdf", "_blank")
        // setBreakoutsOpen(true);
        navigate("breakouts")
    }

    const handleClickBreakouts2 = () => {
        analytics.recordEvent("click-breakouts2")
        // window.open("pdf/CERTIFIED ePosters PDF V3.pdf", "_blank")
        // setBreakoutsOpen(true);
        navigate("breakouts2")
    }

    const handleClickWorkshops = () => {
        analytics.recordEvent("click-workshops")
        // window.open("pdf/CERTIFIED ePosters PDF V3.pdf", "_blank")
        // setBreakoutsOpen(true);
        navigate("LocalWorkshops")
    }
    const handleClickWorkshops2 = () => {
        analytics.recordEvent("click-workshops2")
        // window.open("pdf/CERTIFIED ePosters PDF V3.pdf", "_blank")
        // setBreakoutsOpen(true);
        navigate("LocalWorkshops2")
    }
    const handleClickSessions = () => {
        analytics.recordEvent("click-navigate-sessions-from-home")
        navigate("sessions")
    }

    const handleClickExhibitions = () => {
        analytics.recordEvent("click-navigate-exhibitions-from-home")
        navigate("exhibitions")
    }

    const handleClickFaqDialogDisplay = () => {
        analytics.recordEvent("click-faq")
        setFaqDialogDisplayOpen(true);
    }

    const handleClickMedicalInfoAe = () => {
        analytics.recordEvent("click-medicalInfoAe-home")
        setMedicalInfoAeOpen(true);
    }

    const handleClickFeedback = () => {
        analytics.recordEvent("click-feedback")
        setFeedbackSessionOpen(true);
        // show()
    }

    // const handleClickCertificate = () => {
    //     analytics.recordEvent("click-pdfCertificate")
    //     window.open("pdf/Lobby_ Masterclass RCPath CPD certificate.pdf", "_blank")
    // }

    const handleClickvisitHemeHub = () => {
        analytics.recordEvent("click-visitHemeHub-home")
        setvisitHemeHubOpen(true);
    }
    const handleClickBio = () => {
        analytics.recordEvent("click-bio")
        // setBioOpen(true);
        window.open("pdf/SCN11621_XLH Speaker Biographies_v3_MT.pdf", "_blank")
    }
    const handleClickCertificate = () => {
        analytics.recordEvent("click-certificate")
        setCertificateOpen(true);
    }
        const handleClickCrysvita = () => {
            analytics.recordEvent("click-crysvita")
            setCrysvitaOpen(true);
        }
        const handleClickXlh = () => {
            analytics.recordEvent("click-xlh")
            setXlhOpen(true);
        }
    const handleClickVideo = () => {
        analytics.recordEvent("click-video")
        setVideoOpen(true);
    }
    const handleClickOAVideo = () => {
        analytics.recordEvent("click-video")
        setOAVideoOpen(true);
    }
    const handleClickOAVideo2023 = () => {
        analytics.recordEvent("click-video2023")
        setOAVideo2023Open(true);
    }
    const handleClickResource = () => {
        analytics.recordEvent("click-resource")
        setResourceOpen(true);
    }

    const handleClickPublication = () => {
        analytics.recordEvent("click-publication")
        setPublicationOpen(true);
    }

    const handleClickChat = () => {
        analytics.recordEvent("click-chat-home")
        setTechnicalHelpOpen(true);

        // show()
    }




    const handleClickmedicalInfoAe_AE= () => {
        analytics.recordEvent("click-medicalInfoAeAE")
        window.open("pdf/PIs_for_October_2023_update/UAEKUWAITBAHRAIN_CRYPIL.pdf", "_blank")}

    const handleClickmedicalInfoAe_BE= () => {
        analytics.recordEvent("click-medicalInfoAeBE")
        window.open("pdf/PIs_for_October_2023_update/BE-BELGIUM_Crysvita_shortSPC(nl)_BE_Dec2022.pdf", "_blank")}

    const handleClickmedicalInfoAe_BG= () => {
        analytics.recordEvent("click-medicalInfoAeBG")
        window.open("pdf/PIs_for_October_2023_update/Bulgaria PI for XLH only_BG_07-2023.pdf", "_blank")}

    const handleClickmedicalInfoAe_CZ= () => {
        analytics.recordEvent("click-medicalInfoAeCZ")
        window.open("pdf/PIs_for_October_2023_update/Czech Republic Crysvita short SmPC 20230531.pdf", "_blank")}

    const handleClickmedicalInfoAe_BH= () => {
        analytics.recordEvent("click-medicalInfoAeBH")
        window.open("pdf/PIs_for_October_2023_update/UAEKUWAITBAHRAIN_CRYPIL.pdf", "_blank")}



    const handleClickmedicalInfoAe_DE= () => {
        analytics.recordEvent("click-medicalInfoAeDE")
        window.open("pdf/PIs_for_October_2023_update/DE-GERMANY_2022-10-11_Crysvita_Pflichttext_Stand_09_2022_furextern.pdf", "_blank")}
    const handleClickmedicalInfoAe_DK= () => {
        analytics.recordEvent("click-medicalInfoAeDK")
        window.open("pdf/PIs_for_October_2023_update/DK-DENMARK_CrysvitaPrescribingInformationDKOct(2).pdf", "_blank")}
    const handleClickmedicalInfoAe_ES= () => {
        analytics.recordEvent("click-medicalInfoAeES")
        window.open("pdf/PIs_for_October_2023_update/ES-SPAIN_220930FTCrysvita_V08_Spain.pdf", "_blank")}
    const handleClickmedicalInfoAe_FI= () => {
        analytics.recordEvent("click-medicalInfoAeFI")
        window.open("pdf/PIs_for_October_2023_update/FI-FINLAND_CrysvitaPrescribingInformationFISept22(2).pdf", "_blank")}
    const handleClickmedicalInfoAe_GR= () => {
        analytics.recordEvent("click-medicalInfoAeGR")
        window.open("pdf/PIs_for_October_2023_update/Greece CRYSVITA_INJ.SOL_10MG_20MG_30MG_SPC.pdf", "_blank")}



    const handleClickmedicalInfoAe_HR= () => {
        analytics.recordEvent("click-medicalInfoAeHR")
        window.open("pdf/PIs_for_November_2023_updateB/CROATIA_English_Crysvita_abbreviated SPC_rev 31.5.2023_For platform.pdf", "_blank")}
    const handleClickmedicalInfoAe_HU= () => {
        analytics.recordEvent("click-medicalInfoAeHU")
        window.open("pdf/PIs_for_November_2023_update/Crysvita_ XLH only_July2023_Hungary_abbrSmPC_HUversion_V2.pdf", "_blank")}
    const handleClickmedicalInfoAe_IE= () => {
        analytics.recordEvent("click-medicalInfoAeIE")
        window.open("pdf/PIs_for_October_2023_update/IE-IRELAND_PICrysvitaAug22IRE-NI(11).pdf", "_blank")}
    const handleClickmedicalInfoAe_IL= () => {
        analytics.recordEvent("click-medicalInfoAeIL")
        window.open("pdf/PIs_for_October_2023_update/Israel Crysvita-10mg-20mg-30mg_solution-for-injection_SPC_01-2022-V2 (002).pdf", "_blank")}
    const handleClickmedicalInfoAe_LU= () => {
        analytics.recordEvent("click-medicalInfoAeLU")
        window.open("pdf/PIs_for_October_2023_update/LU-LUXEMBOURG_Crysvita_shortSPC(fr)_BE-LU_Dec202.pdf", "_blank")}

    const handleClickmedicalInfoAe_NL= () => {
        analytics.recordEvent("click-medicalInfoAeNL")
        window.open("pdf/PIs_for_October_2023_update/NL-NETHERLANDS_vSmPCCrysvitaNLv102022.pdf", "_blank")}
    const handleClickmedicalInfoAe_NO= () => {
        analytics.recordEvent("click-medicalInfoAeNO")
        window.open("pdf/PIs_for_October_2023_update/NO-NORWAY_CrysvitaPrescribingInformationNOOct22(2).pdf", "_blank")}
    const handleClickmedicalInfoAe_PO= () => {
        analytics.recordEvent("click-medicalInfoAePO")
        window.open("pdf/PIs_for_October_2023_update/Poland Crysvita PL Short SmPC_XLH_Oct2023.pdf", "_blank")}
    const handleClickmedicalInfoAe_PT= () => {
        analytics.recordEvent("click-medicalInfoAePT")
        window.open("pdf/PIs_for_October_2023_update/PT-PORTUGAL_Crysvita_IECRCM_withsections4.4&4.5_clean_September2022_Portugal.pdf", "_blank")}
    const handleClickmedicalInfoAe_QA= () => {
        analytics.recordEvent("click-medicalInfoAeQA")
        window.open("pdf/PIs_for_October_2023_update/QA-QATAROMAN_CRYPIL.pdf", "_blank")}



    const handleClickmedicalInfoAe_RO= () => {
        analytics.recordEvent("click-medicalInfoAeRO")
        window.open("pdf/PIs_for_October_2023_update/Romania_Crysvita_sSmPC_07.2023.pdf", "_blank")}
    const handleClickmedicalInfoAe_SA= () => {
        analytics.recordEvent("click-medicalInfoAeSA")
        window.open("pdf/PIs_for_October_2023_update/SA-SAUDI_sa-spc.pdf", "_blank")}
    const handleClickmedicalInfoAe_SE= () => {
        analytics.recordEvent("click-medicalInfoAeSE")
        window.open("pdf/PIs_for_October_2023_update/SE-SWEDEN_CrysvitaPrescribingInformationSEOct22(2).pdf", "_blank")}
    const handleClickmedicalInfoAe_SK= () => {
        analytics.recordEvent("click-medicalInfoAeSK")
        window.open("pdf/PIs_for_November_2023_updateB/SK PI for XLH only_Updated for May 2023_1772_Final edited_CLEAN dt_V2.pdf", "_blank")}
    const handleClickmedicalInfoAe_SL= () => {
        analytics.recordEvent("click-medicalInfoAeSL")
        window.open("pdf/PIs_for_October_2023_update/Slovenia Crysvita_short SmPC_IA0036_07.2023_clean (002).pdf", "_blank")}

    const handleClickmedicalInfoAe_KW= () => {
        analytics.recordEvent("click-medicalInfoAeSL")
        window.open("pdf/PIs_for_October_2023_update/UAEKUWAITBAHRAIN_CRYPIL.pdf", "_blank")}
    const handleClickmedicalInfoAe_OM= () => {
        analytics.recordEvent("click-medicalInfoAeSL")
        window.open("pdf/PIs_for_October_2023_update/QA-QATAROMAN_CRYPIL.pdf", "_blank")}

    const handleClickPrivacy= () => {
        analytics.recordEvent("click-privacyNotice")
        window.open("https://international.kyowa-kirin.com/legal/privacy-notice.html", "_blank")}

    const handleClickCookie= () => {
        analytics.recordEvent("click-cookiePolicy")
        setCookiePolicyOpen(true);
        // window.open("https://www.kyowakirinhub.com/en-gb/cookie-notice", "_blank")}
    }

    const handleClickAdverse = () => {
        analytics.recordEvent("click-adverse")
        setAdverseOpen(true);
    }

    const handleClickiDontWantToWriteThisCodeItIs_a_TRAVESTY = () => {
        analytics.recordEvent("click-navigate-temporaryEveningSession2")
        window.open("https://openmeet.com/attendee/xlh_matters_virtual_13th-14th_oct_2021", '_blank')
    }

    const handleClickiDontWantToWriteThisCodeItIs_CONTACT = () => {
        analytics.recordEvent("click-navigate-temporaryEveningSession2")
        window.open("https://openmeet.com/attendee/xlh_matters_virtual_further_info", '_blank')
    }

    const handleClickiDontWantToWriteThisCodeItIs_EVALUATION = () => {
        analytics.recordEvent("click-navigate-temporaryEveningSession2")
        window.open("https://openmeet.com/attendee/xlh_matters_virtual_13th-14th_oct_2021_feedback_site", '_blank')
    }

    const handleClickMobileVideo_01 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_01")
        window.open("https://openaudience.tube/embed/?id=49A71D8E2AB740CFBC090B7132C16450", '_blank')
    }
    const handleClickMobileVideo_02 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_02")
        window.open("https://openaudience.tube/embed/?id=CFB8D54C478A437F966F7C6190F68D16", '_blank')
    }
    const handleClickMobileVideo_03 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_03")
        window.open("https://openaudience.tube/embed/?id=4561AA9DA1FD4B63BB39BD4A018E00C3", '_blank')
    }
    const handleClickMobileVideo_04 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_04")
        window.open("https://openaudience.tube/embed/?id=6C625A46C19142439158EC4FEAC6746A", '_blank')
    }
    const handleClickMobileVideo_05 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_05")
        window.open("https://openaudience.tube/embed/?id=A46BB6DF443D46948069F8B7CB7C6EE2", '_blank')
    }

    const handleClickMobileVideo_06 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_06")
        window.open("https://openaudience.tube/embed/?id=20CE875EA81E4936970A6EB52BE78DA4", '_blank')
    }
    const handleClickMobileVideo_07 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_07")
        window.open("https://openaudience.tube/embed/?id=5E55F38C948B46CA8FA3C3B01E4F07D5", '_blank')
    }
    const handleClickMobileVideo_08 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_08")
        window.open("https://openaudience.tube/embed/?id=9ED281C3D7CE4568A1B9D07C8E5F8266", '_blank')
    }
    const handleClickMobileVideo_09 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_09")
        window.open("https://openaudience.tube/embed/?id=4454222B53124B3C86EF9B9006926302", '_blank')
    }
    const handleClickMobileVideo_10 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_10")
        window.open("https://openaudience.tube/embed/?id=93AA13EEA7684D15AD07DD3558B1E341", '_blank')
    }

    const handleClickMobileVideo_11 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_11")
        window.open("https://openaudience.tube/embed/?id=CF1AA313BF734666ABAA3754722B6893", '_blank')
    }
    const handleClickMobileVideo_12 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_12")
        window.open("https://openaudience.tube/embed/?id=7DC69E9319CC43C3BB8C521FA7218ECE", '_blank')
    }
    const handleClickMobileVideo_13 = () => {
        analytics.recordEvent("click-navigate-MobileVideo_13")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }

    /////////////////////////////////////////////////////

    const handleClickMobileVideo2023_02 = () => {
        analytics.recordEvent("click-navigate-MobileVideo2023_02")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }
    const handleClickMobileVideo2023_03 = () => {
        analytics.recordEvent("click-navigate-MobileVideo2023_03")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }

    const handleClickMobileVideo2023_04 = () => {
        analytics.recordEvent("click-navigate-MobileVideo2023_04")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }
    const handleClickMobileVideo2023_05 = () => {
        analytics.recordEvent("click-navigate-MobileVideo2023_05")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }

    const handleClickMobileVideo2023_06 = () => {
        analytics.recordEvent("click-navigate-MobileVideo2023_06")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }
    const handleClickMobileVideo2023_07 = () => {
        analytics.recordEvent("click-navigate-MobileVideo2023_07")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }

    const handleClickMobileVideo2023_08 = () => {
        analytics.recordEvent("click-navigate-MobileVideo2023_08")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }
    const handleClickMobileVideo2023_09 = () => {
        analytics.recordEvent("click-navigate-MobileVideo2023_09")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }

    const handleClickMobileVideo2023_10 = () => {
        analytics.recordEvent("click-navigate-MobileVideo2023_10")
        window.open("https://openaudience.tube/embed/?id=3D95D0DB4E23449887963406D76E3783", '_blank')
    }


    return (
        <>
            {/*Preload images and track progress */}
            <img src="images/homeScroll.jpg" style={{display: "none"}} onLoad={() => loaded()} />
            <img src="images/homeOverlayResized.png" style={{display: "none"}} onLoad={() => loaded()} />

            <Backdrop open={Boolean(loading)} style={{zIndex: 1000 }} >
                <CircularProgress color="primary" />
            </Backdrop>

            {/*Default breakpoints:*/}
            {/*xs, extra-small: 0px*/}
            {/*sm, small: 600px*/}
            {/*md, medium: 960px*/}
            {/*lg, large: 1280px*/}
            {/*xl, extra-large: 1920px*/}

            <PointsDialogDisplay
                title="Points"
                src=''
                open={PointsDialogDisplayOpen}
                onClose={() => setPointsDialogDisplayOpen(false)}
                maxWidth="lg"
                fullWidth
            />

            <AgendaDialog
                title="Agenda"
                src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={agendaOpen}
                onClose={() => setAgendaOpen(false)}
                maxWidth="lg"
                fullWidth
            />

            <PosterChooserDialog
                title="Posters"
                src='images/null.jpg'
                open={PostersOpen}
                onClose={() => setPostersOpen(false)}
                maxWidth="md"
                // fullWidth
            />

            <FaqDialogDisplay
                title="Faq"
                src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={FaqDialogDisplayOpen}
                onClose={() => setFaqDialogDisplayOpen(false)}
                maxWidth="lg"
                fullWidth
            />

            <MedinfoRequest
                title="Medical Info"
                src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={medicalInfoAeOpen}
                onClose={() => setMedicalInfoAeOpen(false)}
                maxWidth="sm"
            />

            <IframeDialog
                title="Feedback"
                url='https://openmeet.com/attendee/haem_masterclass_11'
                open={feedbackSessionOpen}
                onClose={() => setFeedbackSessionOpen(false)}
                maxWidth="xl"
                fullWidth
            />

            {/*<PDFDialog*/}
            {/*//not used*/}
            {/*    title="Documents"*/}
            {/*    open={pdfOpen}*/}
            {/*    onClose={() => setPdfOpen(false)}*/}
            {/*    maxWidth="sm"*/}
            {/*    fullWidth*/}
            {/*    // fullScreen*/}
            {/*    pdfs={[*/}
            {/*        {*/}
            {/*            name: "Certificate of Attendance",*/}
            {/*            avatar: "images/null.jpg",*/}
            {/*            description: "Click here to view the document / Click the icon to download",*/}
            {/*            url: "pdf/null.pdf"*/}
            {/*        }*/}
            {/*    ]}*/}
            {/*/>*/}

            <ExternalLinkDialogCrysvita
                title="Visit External Web Address"
                url='https://https://webaccess-janssen.com/interaction/v2/4be12f32-7858-4929-a80a-e0bccf2c0325/login?client_id=teqgwpkk3pnqf64994bg3rcm5sbzrj65'
                open={crysvitaOpen}
                onClose={() => setCrysvitaOpen(false)}
                maxWidth="sm"
                fullWidth
                // fullScreen
            />

            <ExternalLinkDialogXlhlink
                title="Visit External Web Address"
                url='https://https://webaccess-janssen.com/interaction/v2/4be12f32-7858-4929-a80a-e0bccf2c0325/login?client_id=teqgwpkk3pnqf64994bg3rcm5sbzrj65'
                open={xlhOpen}
                onClose={() => setXlhOpen(false)}
                maxWidth="sm"
                fullWidth
                // fullScreen
            />

            <BioDialog
                title="Bios"
                src='images/null.jpg'
                open={bioOpen}
                onClose={() => setBioOpen(false)}
                maxWidth="xl"
                fullWidth
                // fullScreen
            />

            <CertificateDialog
                title="Get Certificate"
                url='https://https://webaccess-janssen.com/interaction/v2/4be12f32-7858-4929-a80a-e0bccf2c0325/login?client_id=teqgwpkk3pnqf64994bg3rcm5sbzrj65'
                open={certificateOpen}
                onClose={() => setCertificateOpen(false)}
                maxWidth="sm"
                fullWidth
                // fullScreen
            />
            <VideoDialog
                title="Video"
                // src='videosnull.mp4'
                src='video/XLH_MATTERS_invite_04.mp4'
                type="video/mp4"
                open={videoOpen}
                onClose={() => setVideoOpen(false)}
                fullWidth
                // fullScreen
            />
           {/* <OAtubeDialog
                title="Video"
                src='https://openaudience.tube/embed/?id=0B51B68454104BA0975B30DC52F72A30'
                type="video/mp4"
                open={OAvideoOpen}
                onClose={() => setOAVideoOpen(false)}
                fullWidth
                // fullScreen
                fullHeight
                maxWidth={'md'} // 'sm' || 'md' || 'lg' || 'xl'
            />*/}
            <OAVideosDialog
                maxWidth="lg"
                open={OAvideoOpen}
                onClose={() => setOAVideoOpen(false)}
            />

            <OAVideosDialog2023
                maxWidth="lg"
                open={OAvideoOpen2023}
                onClose={() => setOAVideo2023Open(false)}
            />

            {/*under here add about 3 more OAtubedialogs in a stack*/}

            <AdverseDialog
                title="Adverse Events"
                // src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={adverseOpen}
                onClose={() => setAdverseOpen(false)}
                maxWidth="xl"
            />
            <CookiePolicy
                title="Cookie Policy"
                // src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={CookiePolicyOpen}
                onClose={() => setCookiePolicyOpen(false)}
                maxWidth="md"
            />

            <TechnicalHelp
                title="Cookie Policy"
                // src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={TechnicalHelpOpen}
                onClose={() => setTechnicalHelpOpen(false)}
                maxWidth="sm"
            />


            {/*Default breakpoints:*/}
            {/*xs, extra-small: 0px*/}
            {/*sm, small: 600px*/}
            {/*md, medium: 960px*/}
            {/*lg, large: 1280px*/}
            {/*xl, extra-large: 1920px*/}

            <ResourceGallery
                title="ResourceGallery"
                // src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={resourceOpen}
                onClose={() => setResourceOpen(false)}
                maxWidth="lg"
            />
            <RecentPublications
                title="RecentPublications"
                // src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={publicationOpen}
                onClose={() => setPublicationOpen(false)}
                maxWidth="lg"
            />

            <Hidden xsDown>
                <div className="click-region-outer">
                    <div className="click-region-inner">
                        <Fade in={!loading}>
                            <ImageMap
                                className="click-region"
                                // switch out this image for holding page
                                src={"images/homeScroll.jpg"}
                                // src="images/Holding_page_popup.jpg"
                                map={mapArea}
                                onMapClick={onMapClick}
                            />
                        </Fade>

                        <Fade in={!loading}>
                            <img
                                className="click-region"

                                // switch out this image for holding page
                                src="images/homeOverlayResized.png"
                                // src="images/Holding_page_popup.jpg"

                                width="100%"

                                // switch out this style to disable clickables for holding page
                                style={{zIndex: 1000, pointerEvents: "none"}}
                                // style={{position: "absolute", zIndex: 1000}}
                            />
                        </Fade>

                        {/*<Fade in={!loading}>*/}
                        {/*    <img*/}
                        {/*        className="click-region"*/}
                        {/*        // switch out this image for holding page*/}
                        {/*        src="images/animatedGif.gif"*/}
                        {/*        // src="images/Holding_page_popup.jpg"*/}
                        {/*        width="5%"*/}
                        {/*        height="6.5%"*/}
                        {/*        style={{left: "46.5%", top: "55%", zIndex: 1000}}*/}
                        {/*        // switch out this style to disable clickables for holding page*/}
                        {/*        // style={{zIndex: 1000, pointerEvents: "none"}}*/}
                        {/*        // style={{position: "absolute", zIndex: 1000}}*/}
                        {/*    />*/}
                        {/*</Fade>*/}

                        {/*comment out for holding page*/}
                        {/*<Fade in={!loading}>*/}
                        {/*    <LiveImageLink*/}
                        {/*        id="agenda-image"*/}
                        {/*        className="click-region"*/}
                        {/*        width="21.0%"*/}
                        {/*        height="52.0%"*/}
                        {/*        style={{ left: "78.0%", top: "40.0%", zIndex: 1000}}*/}
                        {/*    />*/}
                        {/*</Fade>*/}
                        {/*<div*/}
                        {/*    id="image-wrapper"*/}
                        {/*    className="click-region"*/}
                        {/*    style={{width: '7.0%', height: "2.2%", left: "20.0%", top: "71.0%", zIndex: 1000}}*/}
                        {/*>*/}
                        {/*    <OARoomImageLink id="image-slot-11" style={{ width: "100%", height: "100%" }} />*/}
                        {/*</div>*/}

                        {/*<div*/}
                        {/*    id="image-wrapper"*/}
                        {/*    className="click-region"*/}
                        {/*    style={{width: '11.5%', height: "10.2%", left: "43.5%", top: "44.0%", zIndex: 1000}}*/}
                        {/*>*/}
                        {/*    <OARoomImageLink id="image-slot-12" style={{ width: "100%", height: "100%" }} />*/}
                        {/*</div>*/}

                        {/*<div*/}
                        {/*    id="image-wrapper"*/}
                        {/*    className="click-region"*/}
                        {/*    style={{width: '6.0%', height: "2.0%", left: "10.1%", top: "76.0%", zIndex: 1000}}*/}
                        {/*>*/}
                        {/*    <OARoomImageLink id="image-slot-13" style={{ width: "100%", height: "100%" }} />*/}
                        {/*</div>*/}


                    </div>

                </div>
            </Hidden>

            <Hidden smUp>
                <Box width="100%" height="100%">
                    {/*switch out this image for holding page*/}
                    <img src='images/homeScroll_MOB.jpg' width="100%"  alt=""/>
                    {/*<img src='images/Holding_page_popup.jpg' width="100%"  alt=""/>*/}

                    {/*comment out whole list for holding page*/}
{/*//////////////////////////////////////////////////////////////////////////*/}
                    <List>
                        {/*<ListItem button onClick={handleClickAgenda}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Agenda"*/}
                        {/*        secondary="Check the agenda for today's event"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}
                        {/*<ListItem button onClick={handleClickBreakouts}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Breakouts"*/}
                        {/*        secondary="Join one of the Breakout Sessions"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        {/*<OARoomListLink*/}
                        {/*    id="image-slot-12"*/}
                        {/*    name="Main Plenary Session"*/}
                        {/*/>*/}

                        {/*<ListItem button onClick={handleClickiDontWantToWriteThisCodeItIs_a_TRAVESTY}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Main Plenary Session"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        {/*<OARoomListLinkBuild*/}
                        {/*    id="image-slot-12"*/}
                        {/*    name="Main Plenary Session"*/}
                        {/*/>*/}

                        {/*<ListItem button onClick={handleClickWorkshops}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Local Workshops"*/}
                        {/*        secondary="Visit The Local Workshops"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        {/*<ListItem button onClick={handleClickOAVideo}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Meeting On-Demand Videos"*/}
                        {/*        secondary="View the Meeting On-Demand Videos"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        <ListItem button onClick={handleClickMobileVideo_01}>
                            <ListItemText
                                primary="VIDEO 2021: Welcome Message"
                                secondary="Prof. Linglart"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_02}>
                            <ListItemText
                                primary="VIDEO 2021: What are the causes and clinical impact of hypophosphataemia?"
                                secondary="Prof. Linglart"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_03}>
                            <ListItemText
                                primary="VIDEO 2021: How to diagnose XLH in the metabolic disease landscape"
                                secondary="Dr Reschke"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_04}>
                            <ListItemText
                                primary="VIDEO 2021: Joining the dots: Co-ordinating the MDT perspectives from different specialities"
                                secondary="Prof. Linglart (Chair), Prof. Wicart, Prof. Chaussain, Prof. Di Rocco, Dr Nevoux, Ian Tucker"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_05}>
                            <ListItemText
                                primary="VIDEO 2021: How can we encourage earlier referral for better patient outcomes?"
                                secondary="Prof. Emma"
                            />
                        </ListItem>



                        <ListItem button onClick={handleClickMobileVideo_06}>
                            <ListItemText
                                primary="VIDEO 2021: Achieving treatment goals for our patients"
                                secondary="Dr Padidela"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_07}>
                            <ListItemText
                                primary="VIDEO 2021: Diagnosing XLH: Interactive case studies"
                                secondary="Dr Di Iorgi, Prof. Emma"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_08}>
                            <ListItemText
                                primary="VIDEO 2021: What should the treatment goals be for children with XLH?"
                                secondary="Prof. Linglart, Dr Padidela"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_09}>
                            <ListItemText
                                primary="VIDEO 2021: Managing hyperparathyroidism in patients with XLH"
                                secondary="Dr Reschke"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_10}>
                            <ListItemText
                                primary="VIDEO 2021: Craniosynostosis in children with XLH"
                                secondary="Prof. Di Rocco"
                            />
                        </ListItem>


                        <ListItem button onClick={handleClickMobileVideo_11}>
                            <ListItemText
                                primary="VIDEO 2021: Dose adjustment in children with XLH receiving treatment with CRYSVITA"
                                secondary="Dr Padidela"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_12}>
                            <ListItemText
                                primary="VIDEO 2021: The practical use of CRYSVITA in children with XLH to optimise outcomes"
                                secondary="Prof. Linglart"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickMobileVideo_13}>
                            <ListItemText
                                primary="VIDEO 2021: The importance of diet"
                                secondary="Dr de Lucas"
                            />
                        </ListItem>

{/*//////////////////////////////////2023//////////////////////////////////*/}
                        <Divider/>

                                {/*//02*/}
                            <ListItem button onClick={handleClickMobileVideo2023_02}>
                            <ListItemText
                                primary="VIDEO 2022: Recent advances in XLH, Including the Kyowa Kirin XLH Registry"
                                secondary="Prof. Francesco Emma"
                                />
                            </ListItem>
                                {/*//03*/}
                            <ListItem button onClick={handleClickMobileVideo2023_03}>
                            <ListItemText
                                primary= "VIDEO 2022: How to optimise treatment outcomes for children with XLH?"
                                secondary="Dr Anya Rothenbuhler, Dr Adalbert Raimann, Dr Pedro Arango Sancho"
                            />
                            </ListItem>
                                {/*//04*/}
                            <ListItem button onClick={handleClickMobileVideo2023_04}>
                            <ListItemText
                                primary="VIDEO 2022: Accelerating the diagnosis of XLH in adults"
                                secondary="Dr Ali Alzahrani"
                            />
                            </ListItem>
                                {/*//05*/}
                            <ListItem button onClick={handleClickMobileVideo2023_05}>
                            <ListItemText
                                primary="VIDEO 2022: How to optimise treatment outcomes for adults with XLH"
                                secondary="Dr Lothar Seefried (Co-Chair), Dr Rachel Crowley"
                            />
                            </ListItem>
                                {/*//06*/}
                            <ListItem button onClick={handleClickMobileVideo2023_06}>
                            <ListItemText
                                primary="VIDEO 2022: The pathophysiology of XLH – behind the scenes"
                                secondary="Prof. Justine Bacchetta"
                            />
                            </ListItem>
                                {/*//07*/}
                            <ListItem button onClick={handleClickMobileVideo2023_07}>
                            <ListItemText
                                primary="VIDEO 2022: Preparing adolescents for transition – the health psychology perspective"
                                secondary="Dr Jonathan Gibbins"
                            />
                            </ListItem>
                                {/*//08*/}
                            <ListItem button onClick={handleClickMobileVideo2023_08}>
                            <ListItemText
                                primary= "VIDEO 2022: Transition in practice"
                                secondary="Ian Tucker, Dr Anna Grandone"
                            />
                            </ListItem>
                                {/*//09*/}
                            <ListItem button onClick={handleClickMobileVideo2023_09}>
                            <ListItemText
                                primary="VIDEO 2022: Working with orthopaedic surgeons to optimise outcomes"
                                secondary="Dr Gabriel Mindler"
                            />
                            </ListItem>
                                {/*//10*/}
                            <ListItem button onClick={handleClickMobileVideo2023_10}>
                            <ListItemText
                                primary="VIDEO 2022: Clinical case studies – optimising transition of adolescent to adult services"
                                secondary="Prof. Agnès Linglart (Co-Chair), Dr Adalbert Raimann, Dr Leonid Zeitlin"
                            />
                            </ListItem>

{/*//////////////////////////////////////////////////////////////////////////*/}
                        <Divider/>

                        {/*<ListItem button onClick={handleClickResource}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Resource Gallery"*/}
                        {/*        secondary="Visit The Resource Gallery"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        <ListItem button onClick={handleClickPublication}>
                            <ListItemText
                                primary="XLH Publications of Interest"
                                secondary="View XLH Publications of Interest"
                            />
                        </ListItem>

{/*//////////////////////////////////////////////////////////////////////////*/}
{/*                        <Divider/>*/}


                        {/*<ListItem button onClick={handleClickCrysvita}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Crysvita Website"*/}
                        {/*        secondary="Visit the Cyrysvita.eu website"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}
                        {/*<ListItem button onClick={handleClickXlh}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="XLHlink Website"*/}
                        {/*        secondary="Visit the XLHlink.eu website"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

{/*//////////////////////////////////////////////////////////////////////////*/}
{/*                        <Divider/>*/}

                        {/*<ListItem button onClick={handleClickVideo}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Welcome Video"*/}
                        {/*        secondary="Watch the welcome video"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

{/*//////////////////////////////////////////////////////////////////////////*/}
{/*                        <Divider/>*/}

                        {/*<ListItem button onClick={handleClickFaqDialogDisplay}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="FAQ"*/}
                        {/*        secondary="Frequently asked questions"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        {/*<ListItem button onClick={handleClickBio}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Faculty Biographies"*/}
                        {/*        secondary="View the Faculty Biographies"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        {/*<OARoomListLink*/}
                        {/*    id="image-slot-13"*/}
                        {/*    name="Evaluation Survey"*/}
                        {/*/>*/}

                        {/*<OARoomListLinkBuild*/}
                        {/*    id="image-slot-13"*/}
                        {/*    name="Evaluation Survey"*/}
                        {/*/>*/}



                        {/*<ListItem button onClick={handleClickCertificate}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Meeting Certificate"*/}
                        {/*        secondary="Request a meeting certificate"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}


                        {/*<OARoomListLink*/}
                        {/*    id="image-slot-11"*/}
                        {/*    name="Contact Kyowa Kirin"*/}
                        {/*/>*/}

                        {/*<ListItem button onClick={handleClickiDontWantToWriteThisCodeItIs_CONTACT}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Contact Kyowa Kirin"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        {/*<OARoomListLinkBuild*/}
                        {/*    id="image-slot-11"*/}
                        {/*    name="Contact Kyowa Kirin"*/}
                        {/*/>*/}

                    <ListItem button onClick={handleClickMedicalInfoAe}>
                        <ListItemText
                            primary="Medical Information Request"
                            secondary="Request Medical Information"
                        />
                    </ListItem>

                        {/*<ListItem button onClick={handleClickChat}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Technical Help"*/}
                        {/*        secondary="Request technical support"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}
{/*//////////////////////////////////////////////////////////////////////////*/}
                        <Divider/>


                        <ListItem button onClick={handleClickPrivacy}>
                            <ListItemText
                                primary="Privacy Policy"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickCookie}>
                            <ListItemText
                                primary="Cookie Policy"
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickAdverse}>
                            <ListItemText
                                primary="Click here for Adverse Event Reporting"
                            />
                        </ListItem>


                        <Divider/>
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_AE}> <ListItemText secondary="View Regional Prescribing Information for AE" /> </ListItem>*/}
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_BE}> <ListItemText secondary="View Regional Prescribing Information for BE" /> </ListItem>*/}
                        <ListItem button onClick={handleClickmedicalInfoAe_BG}> <ListItemText secondary="View Regional Prescribing Information for BG" /> </ListItem>
                        <ListItem button onClick={handleClickmedicalInfoAe_CZ}> <ListItemText secondary="View Regional Prescribing Information for CZ" /> </ListItem>
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_BH}> <ListItemText secondary="View Regional Prescribing Information for BH" /> </ListItem>*/}

                        {/*<ListItem button onClick={handleClickmedicalInfoAe_DE}> <ListItemText secondary="View Regional Prescribing Information for DE" /> </ListItem>*/}
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_DK}> <ListItemText secondary="View Regional Prescribing Information for DK" /> </ListItem>*/}
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_ES}> <ListItemText secondary="View Regional Prescribing Information for ES" /> </ListItem>*/}
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_FI}> <ListItemText secondary="View Regional Prescribing Information for FI" /> </ListItem>*/}
                        <ListItem button onClick={handleClickmedicalInfoAe_GR}> <ListItemText secondary="View Regional Prescribing Information for GR" /> </ListItem>


                        <ListItem button onClick={handleClickmedicalInfoAe_HR}> <ListItemText secondary="View Regional Prescribing Information for HR" /> </ListItem>
                        <ListItem button onClick={handleClickmedicalInfoAe_HU}> <ListItemText secondary="View Regional Prescribing Information for HU" /> </ListItem>
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_IE}> <ListItemText secondary="View Regional Prescribing Information for IE" /> </ListItem>*/}
                        <ListItem button onClick={handleClickmedicalInfoAe_IL}> <ListItemText secondary="View Regional Prescribing Information for IL" /> </ListItem>
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_LU}> <ListItemText secondary="View Regional Prescribing Information for LU" /> </ListItem>*/}

                        {/*<ListItem button onClick={handleClickmedicalInfoAe_NL}> <ListItemText secondary="View Regional Prescribing Information for NL" /> </ListItem>*/}
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_NO}> <ListItemText secondary="View Regional Prescribing Information for NO" /> </ListItem>*/}
                        <ListItem button onClick={handleClickmedicalInfoAe_PO}> <ListItemText secondary="View Regional Prescribing Information for PL" /> </ListItem>
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_PT}> <ListItemText secondary="View Regional Prescribing Information for PT" /> </ListItem>*/}
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_QA}> <ListItemText secondary="View Regional Prescribing Information for QA" /> </ListItem>*/}

                        <ListItem button onClick={handleClickmedicalInfoAe_RO}> <ListItemText secondary="View Regional Prescribing Information for RO" /> </ListItem>
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_SA}> <ListItemText secondary="View Regional Prescribing Information for SA" /> </ListItem>*/}
                        {/*<ListItem button onClick={handleClickmedicalInfoAe_SE}> <ListItemText secondary="View Regional Prescribing Information for SE" /> </ListItem>*/}
                        <ListItem button onClick={handleClickmedicalInfoAe_SL}> <ListItemText secondary="View Regional Prescribing Information for SI" /> </ListItem>
                        <ListItem button onClick={handleClickmedicalInfoAe_SK}> <ListItemText secondary="View Regional Prescribing Information for SK" /> </ListItem>

                        <Divider/>


                    </List>
                </Box>
            </Hidden>
        </>
    );
}
