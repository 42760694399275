
        import Dialog from "./dialog";
        import React from "react";
        import Link from "@material-ui/core/Link";
        import {Box, Grid} from '@material-ui/core';
        import * as analytics from "../libs/analytics";

        const handleClickLink = (name) => {
            // analytics.recordEvent(`click-${name}-home`);
        }

        export function TechnicalHelp(props) {

            const { url, title, ...rest } = props;

            return (
                <Dialog {...rest}>

                    <img src="images/BlankBanner.jpg" width="100%" />


                    <Box py={1}>
                    </Box>

                    <div  className="App">
                        <Grid container>
                            {/*<Grid item xs={1}>*/}
                            {/*</Grid>*/}





                            <Grid item xs={12} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" align="center" >

                                <div className="custom-text-Myriad-Pro-Bold-MegaPrintHeadline" justify='center'>
                                    Technical Help
                                    <Box py={1.5}></Box>
                                </div>

                                Please look at the FAQs in the information desk
                                <Box py={0.3}>
                                </Box>
                                or  contact

                                <Link href="mailto:pedro.malha@openaudience.com" underline='always'  target="_blank"
                                      onClick={() => handleClickLink("email-adverse-event-dsafety")}
                                > pedro.malha@openaudience.com</Link>.

                                <Box py={0.4}>
                                </Box>
                                for technical support.
                            </Grid>

                        </Grid>



                    </div>

                    <Box py={7}>
                    </Box>
                    {/*<Grid>*/}
                    {/*    <img src="images/CertificateFooter.jpg" width="100%" />*/}
                    {/*</Grid>*/}
                </Dialog>
            );
        }