import Dialog from "./dialog";
import {
    Box,
    Button,
    Grid
} from '@material-ui/core'
import React from "react";
import * as analytics from "../libs/analytics";

const handleClickLinkA = (name) => {
    // analytics.recordEvent(`click-download-posters-2`);
    window.open("pdf/CERTIFIED ePosters_PDF_V3_2.pdf", "_blank")
}

const handleClickLinkB = (name) => {
    // analytics.recordEvent(`click-download-posters-3`);
    window.open("pdf/CERTIFIED_ePosters_PDF_V3_3.pdf", "_blank")
}

const handleClickLinkC = (name) => {
    // analytics.recordEvent(`click-download-posters-4`);
    window.open("pdf/CERTIFIED_ePosters_PDF_V3_4.pdf", "_blank")
}

export function PosterChooserDialog(props) {

    const { url, title, ...rest } = props;

    return (
        <Dialog {...rest}>



            <Box py={2}>
            </Box>



            <img src="images/posterDialogHeader.jpg" width="100%" />




            <Box py={1}>
            </Box>
            <div  className="App">
                <Grid container>
                    <Grid item xs={1}>
                    </Grid>

                    <Grid item xs={10} align="center" >

{/*//how to get columns?*/}
{/*                        <Button style = {{flexDirection : 'column'}}*/}
{/*                                onClick={handleClickLinkA}>*/}

{/*                            <p>Text 1</p>*/}
{/*                            <p>Text 2</p>*/}
{/*                        </Button>*/}

                        <Button variant="contained" fullWidth color='primary' style={{ fontSize: '12px' }} onClick={handleClickLinkA}>

                                                         {/*Myeloma nurse led telephone review cli\nic*/}

                            <br></br>
{/*<br/>*/}
{/*                                <Box py={1}>*/}
{/*                                </Box>*/}

                            {/*Laura Simposon and Fiona Butler*/}

                        </Button>


                        <Box py={1}>
                        </Box>

                        <Button variant="contained" fullWidth color='primary' style={{ fontSize: '12px' }} onClick={handleClickLinkB}>
                            {/*    The development of a pharmacy-based, nurse-led injection clinic for cancer patients to receive*/}
                            {/*    their treatment outside of a hospital during the COVID-19 pandemic*/}
                            {/*<br></br>*/}
                            {/*    Natalie Bingham et al.*/}
                        </Button>

                        <Box py={1}>

                        </Box>

                        <Button variant="contained" fullWidth color='primary' style={{ fontSize: '12px' }} onClick={handleClickLinkC}>
                            {/*    Implementing Venetoclax treatment*/}
                            {/*<br></br>*/}
                            {/*    Klara Matulay et al.*/}
                        </Button>


                    </Grid>

                    <Grid item xs={1}>
                    </Grid>
                </Grid>
            </div>

            {/*<Box py={2}>*/}
            {/*</Box>*/}

            <img src="images/posterDialogFooter.jpg" width="100%" />

        </Dialog>
    );
}