import {
    Box,
    DialogContent, Typography
} from '@material-ui/core'
import React, {useEffect} from 'react';
import Dialog from '../components/dialog'
import * as analytics from "../libs/analytics";

export default function Redirect(){

    useEffect(() => {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        console.log(parsed);

        if(parsed.url){
            analytics.recordEvent("redirect-LiveBannerLink").then(() => {
                //window.open(parsed.url)
            })
        }

    }, [])

    return (
        <Box width="100%" height="100%">
            <Dialog open maxWidth='md' hideBackdrop>
                <DialogContent>
                    <Box py={0}>
                        <Typography>Redirecting...</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}