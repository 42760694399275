import './App.css';
import { navigate, Router } from "@reach/router";
import React, { Fragment, useEffect } from 'react';
import Home from './pages/home';
import Login from './pages/login';
import LoginClient from './pages/loginClient';
import { isTokenValid } from './libs/authLib'
import Sessions from './pages/sessions';
import Sessions2 from './pages/sessions2';
import Exhibitions from './pages/exhibitions';
// import { IntercomProvider } from 'react-use-intercom';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {isBrowserCompatible} from "./libs/browserLib";
import Unsupported from "./pages/unsupported";
// import LiveBanner from "./components/LiveBanner";
import Redirect from "./pages/redirect";
import Standby from "./pages/standby";
import Breakouts from "./pages/breakouts";
import Breakouts2 from "./pages/breakouts2";
import LocalWorkshops from "./pages/LocalWorkshops";
import LocalWorkshops2 from "./pages/LocalWorkshops2";
import CookieConsentPopup from "./components/CookieConsentPopup";


// Sentry.init({
//     dsn: "https://aaea8d21edbb4fde8e9080898ed99024@o468348.ingest.sentry.io/5962551",
//     integrations: [new Integrations.BrowserTracing()],
//
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
// });

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#022c58"
        }
    }
});

function App() {

    useEffect(() => {
        if(!isBrowserCompatible()){
            navigate('unsupported', { replace: true })
        }else{
            if(!isTokenValid()){
                // navigate('loginClient', { replace: true })
                navigate('login', { replace: true })
            }
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <CookieConsentPopup />


            {/* Image Preload */}
            {/*chris to put all images that need preloading here*/}
            {/*<img src="animatedGif.gif" style={{display: "none"}}/>*/}

            <img src="./images/temp-banner.jpg" style={{display: "none"}}/>
            <img src="./images/spacer.jpg" style={{display: "none"}}/>

            <img src="./images/AdverseFooter2.jpg" style={{display: "none"}}/>
            <img src="./images/footer.jpg" style={{display: "none"}}/>

            <img src="./images/video_01.jpg" style={{display: "none"}}/>
            <img src="./images/video_02.jpg" style={{display: "none"}}/>
            <img src="./images/video_03.jpg" style={{display: "none"}}/>
            <img src="./images/video_04.jpg" style={{display: "none"}}/>
            <img src="./images/video_05.jpg" style={{display: "none"}}/>
            <img src="./images/video_06.jpg" style={{display: "none"}}/>
            <img src="./images/video_07.jpg" style={{display: "none"}}/>
            <img src="./images/video_08.jpg" style={{display: "none"}}/>
            <img src="./images/video_09.jpg" style={{display: "none"}}/>
            <img src="./images/video_10.jpg" style={{display: "none"}}/>
            <img src="./images/video_11.jpg" style={{display: "none"}}/>
            <img src="./images/video_12.jpg" style={{display: "none"}}/>
            <img src="./images/video_13.jpg" style={{display: "none"}}/>


            <img src="./images/AdverseFooterLeft.jpg" style={{display: "none"}}/>
            <img src="./images/homeScroll_MOB.jpg" style={{display: "none"}}/>
            <img src="./images/bannerLogin.svg" style={{display: "none"}}/>
            <img src="./images/resourceBanner.jpg" style={{display: "none"}}/>
            <img src="./images/micrositeCookies2.jpg" style={{display: "none"}}/>
            <img src="./images/homeOverlayResized.jpg" style={{display: "none"}}/>
            <img src="./images/homeScroll.jpg" style={{display: "none"}}/>


            {/*comment out for preview*/}
            {/*<IntercomProvider appId="rsohvzg6">*/}

                {/*<LiveBanner id="home-banner" />*/}

                <div className="container">

                    {/*wordly iframe in a div*/}
                    {/*<div style={{position: 'fixed', top: '0', width: '100%', height: '75px',zIndex: '1001'}}>*/}
                    {/*    <iframe src="https://attend.wordly.ai/frame/AAAA-0000?bgcolor=33ACFF"*/}
                    {/*            style={{*/}
                    {/*                zIndex: 1000,*/}
                    {/*                // border: 0px  #ffffff none,*/}
                    {/*                name: "myiFrame",*/}
                    {/*                scrolling: "no",*/}
                    {/*                frameBorder:"1",*/}
                    {/*                marginHeight:"0px",*/}
                    {/*                marginWidth:"0px",*/}
                    {/*                // height:"75px",*/}
                    {/*                width:"100%",*/}
                    {/*                // allowFullScreen,*/}
                    {/*            }}*/}
                    {/*    ></iframe>*/}

                    {/*    /!*<iframe src="https://attend.wordly.ai/frame/AAAA-0000?bgcolor=33ACFF"*!/*/}
                    {/*    /!*        style="border:0px #ffffff none;" name="myiFrame" scrolling="no" frameBorder="1"*!/*/}
                    {/*    /!*        marginHeight="0px" marginWidth="0px" height="75px" width="600px" allowFullScreen></iframe>*!/*/}
                    {/*</div>*/}


                    <Router component={Fragment} primary={false}>

                        <Login path='/login' />
                        <LoginClient path='/loginClient' />

                        {/*TOGGLE FOR LIVE / STANDBY*/}
                        {/*<Home path="/" />*/}

                        <Home path="/" />
                        {/*<Standby path='/' />*/}

                        {/*<Standby path='/' />*/}


                        {/*<Sessions path="/sessions" />*/}
                        <Breakouts path="/breakouts" />
                        <Breakouts2 path="/breakouts2" />
                        <LocalWorkshops path="/LocalWorkshops" />
                        <LocalWorkshops2 path="/LocalWorkshops2" />
                        <Exhibitions path="/exhibitions" />
                        <Unsupported path="/unsupported" />
                        <Redirect path="/redirect" />
                    </Router>
                </div>

            {/*comment out for preview*/}
            {/*</IntercomProvider>*/}
        </ThemeProvider>

    );
}

export default App;
