import Dialog from "./dialog";
import React from "react";
import Link from "@material-ui/core/Link";
import {Box, Grid} from '@material-ui/core';
import * as analytics from "../libs/analytics";

const handleClickLink = (name) => {
    // analytics.recordEvent(`click-${name}-sessions`);
}

export function MedicalInfoAeMessageDialogExhibitions(props) {

    const { url, title, ...rest } = props;

    return (
        <Dialog {...rest}>







            <img src="images/Main_AEPI-popup_header.jpg" width="100%" />

            <Box py={3}>
            </Box>
            <div  className="App">
                <Grid container  >


                    {/*======================================================================================*/}
                    <Grid item xs={1}>
                    </Grid>
                    {/*======================================================================================*/}
                    <Grid item xs={3}>
                        <Box py={3}>
                        </Box>
                        <div  className="custom-text-Myriad-Pro-Bold-DialogBoxPrescribeHeadline" align='left' >
                            Links to
                            <Box py={0}></Box>
                            Prescribing
                            <Box py={0}></Box>
                            Information
                        </div>
                    </Grid>
                    {/*======================================================================================*/}
                    <Grid item xs={1}>
                        <img src="images/divider.jpg" width="100%" />
                    </Grid>
                    {/*======================================================================================*/}

                    <Grid item xs={7}>

                        <Box py={0.5}>
                        </Box>

                        <div className="custom-text-Myriad-Pro-Bold-DialogBoxPrescribe" align='left'>


                            DARZALEX<sup>®</sup>
                            <span className="custom-text-Myriad-Pro-Regular-SmallPrintBlack">▼ </span>
                            (daratumumab)
                        </div>
                        <Box py={0.3}></Box>
                        <div className="custom-text-Myriad-Pro-Light-DialogBoxPrescribe" align='left'>
                            <Link
                                href="https://www.janssen-pi.co.uk/en/product-information/4"
                                underline='always'
                                target="_blank"
                                onClick={() => handleClickLink("link-product-information-darzalex")}
                            >
                                https://www.janssen-pi.co.uk/en/product-information/4
                            </Link>.
                        </div>

                        <Box py={1.3}></Box>

                        <div className="custom-text-Myriad-Pro-Bold-DialogBoxPrescribe" align='left'>
                            VELCADE<sup>®</sup> (bortezomib)
                        </div>
                        <Box py={0.3}></Box>
                        <div className="custom-text-Myriad-Pro-Light-DialogBoxPrescribe" align='left'>
                            <Link href="https://www.janssen-pi.co.uk/en/product-information/22 "
                                   underline='always'
                                   target="_blank"
                                   onClick={() => handleClickLink("link-product-information-velcade")}
                        >
                            https://www.janssen-pi.co.uk/en/product-information/22
                        </Link>.
                        </div>

                        <Box py={1.3}></Box>

                        <div className="custom-text-Myriad-Pro-Bold-DialogBoxPrescribe" align='left'>
                            IMBRUVICA<sup>®</sup> (ibrutinib)
                        </div>
                        <Box py={0.3}></Box>
                        <div className="custom-text-Myriad-Pro-Light-DialogBoxPrescribe" align='left'>
                            <Link href="https://janssen-pi.co.uk/en/product-information/9"
                                   underline='always'
                                   target="_blank"
                                   onClick={() => handleClickLink("link-product-information-imbruvica")}
                        >
                            https://janssen-pi.co.uk/en/product-information/9
                        </Link>.
                        </div>

                    </Grid>
                    {/*======================================================================================*/}
                    <Grid item xs={1}>
                    </Grid>
                    {/*======================================================================================*/}
                </Grid>
            </div>

            <Box py={1}>
            </Box>
            <div  className="App">
                <Grid container>
                    <Grid item xs={1}>
                    </Grid>

                    <Grid item xs={10} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextSmaller" align="center" >
                        Adverse events should be reported.
                        <span className="custom-text-Myriad-Pro-Regular-SmallPrintBlack">▼ </span>
                        Reporting forms and information can be found at
                        <Link href=" https://yellowcard.mhra.gov.uk" underline='always' target="_blank"
                              onClick={() => handleClickLink("link-adverse-event-yellowcard")}
                        > https://yellowcard.mhra.gov.uk </Link>
                        or search for MHRA Yellow Card in the Google Play or Apple App Store.
                        Adverse events should also be reported to Janssen-Cilag Limited on 01494 567447 or at
                        <Link href="mailto:dsafety@its.jnj.com" underline='always'  target="_blank"
                              onClick={() => handleClickLink("email-adverse-event-dsafety")}
                        > dsafety@its.jnj.com</Link>.
                    </Grid>

                    <Grid item xs={1}>
                    </Grid>
                </Grid>
            </div>

            <Box py={2}>
            </Box>

        </Dialog>
    );
}