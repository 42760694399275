import {getToken} from "./authLib";
import config from "../config";

const baseUrl = "https://openmeet.com/attendee/"
export async function getLinkData(meeting_id){

    const token = getToken();

    if(!token) throw new Error("User not logged in.")

    const response = await fetch(`${config.auth_api}/link?meeting=${meeting_id}`, {
        method: 'GET',
        headers: {
            "Authorization": `Bearer ${token}`
        }
    })

    if(response.ok){
        const body = await response.json()
        return body.encoded;
    }
}

export async function buildLink(room) {
    const token = await getLinkData(room);
     const sliced = token.replace(/=/g, "");
     console.log(sliced);
    return `${baseUrl}${room}?i=${sliced}`;
}