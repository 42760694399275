import useSWR from "swr";
import config from "./config";

const url = config.cms_api;

export default function useCmsString(id){
    const fetcher = url => fetch(url).then(r => r.json())
    const { data, error, revalidate, isValidating } = useSWR(`${url}/strings/${id}`, fetcher, { refreshInterval: 60000 })
    return {
        data,
        error,
        revalidate,
        isValidating,
        isLoading: !data && !error
    }
}