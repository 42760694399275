import Dialog from "./dialog";
import React from "react";
import Link from "@material-ui/core/Link";
import {Box, Grid} from '@material-ui/core';
import * as analytics from "../libs/analytics";

const handleClickLink = (name) => {
    // analytics.recordEvent(`click-${name}-home`);
}

export function CertificateDialog(props) {

    const { url, title, ...rest } = props;

    return (
        <Dialog {...rest}>

            <img src="images/CertificateBanner.jpg" width="100%" />


            <Box py={0}>
            </Box>

            <div  className="App">
                <Grid container>
                    <Grid item xs={1}>
                    </Grid>

                    <Grid item xs={10} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" align="center" >
                        Please email

                        <Link href="mailto:xlhmatters@sciterion.com" underline='always'  target="_blank"
                              onClick={() => handleClickLink("email-adverse-event-dsafety")}
                        > xlhmatters@sciterion.com</Link>.

                        to request a meeting certificate.
                        Please provide your name, place of work and country in the email.
                    </Grid>

                </Grid>



            </div>

            <Box py={7}>
            </Box>
            {/*<Grid>*/}
            {/*    <img src="images/CertificateFooter.jpg" width="100%" />*/}
            {/*</Grid>*/}
        </Dialog>
    );
}