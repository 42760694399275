import Dialog from "./dialog";
import Backdrop from '@material-ui/core/Backdrop';
import React, { useState} from "react";
import {OAtubeDialog} from "./OAtubeDialog";

import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    TextField,
    Grid,
    Link, DialogTitle, Typography
} from '@material-ui/core'
import * as analytics from "../libs/analytics";

const handleClickLink = (name) => {
    analytics.recordEvent(`click-${name}-home`);
}


function OAVideoSection (props) {
    return (
        <Box bgcolor="primary.main" onClick={props.onClick}>
            <img src={props.image} width="100%" alt="" style={{ borderRadius: 20, cursor: "pointer" }} />
            <Box mt={1}>
                <Typography style={{ color: "orange" }} variant="subtitle2">{props.header}</Typography>

                <Typography style={{ color: "white", fontWeight: "bold" }} variant="subtitle1">{props.title}</Typography>
                <Typography style={{ color: "white" }} variant="caption">{props.runtime}</Typography>
                <Box py={0.0}></Box>
                <Typography style={{ color: '#f25721'  }} variant="subtitle1">{props.titleOrange}</Typography>
                <Typography style={{ color: "white" }} variant="caption">{props.title2}</Typography>
                <Box py={0.5}></Box>
                <Typography style={{ color: "white" }} variant="caption" >{props.name}</Typography>      <Box py={0.0}></Box>
                <Typography style={{ color: "white" }} variant="caption">{props.location}</Typography>
                <Box py={0.5}></Box>

                <Typography style={{ color: "white" }} variant="caption">{props.name02}</Typography>     <Box py={0.0}></Box>
                <Typography style={{ color: "white" }} variant="caption">{props.location02}</Typography>
                <Box py={0.5}></Box>

                <Typography style={{ color: "white" }} variant="caption">{props.name03}</Typography>     <Box py={0.0}></Box>
                <Typography style={{ color: "white" }} variant="caption">{props.location03}</Typography>
                <Box py={0.5}></Box>

                <Typography style={{ color: "white" }} variant="caption">{props.name04}</Typography>     <Box py={0.0}></Box>
                <Typography style={{ color: "white" }} variant="caption">{props.location04}</Typography>
                <Box py={0.5}></Box>

                <Typography style={{ color: "white" }} variant="caption">{props.name05}</Typography>     <Box py={0.0}></Box>
                <Typography style={{ color: "white" }} variant="caption">{props.location05}</Typography>
                <Box py={0.5}></Box>

                <Typography style={{ color: "white" }} variant="caption">{props.name06}</Typography>     <Box py={0.0}></Box>
                <Typography style={{ color: "white" }} variant="caption">{props.location06}</Typography>
            </Box>
        </Box>
    )
}

export function OAVideosDialog(props) {

    const { ...rest } = props;

    const [selectedVideo, setSelectedVideo] = useState();
    const [selectedVideoOpen    , setSelectedVideoOpen] = useState(false);

    const videos = [
        // {
        //     image: "/images/video_01.jpg",
        //     analytics_id: "OAvideo_2022_01",
        //     // header: 'WELCOME MESSAGE',
        //     title: 'Welcome Message',
        //     runtime: " (1 minute)",
        //     name: "Prof. Linglart",
        //     location: "Hôpital AP-HP Bicêtre Paris Saclay, Université Paris Saclay,\n" +
        //         "France",
        //     url: "https://player.vimeo.com/video/705718237?h=b02109aba2"
        // },
        {
            image: "/images/video_02.jpg",
            analytics_id: "OAvideo_2022_02",
            // header: 'PRESENTATION',
            title: 'Recent advances in XLH, Including the Kyowa Kirin XLH Registry\n' +
                ' ',
            runtime: " (36 minutes)",
            name: "Prof. Francesco Emma",
            location: "Bambino Gesù Children’s Hospital, Rome,\n" +
                "Italy",
            url: "https://player.vimeo.com/video/799191492?h=f77b625af4"
        },
        {
            image: "/images/video_03.jpg",
            analytics_id: "OAvideo_2022_03",
            // header: 'PRESENTATION',
            title: 'How to optimise treatment outcomes for children with XLH?\n' +
                ' ',
            runtime: " (1 hour 40 minutes)",
            name: "Dr Anya Rothenbuhler",
            location: "Hôpital Bicêtre, Université Paris Saclay, France",
            name02: "Dr Adalbert Raimann",
            location02: "Vienna Bone and Growth Center, Vienna, Austria",
            name03: "Dr Pedro Arango Sancho",
            location03: "Sant Joan de Déu Barcelona Hospital, Spain\n" +
                "",
            url: "https://player.vimeo.com/video/799186366?h=79496267bd"
        },
        {
            image: "/images/video_04.jpg",
            analytics_id: "OAvideo_2022_04",
            // header: 'PRESENTATION',
            title: 'Accelerating the diagnosis of XLH in adults\n' +
                ' ',
            runtime: " (47 minutes)",
            name: "Dr Ali Alzahrani",
            location: "King Faisal Specialist Hospital and Research Centre-Riyadh, Saudi Arabia",
            url: "https://player.vimeo.com/video/799192844?h=dddcc80ebb"

        },
        {
            image: "/images/video_05.jpg",
            analytics_id: "OAvideo_2022_05",
            // header: 'PRESENTATION',
            title: 'How to optimise treatment outcomes for adults with XLH?\n' +
                ' ',
            runtime: " (49 minutes)",
            name: "Dr Lothar Seefried (Co-Chair)",
            location: "University of Würzburg, Germany",
            name02: "Dr Rachel Crowley",
            location02: "St. Vincent's University Hospital, Dublin, Ireland",
            url: "https://player.vimeo.com/video/799195500?h=43353ce9b3"
        },
        {
            image: "/images/video_06.jpg",
            analytics_id: "OAvideo_2022_06",
            // header: 'PRESENTATION',
            title: 'The pathophysiology of XLH – behind the scenes\n' +
                ' ',
            runtime: " (39 minutes)",
            name: "Prof. Justine Bacchetta",
            location: "Hôpital Femme-Mère-Enfant, Lyon, France",
            url: "https://player.vimeo.com/video/799190341?h=8c77810873"
        },
        {
            image: "/images/video_07.jpg",
            analytics_id: "OAvideo_2022_07",
            // header: 'WORKSHOP',
            title: 'Preparing adolescents for transition – the health psychology perspective',
            runtime: " (30 minutes)",
            name: "Dr Jonathan Gibbins",
            location: "Evelina London Children’s Hospital, UK",
            url: "https://player.vimeo.com/video/799193634?h=14d2a3b955"
        },
        {
            image: "/images/video_08.jpg",
            analytics_id: "OAvideo_2022_08",
            // header: 'WORKSHOP',
            title: 'Transition in practice \n' +
                ' ',
            runtime: " (29 minutes)",
            name: "Ian Tucker",
            location: "Bristol Royal Hospital for Children, University Hospitals Bristol NHS Foundation Trust, UK",
            name02: "Prof. Anna Grandone",
            location02: "Università degli Studi della Campania “L. Vanvitelli”, Naples, Italy",
            url: "https://player.vimeo.com/video/799191006?h=17ff5c328e"
        },
        {
            image: "/images/video_09.jpg",
            analytics_id: "OAvideo_2022_09",
            // header: 'WORKSHOP',
            title: 'Working with orthopaedic surgeons to optimise outcomes',
            runtime: " (35 minutes)",
            name: "Dr Gabriel Mindler",
            location: "Orthopaedic Hospital Vienna Speising, Vienna, Austria\n" +
                " ",
            url: "https://player.vimeo.com/video/799194902?h=df6bc40ee3"
        },
        {
            image: "/images/video_10.jpg",
            analytics_id: "OAvideo_2022_10",
            // header: 'WORKSHOP',
            title: 'Clinical case studies – optimising transition of adolescent to adult services',
            runtime: " (1 hour 2 minutes)",
            name: "Prof. Linglart",
            location: "Hôpital AP-HP Bicêtre Paris Saclay, Université Paris Saclay, France\n" +
                " ",
            name02: "Dr Adalbert Raimann",
            location02: "Vienna Bone and Growth Center, Vienna, Austria",
            name03: "Dr Leonid Zeitlin",
            location03: "Dana-Dwek Children’s Hospital, Tel Aviv, Israel",
            url: "https://player.vimeo.com/video/799182543?h=f951bd6a17"
        },
    ]
//
    return (
        <>
            <Dialog bgcolor="primary.main" analytics_id="oavideos" {...rest} >
                {/*<Box bgcolor="white" py={2}></Box>*/}
                <Box width='100%' mb={2}>
                    <img src="/images/temp-banner.png" width="100%" alt="" />
                </Box>

                <Box
                    p={4}
                    bgcolor="primary.main"
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                    bgcolor="primary.main"
                    style={{ display: "flex", overflowY: "scroll" }}
                >
                    {/* Title */}
                    {/*<Box py={2}></Box>*/}
                    {/*<div style={{ width: "100%", height: 20, backgroundColor: "orange" }} />*/}
                    {/*<Box py={2}></Box>*/}
                    {/*<h1 style={{ color: "orange" }}>HEADER</h1>*/}

                    {/* Videos  */}
                        <Grid container spacing={4} justify="space-between">
                            {videos.map((video, index) => (
                                <Grid key={index} item xs={12} md={4}>
                                    <OAVideoSection
                                        image={video.image}
                                        header={video.header}
                                        title={video.title}
                                        runtime={video.runtime}
                                        titleOrange={video.titleOrange}
                                        title2={video.title2}
                                        name={video.name}
                                        location={video.location}
                                        name02={video.name02}
                                        location02={video.location02}
                                        name03={video.name03}
                                        location03={video.location03}
                                        name04={video.name04}
                                        location04={video.location04}
                                        name05={video.name05}
                                        location05={video.location05}
                                        name06={video.name06}
                                        location06={video.location06}

                                        onClick={() => {
                                            setSelectedVideo(video);
                                            setSelectedVideoOpen(true)
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                </Box>

                {/*footer text START*/}
                <Grid
                    container
                    // spacing={0}
                    // direction="column"
                    alignItems="center"
                    justifyContent="center"
                    // style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography className="custom-text-Myriad-Pro-Regular-TinyPrintBlack" align='center'>
                            Prescribing Information (please click on your region/country)
                        </Typography>

                        <Typography className="custom-text-Myriad-Pro-Regular-TinyPrintBlack" align='center'>

                            {/*/!*UAE	AE	https://www.dropbox.com/s/0m9be31jo0fompc/UAE%2C%20KUWAIT%2C%20BAHRAIN_CRY%20PIL.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/UAEKUWAITBAHRAIN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    AE*/}
                            {/*</Link> <span></span>*/}

                            {/*Belgium	BE	https://www.dropbox.com/s/pvazjp5y2icypad/BELGIUM_Crysvita_short%20SPC%28nl%29_BE_Dec2022.pdf?dl=0*/}
                            {/*<Link href="pdf/PIs_for_October_2023_update/BE-BELGIUM_Crysvita_shortSPC(nl)_BE_Dec2022.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    BE*/}
                            {/*</Link> <span></span>*/}

                            {/*/!*BULGARIA	BG	https://www.dropbox.com/s/302l4bfp7la0kca/BG-BULGARIA_PI%20for%20XLH%20only_CLEAN.pdf?dl=0*!/*/}
                            <Link href="pdf/PIs_for_October_2023_update/Bulgaria PI for XLH only_BG_07-2023.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                BG
                            </Link> <span></span>

                            {/*/!*BAHRAIN	BH	https://www.dropbox.com/s/0m9be31jo0fompc/UAE%2C%20KUWAIT%2C%20BAHRAIN_CRY%20PIL.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/UAEKUWAITBAHRAIN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    BH*/}
                            {/*</Link> <span></span>*/}

                            {/*Czech Republic	CZ	https://www.dropbox.com/s/wsymbs7ii2aad2i/CZECH%20REP_Crysvita%20short%20SmPC%2020220922.pdf?dl=0*/}
                            <Link href="pdf/PIs_for_October_2023_update/Czech Republic Crysvita short SmPC 20230531.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                CZ
                            </Link> <span></span>

                            {/*/!*Germany	DE	https://www.dropbox.com/s/kzfb0grqapxia9t/GERMANY_2022-10-11_Crysvita_Pflichttext_Stand_09_2022_f%C3%BCr%20extern.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/DE-GERMANY_2022-10-11_Crysvita_Pflichttext_Stand_09_2022_furextern.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    DE*/}
                            {/*</Link> <span></span>*/}

                            {/*/!*Denmark	DK	https://www.dropbox.com/s/7ta3zoykjojv9pj/DENMARK_Crysvita%20Prescribing%20Information%20DK%20Oct%20%282%29.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/DK-DENMARK_CrysvitaPrescribingInformationDKOct(2).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    DK*/}
                            {/*</Link> <span></span>*/}

                            {/*/!*Spain	ES	https://www.dropbox.com/s/apcrxwx895sn13s/SPAIN_220930%20FT%20Crysvita_V08_Spain.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/ES-SPAIN_220930FTCrysvita_V08_Spain.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    ES*/}
                            {/*</Link> <span></span>*/}

                            {/*/!*Finland	FI	https://www.dropbox.com/s/pyqngi9a7ih5szc/FINLAND_Crysvita%20Prescribing%20Information%20FI%20Sept%2022%20%282%29.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/FI-FINLAND_CrysvitaPrescribingInformationFISept22(2).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    FI*/}
                            {/*</Link> <span></span>*/}

                            {/*Greece	GR	https://www.dropbox.com/s/a805euq0ba07z1i/GREECE_Crysvita_el%20prescr%20inform_09.2022.pdf?dl=0*/}
                            <Link href="pdf/PIs_for_October_2023_update/Greece CRYSVITA_INJ.SOL_10MG_20MG_30MG_SPC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                GR
                            </Link> <span></span>

                            {/*Croatia	HR	https://www.dropbox.com/s/anv6b16bm4oics0/CROATIA_Crysvita_skraceni%20SPC_rev%2021.9.pdf?dl=0*/}
                            <Link href="pdf/PIs_for_November_2023_updateB/CROATIA_English_Crysvita_abbreviated SPC_rev 31.5.2023_For platform.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                HR
                            </Link> <span></span>

                            {/*Hungary	HU	https://www.dropbox.com/s/f8ue7if3nu8dkee/HUNGARY_PI%20for%20XLH%20only_Updated%20for%20Oct%202022_1772_Hungary_draft_HUversion.pdf?dl=0*/}
                            <Link href="pdf/PIs_for_November_2023_update/Crysvita_ XLH only_July2023_Hungary_abbrSmPC_HUversion_V2.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                HU
                            </Link> <span></span>

                            {/*/!*Ireland	IE	https://www.dropbox.com/s/xa96pg2qgcu0cm9/IE-IRELAND_PI%20Crysvita%20Aug22%20IRE%20-%20NI%20%2811%29.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/IE-IRELAND_PICrysvitaAug22IRE-NI(11).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    IE*/}
                            {/*</Link> <span></span>*/}

                            {/*Israel	IL	https://www.dropbox.com/s/p6xlulqws8qoqek/ISRAEL_Crysvita-10mg-20mg-30mg_solution-for-injection_SPC_01-2022-V2_Israel.pdf?dl=0*/}
                            <Link href="pdf/PIs_for_October_2023_update/Israel Crysvita-10mg-20mg-30mg_solution-for-injection_SPC_01-2022-V2 (002).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                IL
                            </Link> <span></span>

                            {/*<Link href="pdf/FINAL PIS/UAEKUWAITBAHRAIN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    KW*/}
                            {/*</Link> <span></span>*/}



                            {/*/!*Luxembourg	LU	https://www.dropbox.com/s/p6ghzdq8gcq1qxs/LUXEMBOURG_Crysvita_short%20SPC%28fr%29_BE-LU_Dec202.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/LU-LUXEMBOURG_Crysvita_shortSPC(fr)_BE-LU_Dec202.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    LU*/}
                            {/*</Link> <span></span>*/}

                            {/*/!*Netherlands	NL	https://www.dropbox.com/s/ghgdqtj6sqkmhux/NETHERLANDS_vSmPC%20Crysvita%20NL%20v102022.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/NL-NETHERLANDS_vSmPCCrysvitaNLv102022.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    NL*/}
                            {/*</Link> <span></span>*/}

                            {/*/!*Norway	NO	 https://www.dropbox.com/s/q8nsm204yttzl97/NORWAY_Crysvita%20Prescribing%20Information%20NO%20Oct%2022%20%282%29.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/NO-NORWAY_CrysvitaPrescribingInformationNOOct22(2).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    NO*/}
                            {/*</Link> <span></span>*/}

                            {/*<Link href="pdf/FINAL PIS/QA-QATAROMAN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    OM*/}
                            {/*</Link> <span></span>*/}

                            {/*Poland	PL	https://www.dropbox.com/s/xhfoyy5rrrs9lt1/POLAND_Crysvita%20PL%20Short%20SmPC_XLH_Oct2022.pdf?dl=0*/}
                            <Link href="pdf/PIs_for_October_2023_update/Poland Crysvita PL Short SmPC_XLH_Oct2023.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                PL
                            </Link> <span></span>

                            {/*/!*Portugal	PT	https://www.dropbox.com/s/8t78wlg544fzcqx/PORTUGAL_Crysvita_IECRCM_with%20sections%204.4%264.5_clean_September2022_Portugal.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/PT-PORTUGAL_Crysvita_IECRCM_withsections4.4&4.5_clean_September2022_Portugal.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    PT*/}
                            {/*</Link> <span></span>*/}

                            {/*/!*Qatar	QA	https://www.dropbox.com/s/q6sex4kefzkyows/QATAR%2C%20OMAN_CRY%20PIL.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/QA-QATAROMAN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    QA*/}
                            {/*</Link> <span></span>*/}

                            {/*Romania	RO	https://www.dropbox.com/s/xnqwdhid3vsa85k/ROMANIA_Crysvita%20RO%20API.pdf?dl=0*/}
                            <Link href="pdf/PIs_for_October_2023_update/Romania_Crysvita_sSmPC_07.2023.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                RO
                            </Link> <span></span>

                            {/*/!*Saudi Arabia	SA	https://www.dropbox.com/s/ehn43jj6reotsb7/SAUDI_sa-spc.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/SA-SAUDI_sa-spc.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    SA*/}
                            {/*</Link> <span></span>*/}

                            {/*/!*Sweden	SE	https://www.dropbox.com/s/e12nec0jinn2f2s/SWEDEN_Crysvita%20Prescribing%20Information%20SE%20Oct%2022%20%282%29.pdf?dl=0*!/*/}
                            {/*<Link href="pdf/FINAL PIS/SE-SWEDEN_CrysvitaPrescribingInformationSEOct22(2).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                            {/*    SE*/}
                            {/*</Link> <span></span>*/}

                            {/*Slovenia	SI	https://www.dropbox.com/s/5btotwllwg9rejo/SLOVENIA_Crysvita_short%20SmPC_10.2022_clean_prepared%2010.2.2023%20%28003%29.pdf?dl=0*/}
                            <Link href="pdf/PIs_for_October_2023_update/Slovenia Crysvita_short SmPC_IA0036_07.2023_clean (002).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                SI
                            </Link> <span></span>

                            {/*SLOVAKIA	SK*/}
                            <Link href="pdf/PIs_for_November_2023_updateB/SK PI for XLH only_Updated for May 2023_1772_Final edited_CLEAN dt_V2.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                                SK
                            </Link> <span></span>

                            {/*<Link href="pdf/AT Austria PI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>AT</Link> <span></span>*/}
                            {/*<Link href="pdf/BE Belgium.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>BE</Link> <span></span>*/}
                            {/*<Link href="pdf/API Crysvita_Czech_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>CZ</Link> <span></span>*/}
                            {/*<Link href="pdf/CRYSVITA_FI_Stand Mai 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>DE</Link> <span></span>*/}

                            {/*<Link href="pdf/Crysvita DK Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>DK</Link> <span></span>*/}
                            {/*<Link href="pdf/Estonia_SMPC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>EE</Link> <span></span>*/}
                            {/*<Link href="pdf/210719 FTR Crysvita_V04_Spain.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>ES</Link> <span></span>*/}

                            {/*<Link href="pdf/EU PI CRYSVITA.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>EU</Link> <span></span>*/}

                            {/*<Link href="pdf/Crysvita FI Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>FI</Link> <span></span>*/}
                            {/*<Link href="pdf/CRYSVITA PI Full Label_A4_UK_July21_V6_AM.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>GB</Link> <span></span>*/}

                            {/*<Link href="pdf/CRYSVITA Abbreviated Prescribing Information Text_GCC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>GCC</Link> <span></span>*/}

                            {/*<Link href="pdf/HR Crysvita_Croatian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>HR</Link> <span></span>*/}

                            {/*<Link href="pdf/API Crysvita_Hungarian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>HU</Link> <span></span>*/}
                            {/*<Link href="pdf/Prescribing Information IRE  NI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>IE</Link> <span></span>*/}
                            {/*<Link href="pdf/Crysvita 10 20 30 SPC-05-2020-2_Isreal.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>IL</Link> <span></span>*/}
                            {/*<Link href="pdf/LU Luxembourg.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>LU</Link> <span></span>*/}

                            {/*<Link href="pdf/NL Netherlands PI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>NL</Link> <span></span>*/}
                            {/*<Link href="pdf/Crysvita NO Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>NO</Link> <span></span>*/}
                            {/*<Link href="pdf/Burosumab sol for inj (Crysvita) SPC Portugal clean.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>PT</Link> <span></span>*/}
                            {/*<Link href="pdf/API Crysvita_Romanian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>RO</Link> <span></span>*/}
                            {/*<Link href="pdf/Crysvita SE Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>SE</Link> <span></span>*/}

                            {/*<Link href="pdf/API Crysvita_Slovenian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>SI</Link> <span></span>*/}

                        </Typography>
                        {/*</div>*/}
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
                {/*footer text END*/}


            </Dialog>

            <OAtubeDialog
                analytics_id={selectedVideo?.analytics_id}
                bgcolor="primary.main"
                maxWidth="xl"
                fullWidth
                open={selectedVideoOpen}
                url={selectedVideo?.url}
                onClose={() => setSelectedVideoOpen(false)}
            />
        </>
    );
}
