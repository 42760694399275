import Dialog from "./dialog";
import React from "react";
import Link from "@material-ui/core/Link";
import {Box, Grid} from '@material-ui/core';
import * as analytics from "../libs/analytics";

const handleClickLink = (name) => {
    analytics.recordEvent(`click-${name}-home`);
}

export function AdverseDialog(props) {

    const { url, title, ...rest } = props;

    return (
        <Dialog {...rest}>

            <Box py={1.0}>
            </Box>

            <img src="images/BlankBanner.jpg" width="100%" />


            {/*<Box py={1}>*/}
            {/*</Box>*/}
            <Grid container>
                <Grid item xs={12} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" align="center" >
                    <div className="custom-text-Myriad-Pro-Bold-MegaPrintHeadline" justify='center'>
                        <Box py={1.0}></Box>
                        Report an Adverse Event
                        <Box py={1.5}></Box>
                    </div>
                    <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline" justify='center'>
                        Adverse events should be reported to Kyowa Kirin
                        <Box py={1.5}></Box>
                    </div>
                </Grid>
            </Grid>


            <div  className="App">
                <Grid container>
                    <Grid item xs={1}>
                    </Grid>


                    <Grid item xs={10} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" align="left" >



                        You can find more information about how Kyowa Kirin manages your personal information in this <span> </span>

                        <Link href="https://international.kyowa-kirin.com/legal/privacy-notice.html" underline='always'  target="_blank"
                              onClick={() => handleClickLink("email-adverse-event-dsafety")}
                        >Privacy Notice</Link>.


                        {/*<Box py={1.0}></Box>*/}

                        {/*<div className="custom-text-Myriad-Pro-Light-DialogBoxAdverseMediumBlack"  justify='left'>*/}
                        {/*    United Kingdom: Adverse Events should be reported.*/}
                        {/*    <Box py={0.0}></Box>*/}
                        {/*    Reporting forms and information can be found at*/}
                        {/*    <Link href="https://yellowcard.mhra.gov.uk" underline='always'  target="_blank"*/}
                        {/*          onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*    > https://yellowcard.mhra.gov.uk.</Link>.*/}
                        {/*    <Box py={0.5}></Box>*/}
                        {/*    Adverse events should also be reported to Kyowa Kirin Ltd on +44 (0)1896 664000,*/}
                        {/*    <Box py={0.0}></Box>*/}
                        {/*    email*/}
                        {/*    <Link href="mailto:medinfo@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*          onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*    > medinfo@kyowakirin.com</Link>.*/}

                        {/*</div>*/}

                        <div className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight"  justify='left'>

                        <Box py={1.7}></Box>

                        {/*- Belgium / Luxembourg: <span> </span>*/}
                        {/*<Link href="mailto:pv-belux@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*>pv-belux@kyowakirin.com</Link>.                                    <Box py={0.5}></Box>*/}

                        {/*- France: <span> </span>*/}
                        {/*<Link href="mailto:pvfrance@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*>pvfrance@kyowakirin.com</Link>.                                    <Box py={0.5}></Box>*/}

                        {/*- Germany: <span> </span>*/}
                        {/*<Link href="mailto:KyowaKirin-PhV@spm2-safety.com" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*>KyowaKirin-PhV@spm2-safety.com</Link>.                                    <Box py={0.5}></Box>*/}

                        {/*- GCC (Bahrain, Kuwait, Oman, Qatar, Saudi Arabia, United Arab Emirates): <span> </span>*/}
                        {/*<Link href="mailto:drugsafetygcc@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*>drugsafetygcc@kyowakirin.com</Link>.                                    <Box py={0.5}></Box>*/}

                        {/*- Netherlands: <span> </span>*/}
                        {/*<Link href="mailto:pv-nl@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*>pv-nl@kyowakirin.com</Link>.                                    <Box py={0.5}></Box>*/}

                        {/*    - Nordics (Denmark, Finland, Iceland, Norway, Sweden): <span> </span>*/}
                        {/*    <Link href="mailto:infose@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*          onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*    > infose@kyowakirin.com</Link>.                                    <Box py={0.5}></Box>*/}

                        {/*- Portugal: <span> </span>*/}
                        {/*<Link href="mailto:adr.ae@pharsolution.com" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*>adr.ae@pharsolution.com</Link>.                                    <Box py={0.5}></Box>*/}

                        {/*    - Spain: <span> </span>*/}
                        {/*    <Link href="mailto:farmacovigilanciaes@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*          onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*    > farmacovigilanciaes@kyowakirin.com</Link>.                                    <Box py={0.5}></Box>*/}

                        {/*- Switzerland: <span> </span>*/}
                        {/*<Link href="mailto:KyowaKirin@pharma-services.ch" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*>KyowaKirin@pharma-services.ch</Link>.                                    <Box py={0.5}></Box>*/}

                        {/*- Ireland: <span> </span>*/}
                        {/*<Link href="mailto:medinfo@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*>medinfo@kyowakirin.com</Link>.<Box py={0.5}></Box>*/}

                        {/*- United Kingdom: <span> </span>*/}
                        {/*<Link href="mailto:medinfo@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*>medinfo@kyowakirin.com</Link>.<Box py={0.5}></Box>*/}

                            - Partner Markets (Bulgaria, Czech Republic, Croatia, Greece, Hungary, Israel, Poland, Romania, Slovakia, Slovenia)
                            : <span> </span>
                            <Link href="mailto:medinfo@kyowakirin.com" underline='always'  target="_blank"
                                  onClick={() => handleClickLink("email-adverse-event-dsafety")}
                            >medinfo@kyowakirin.com</Link>.                                    <Box py={0.5}></Box>




                                <Box py={2.2}></Box>
                                Reporting forms and information can be found at <span> </span>
                                <Link href="https://yellowcard.mhra.gov.uk" underline='always'  target="_blank"
                                     onClick={() => handleClickLink("email-adverse-event-dsafety")}
                               >https://yellowcard.mhra.gov.uk</Link>.
                               <Box py={0.2}></Box>
                               Adverse events should also be reported to Kyowa Kirin Ltd on +44 (0)1896 664000,
                                <Box py={0.2}></Box>
                               email  <span> </span>
                               <Link href="mailto:medinfo@kyowakirin.com" underline='always'  target="_blank"
                                      onClick={() => handleClickLink("email-adverse-event-dsafety")}
                                >medinfo@kyowakirin.com</Link>.



            </div>
            <Box py={1.0}></Box>

                        {/*United Kingdom: Adverse Events should be reported.*/}
                        {/*<Box py={0.0}></Box>*/}
                        {/*Reporting forms and information can be found at*/}
                        {/*<Link href="https://yellowcard.mhra.gov.uk" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*> https://yellowcard.mhra.gov.uk.</Link>.*/}
                        {/*<Box py={0.5}></Box>*/}
                        {/*Adverse events should also be reported to Kyowa Kirin Ltd on +44 (0)1896 664000,*/}
                        {/*<Box py={0.0}></Box>*/}
                        {/*email*/}
                        {/*<Link href="mailto:medinfo@kyowakirin.com" underline='always'  target="_blank"*/}
                        {/*      onClick={() => handleClickLink("email-adverse-event-dsafety")}*/}
                        {/*> medinfo@kyowakirin.com</Link>.*/}

                    </Grid>





                </Grid>
                <Box py={1.0}></Box>
                <Grid container>
                    <Grid item xs={1} className="custom-text-Myriad-Pro-Light-DialogBoxAdverseMediumRegBlack" align="left" >
                    </Grid>

                <Grid item xs={7.5} className="custom-text-Myriad-Pro-Light-DialogBoxAdverseSmallBlack" align="left" >
                    <div className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight"  justify='left'>
                        {/*At a minimum, please report information regarding the patient*/}
                        {/*<Box py={0.0}></Box>*/}
                        {/*(initials, gender), which Kyowa Kirin product was used, the event that occurred, and your contact*/}
                        {/*<Box py={0.0}></Box>*/}
                        {/*information as the reporter (including your country).*/}
                        <img src="images/spacer.jpg" width="8.5%" />
                        <img src="images/AdverseFooterLeft.jpg" width="55%" />
                        <img src="images/AdverseFooter2.jpg" width="30%" />
                    </div>

                </Grid>
                {/*<Grid item xs={3} className="custom-text-Myriad-Pro-Light-DialogBoxAdverseSmallBlack" align="left" >*/}
                {/*    <img src="images/AdverseFooter2.jpg" width="100%" />*/}
                {/*</Grid>*/}

                    <Grid item xs={0.5} className="custom-text-Myriad-Pro-Light-DialogBoxAdverseSmallBlack" align="left" >
                    </Grid>

                </Grid>
                <Box py={1.0}></Box>
            </div>



        </Dialog>
    );
}
