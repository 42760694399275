import jwt_decode from "jwt-decode";
import {navigate} from "@reach/router";

export function isTokenValid(){
    const token = localStorage.getItem("access_token");
    if(!token) return false;
    try{
        const decoded = jwt_decode(token);
        const exp = decoded.exp;
        if (Date.now() >= exp * 1000) {
            return false;
        }
        return true;
    }catch (e) {
        console.error("Failed to decode token.")
        return false;
    }
}

export function parseToken(){
    const token = localStorage.getItem("access_token");
    if(!token) return false;
    const decoded = jwt_decode(token);
    return decoded;
}

export function getToken(){
    const token = localStorage.getItem("access_token");
    if(!token) return false;
    return token;
}

export function hasPermission(permission){
    const token = parseToken();
    console.log(token);
    return Boolean(token && token.admin && token.permissions && token.permissions.includes(permission));
}

export function logout(){
    localStorage.removeItem("access_token");
    navigate('/login', { replace: true })
}