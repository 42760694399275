const local = {
    cms_api: "https://u7imuqp1nk.execute-api.eu-central-1.amazonaws.com",
    auth_api: "https://v9expgx3re.execute-api.eu-central-1.amazonaws.com",
    analytics_api: "https://pgjgo4nzki.execute-api.eu-central-1.amazonaws.com",
    some_bool: true
};

const dev = {
    cms_api: "https://u7imuqp1nk.execute-api.eu-central-1.amazonaws.com",
    auth_api: "https://v9expgx3re.execute-api.eu-central-1.amazonaws.com",
    analytics_api: "https://pgjgo4nzki.execute-api.eu-central-1.amazonaws.com",
    some_bool: true
};

const prod = {
    cms_api: "https://9nyn4pdclk.execute-api.eu-central-1.amazonaws.com",
    auth_api: "https://140wuke8tb.execute-api.eu-central-1.amazonaws.com",
    analytics_api: "https://rsuqovbynh.execute-api.eu-central-1.amazonaws.com",
    some_bool: false
};

// Default to dev if not set
const config = (function ()
{
    console.log("Using stage: " + process.env.REACT_APP_VERCEL_ENV)

    console.log(process.env)

    switch (process.env.REACT_APP_VERCEL_ENV)
    {
        case "prod":
            return prod;
        case "dev":
            return dev;
        case "local":
            return local;
        default:
            return local;
    }
})(local);

export default {
    ...config
};