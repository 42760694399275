import Dialog from "./dialog";
import React, {useEffect, useState} from "react";
import {recordEvent} from "../libs/analytics";
import {SurveyVideo01} from "./SurveyVideo01";
import Rating from '@material-ui/lab/Rating';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";

export function OAtubeDialog(props) {

    // const question1=null;
    // const question2=null;
    // const question3=null;

    const { title, url, open, onClose, ...rest } = props;
    const [prevState, setPrevState] = useState(false);

    const [feedbackOpen, setFeedbackOpen] = useState(false);

    useEffect(() => {
        if(prevState === open) return;
        if(open){
            // recordEvent("click-session-dialog-open")
        }else{
            // recordEvent("click-session-dialog-close")

            //replace this with survey modal
            // recordEvent("click-session-dialog-close"+props.url)

        }
        setPrevState(open);
    }, [open, prevState])

    const handleClose = () => {
        setFeedbackOpen(true);
    }

    const handleSubmitFeedback = () => {

        // console.log(question1);
        // console.log(question2);
        // console.log(question3);

        setFeedbackOpen(false)
        /// record feedback

        recordEvent("click-session-survey-"+props.url+"-usefulness-"+question1)
        recordEvent("click-session-survey-"+props.url+"-treatOrRefer-"+question2)
        recordEvent("click-session-survey-"+props.url+"-confidence-"+question3)
        resetquestion1();
        resetquestion2();
        resetquestion3();
        onClose()
    }

    const [question1, setQuestion1] = useState();
    const [question2, setQuestion2] = useState();
    const [question3, setQuestion3] = useState();
    const resetquestion1 = () => {setQuestion1('');}
    const resetquestion2 = () => {setQuestion2('');}
    const resetquestion3 = () => {setQuestion3('');}
    const feedbackComplete = question1 && question2 && question3;

    return (
        <>
            <Dialog open={feedbackOpen} disableClose disableForceFullscreen>
                <DialogContent>
                    <img src="images/bannerLogin2023.svg" width="100%" />
                    <Box my={4}/>
                    <Typography>
                        Your feedback is important to us and allows us to improve future educational events.
                        Please complete this short, 30 second, survey.
                    </Typography>
                    <Box my={4}/>

                    <Typography>
                        1: How useful did you find the presentation
                        for your clinical practice?
                    </Typography>
                    <Typography>
                        1 = Poor, 5 = Excellent
                    </Typography>
                    <Rating
                        defaultValue={0}
                        precision={1}
                        value={question1}
                        onChange={(event, value) => setQuestion1(value)}
                    />

                    <Box my={2}/>
                    <Typography>
                        2: Do you currently treat children diagnosed with XLH or would you
                        refer them to an XLH specialist / expert centre for treatment?
                    </Typography>
                    <RadioGroup value={question2} onChange={(event) => setQuestion2(event.target.value)}>
                        <FormControlLabel value="I refer" control={<Radio />} label="I refer" />
                        <FormControlLabel value="I treat" control={<Radio />} label="I treat" />
                    </RadioGroup>
                    <Box my={2}/>
                    <Typography>
                        3: After watching the video,
                        do you feel more confident in managing patients with XLH?
                    </Typography>
                    <RadioGroup value={question3} onChange={(event) => setQuestion3(event.target.value)}>
                        <FormControlLabel value="Yes, more confident" control={<Radio />} label="Yes, more confident" />
                        <FormControlLabel value="Slightly more confident" control={<Radio />} label="Slightly more confident" />
                        <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                    <Box my={4}/>
                    <Typography>
                        Thank you for your feedback. It is greatly appreciated.
                    </Typography>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmitFeedback} disabled={!feedbackComplete}>Submit feedback</Button>
                    <Box my={0}/>
                    <Button onClick={handleSubmitFeedback} disabled={feedbackComplete}>Return to Videos</Button>
                    {/*<Box my={4}/>*/}
                </DialogActions>
            </Dialog>
            <Dialog open={open} onClose={handleClose} {...rest}>
                <iframe
                    src={props.url}
                    // style={{marginTop: "0px", border: "none", minHeight: "4vh"}}
                    // style={{width: "100%"}}
                    style={{ marginTop: "45px", border: "none", minHeight: "75vh" }}
                    scrolling="no"
                    name="myiFrame"
                    // width="100%"
                    // height="100%"
                    allowFullScreen
                />
            </Dialog>
        </>


    );
}


