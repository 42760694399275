import {
    Box,
    DialogContent,
    Typography
} from '@material-ui/core'
import React from 'react';
import Dialog from '../components/dialog'

export default function Unsupported(){
    return (

        <Box width="100%" height="100%">
            <Dialog open maxWidth='md' hideBackdrop>
                <DialogContent>
                    <Box p={4}>
                        <Typography>Your browser is not supported. Please use an updated browser</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    )
}