import Dialog from "./dialog";
import {
    Box,
    Button,
    Grid,
} from "@material-ui/core";
import React from "react";

export function ExternalLinkDialogExhibition(props) {

    const { url, title, onClose, ...rest } = props;

    return (
        <Dialog {...rest} onClose={onClose}>
            {/*<Typography color="primary" variant="h5" style={{fontWeight: "bold"}}>*/}
            {/*    <h1 className="custom-text2"></h1>*/}
            {/*    Link to External Resource*/}
            {/*</Typography>*/}
            <img src="images/leave.jpg" width="100%" />
            {/*<Box minHeight={500} display='flex' alignItems='center'>*/}
                <Box width="100%" >
                    <Grid container justify="center">

                        {/*<Grid item xs={1}>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={10}>*/}
                        {/*    <div  className="custom-text-Myriad-Pro-Regular-ExternalHeadline" align='center' >*/}
                        {/*        <Box py={0}></Box>*/}
                        {/*        Please note you are leaving the*/}
                        {/*        <Box py={0}></Box>*/}
                        {/*        Janssen Nurse Study Day meeting platform*/}
                        {/*        <Box py={0}></Box>*/}
                        {/*        to go to an external website.*/}
                        {/*        <Box py={1}></Box>*/}
                        {/*        This link will take you to a website*/}
                        {/*        <Box py={0}></Box>*/}
                        {/*        where this Privacy policy does not apply.*/}
                        {/*        <Box py={0}></Box>*/}

                        {/*    </div>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={1}>*/}
                        {/*</Grid>*/}


                        <Grid item>
                            {/*<Box py={3}></Box>*/}
                            <Button onClick={() => {
                                window.open(url, "_blank");
                                onClose();
                            }} variant="outlined">
                                Accept
                                </Button>
                            <Box py={2}></Box>
                        </Grid>
                    </Grid>

                </Box>

            {/*</Box>*/}
        </Dialog>

    );
}

