import {Box, Dialog as MuiDialog, IconButton, Paper, useMediaQuery, useTheme} from "@material-ui/core";
import styled from 'styled-components'
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import {Fullscreen, FullscreenExit} from "@material-ui/icons";
import {useEffect, useState} from "react";
import {recordEvent} from "../libs/analytics";

const StyledPaper = styled(Paper)`
  //left border removed for this project
  //border-left: solid 15px #022c58;
`

export default function Dialog(props){
    const theme = useTheme();

    const fullscreenRecommended = useMediaQuery(theme.breakpoints.down('sm'))
    const [forceFullscreen, setForceFullscreen] = useState(fullscreenRecommended);

    const { open, children, disableClose, disableForceFullscreen, analytics_id, ...rest } = props;

    const handleToggleFullScreen = () => {
        setForceFullscreen(!forceFullscreen)
    }

    const [prevState, setPrevState] = useState(false);


    useEffect(() => {

        if (prevState === open) return;

        if (open) {
            recordEvent(analytics_id ? `open-modal-${analytics_id}` : "open-modal")

        } else {
            recordEvent(analytics_id ? `close-modal-${analytics_id}` : "close-modal")
        }

        setPrevState(open);
    }, [open, prevState])

    return (
        <MuiDialog open={open} fullScreen={fullscreenRecommended || forceFullscreen} {...rest} PaperComponent={StyledPaper}>
            {children}
            {!disableClose && (
                <Box p={1} position="absolute" top={0} right={0}>
                    <IconButton size="small" onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}
            {!disableForceFullscreen && !fullscreenRecommended && (
                <Box p={1} position="absolute" top={0} right={40}>
                    <IconButton size="small" onClick={handleToggleFullScreen}>
                        {forceFullscreen ? <FullscreenExit/> : <Fullscreen /> }

                    </IconButton>
                </Box>
            )}
        </MuiDialog>
    )
}