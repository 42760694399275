import Dialog from "./dialog";
import React from "react";
import {Box, Typography} from "@material-ui/core";

export function VideoDialog(props) {

    const { src, type, ...rest } = props;

    return (
        <Dialog {...rest} maxWidth="lg">

            <Box ml={5} mt={1}>
                <Typography color="primary" variant="h5" style={{fontWeight: "bold"}}>
                    Welcome Video
                </Typography>
            </Box>

            <video style={{ border: "none", objectFit: "contain", zIndex: 1}} width="100%" height="100%" controls>
                <source src={src} type={type} />
            </video>
        </Dialog>
    );
}




