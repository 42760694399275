import { ImageMap } from '@qiuz/react-image-map';
import React, {useEffect, useState} from 'react';
import {Box, Divider, Hidden, List, ListItem, ListItemText} from '@material-ui/core'
import {navigate} from "@reach/router";
import {ImageDialog} from "../components/ImageDialog";
import * as analytics from "../libs/analytics";
import {ExternalLinkDialog} from "../components/ExternalLinkDialog";
import {ExternalLinkDialogExhibition} from "../components/ExternalLinkDialogExhibition";
import {MedicalInfoAeMessageDialogExhibitions} from "../components/MedicalInfoAeMessageDialogExhibitions";
import {useIntercom} from "react-use-intercom";
import {ExhibitionLinkDialog} from "../components/ExhibitionLinkDialog";
import {isTokenValid, logout} from "../libs/authLib";
import {getLinkData} from "../libs/openAudienceLib";
// import {IframeDialog} from "../components/IframeDialog";
// import {PDFDialog} from "../components/PDFDialog";


//todo - make sure as many images are preloading as possible
//todo - rename images to match project id
//todo - test analytics on desktop and mobile
//todo - check all names are correct

const mapArea = [
    {
        "id" : "medicalInfoAe",
        "width": "24.8%",
        "height": "26%",
        "left": "1.6%",
        "top": "2.2%",
        "href": "17"
    },
    {
        "id": "EventContact01",
        "width": "23.2%",
        "height": "7.1%",
        "left": "28.1%",
        "top": "4.9%",
        "href": "18"
    },
    {
        "id": "visitHemeHub",
        "width": "21.7%",
        "height": "16.1%",
        "left": "76.0%",
        "top": "6.9%",
        "href": "99"
    },
    {
        "id" : "BoothURL01",
        "width": "15.7%",
        "height": "10%",
        "left": "3.7%",
        "top": "40.2%",
        "href": "01"
    },
    {
        "id" : "BoothDL01",
        "width": "9.0%",
        "height": "5%",
        "left": "3.7%",
        "top": "53.2%",
        "href": "02"
    },
    {
        "id" : "BoothContact01",
        "width": "7.0%",
        "height": "5%",
        "left": "12.7%",
        "top": "52.2%",
        "href": "03"
    },
    {
        "id" : "BoothURL02",
        "width": "12.7%",
        "height": "10%",
        "left": "25.7%",
        "top": "41.2%",
        "href": "04"
    },
    {
        "id" : "BoothDL02",
        "width": "7.7%",
        "height": "4%",
        "left": "25.7%",
        "top": "52.2%",
        "href": "05"
    },
    {
        "id" : "BoothContact02",
        "width": "5.7%",
        "height": "4%",
        "left": "32.7%",
        "top": "52.2%",
        "href": "06"
    },
    {
        "id" : "BoothURL03",
        "width": "10.7%",
        "height": "10%",
        "left": "44.7%",
        "top": "41.2%",
        "href": "07"
    },
    {
        "id" : "BoothDL03",
        "width": "7.7%",
        "height": "4%",
        "left": "44.7%",
        "top": "52.2%",
        "href": "08"
    },
    {
        "id" : "BoothContact03",
        "width": "5.7%",
        "height": "4%",
        "left": "50.7%",
        "top": "52.2%",
        "href": "09"
    },
    {
        "id" : "BoothURL04",
        "width": "11.7%",
        "height": "10%",
        "left": "61.7%",
        "top": "41.2%",
        "href": "10"
    },
    {
        "id" : "BoothDL04",
        "width": "7.7%",
        "height": "4%",
        "left": "61.7%",
        "top": "52.2%",
        "href": "11"
    },
    {
        "id" : "BoothContact04",
        "width": "5.7%",
        "height": "4%",
        "left": "67.7%",
        "top": "52.2%",
        "href": "12"
    },
    {
        "id" : "BoothURL05",
        "width": "15.0%",
        "height": "10%",
        "left": "80.7%",
        "top": "40.2%",
        "href": "13"
    },
    {
        "id" : "BoothDL05",
        "width": "8.0%",
        "height": "5%",
        "left": "80.7%",
        "top": "52.2%",
        "href": "14"
    },
    {
        "id" : "BoothContact05",
        "width": "7.0%",
        "height": "5%",
        "left": "88.7%",
        "top": "53.2%",
        "href": "15"
    },
    {
        "id" : "back",
        "width": "7.5%",
        "height": "7.0%",
        "left": "21.9%",
        "top": "70.0%",
        "href": "16"
    },
    // {
    //not used
    //     "id": "chat",
    //     "width": "5.0%",
    //     "height": "7.0%",
    //     "left": "19.6%",
    //     "top": "40.9%",
    //     "href": "19"
    // },
]

// const baseUrl = "https://openmeet.com/prostate_cancer_uk_summit_2021"

export default function Exhibitions(){

    const [loading, setLoading] = useState(2);
    const loaded = () => setLoading(count => count - 1);

    const [exhibitionLinkDialogOpen, setExhibitionLinkDialogOpen] = useState(true);
    const [medicalInfoAeOpen, setMedicalInfoAeOpen] = useState(false);

    const [DLinfoDialog01Open, setDLinfoDialog01Open] = useState(false);
    const [DLinfoDialog02Open, setDLinfoDialog02Open] = useState(false);
    const [DLinfoDialog03Open, setDLinfoDialog03Open] = useState(false);
    const [DLinfoDialog04Open, setDLinfoDialog04Open] = useState(false);
    const [DLinfoDialog05Open, setDLinfoDialog05Open] = useState(false);

    const [BoothContact01Open, setBoothContact01Open] = useState(false);
    const [BoothContact02Open, setBoothContact02Open] = useState(false);
    const [BoothContact03Open, setBoothContact03Open] = useState(false);
    const [BoothContact04Open, setBoothContact04Open] = useState(false);
    const [BoothContact05Open, setBoothContact05Open] = useState(false);

    const [BoothURL01Open, setBoothURL01Open] = useState(false);
    const [BoothURL02Open, setBoothURL02Open] = useState(false);
    const [BoothURL03Open, setBoothURL03Open] = useState(false);
    const [BoothURL04Open, setBoothURL04Open] = useState(false);
    const [BoothURL05Open, setBoothURL05Open] = useState(false);

    const [visitHemeHubOpen, setvisitHemeHubOpen] = useState(false);
    const [back, setBackOpen] = useState(false);

    // const [session01Open, setSession01Open] = useState(false);
    // const [pdfOpen, setPdfOpen] = useState(false);
    // const [chatOpen, setChatOpen] = useState(false);
    // const [guideOpen, setGuideOpen] = useState(false);

    const { boot, show } = useIntercom();

    useEffect(() => {
        if(!isTokenValid()) {
            logout()
        }
    }, [])

    useEffect(() => {
        //track entry
        analytics.recordEvent("navigate-enter-exhibitions")

        return () => {
            //track exit
            analytics.recordEvent("navigate-exit-exhibitions")
        }
    }, [])

    const onMapClick = async (area) => {
        if(area.id){
            switch (area.id){
                // case "SpeakToTeam": {
                //     handleClickSession1();
                //     break;
                // }
                // case "pdf": {
                //     handleClickPdf();
                //     break;
                // }
                case "back": {
                    //TODO I changed this for conformity
                    handleClickHome();
                    // navigate("/")
                }
                case "visitHemeHub": {
                    handleClickvisitHemeHub();
                    break;
                }
                case "BoothDL01": {
                    handleClickDLinfoDialog01();
                    break;
                }
                case "BoothDL02": {
                    handleClickDLinfoDialog02();
                    break;
                }
                case "BoothDL03": {
                    handleClickDLinfoDialog03();
                    break;
                }
                case "BoothDL04": {
                    handleClickDLinfoDialog04();
                    break;
                }
                case "BoothDL05": {
                    handleClickDLinfoDialog05();
                    // handleClickPdf();
                    break;
                }

                case "BoothContact01": {
                    handleClickBoothContact01();
                    break;
                }
                case "BoothContact02": {
                    handleClickBoothContact02();
                    break;
                }
                case "BoothContact03": {
                    handleClickBoothContact03();
                    break;
                }
                case "BoothContact04": {
                    handleClickBoothContact04();
                    break;
                }
                case "BoothContact05": {
                    handleClickBoothContact05();
                    break;
                }
                case "EventContact01": {
                    handleClickEventContact01();
                    break;
                }

                case "BoothURL01": {
                    handleClickBoothURL01();
                    break;
                }
                case "BoothURL02": {
                    handleClickBoothURL02();
                    break;
                }
                case "BoothURL03": {
                    handleClickBoothURL03();
                    break;
                }
                case "BoothURL04": {
                    handleClickBoothURL04();
                    break;
                }
                case "BoothURL05": {
                    handleClickBoothURL05();
                    break;
                }

                case "medicalInfoAe": {
                    handleClickMedicalInfoAe();
                    break;
                }
                // case "chat": {
                //     handleClickChat();
                //     // show()
                //     break;
                // }
            }
        }
    }
    const handleClickMedicalInfoAe = () => {
        analytics.recordEvent("click-medicalInfoAe-exhibitions")
        setMedicalInfoAeOpen(true);
    }
    const handleClickBoothURL01 = () => {
        setBoothURL01Open(true);
        analytics.recordEvent("click-boothURL-lymphoma")
    }
    const handleClickBoothURL02 = () => {
        setBoothURL02Open(true);
        analytics.recordEvent("click-boothURL-bloodcancer")
    }
    const handleClickBoothURL03 = () => {
        setBoothURL03Open(true);
        analytics.recordEvent("click-boothURL-myeloma")
    }
    const handleClickBoothURL04 = () => {
        setBoothURL04Open(true);
        analytics.recordEvent("click-boothURL-canRehab")
    }
    const handleClickBoothURL05 = () => {
        setBoothURL05Open(true);
        analytics.recordEvent("click-boothURL-LeukaemiaCare")
    }

    const handleClickBoothContact01 = () => {
        window.open("mailto:r.eade@lymphoma-action.org.uk", '_blank')
        analytics.recordEvent("click-boothContact-lymphoma")
    }
    const handleClickBoothContact02 = () => {
        window.open("mailto:hcpnetwork@bloodcancer.org.uk", '_blank')
        analytics.recordEvent("click-boothContact-bloodcancer")
    }
    const handleClickBoothContact03 = () => {
        window.open("mailto:Suzanne.Renwick@myeloma.org.uk", '_blank')
        analytics.recordEvent("click-boothContact-myeloma")
    }
    const handleClickBoothContact04 = () => {
        window.open("mailto:info@canrehab.co.uk", '_blank')
        analytics.recordEvent("click-boothContact-canRehab")
    }
    const handleClickBoothContact05 = () => {
        window.open("mailto:info@leukaemiacare.org.uk", '_blank')
        analytics.recordEvent("click-boothContact-leukaemiaCare")
    }

    const handleClickEventContact01 = () => {
        window.open("mailto:medinfo@its.jnj.com", '_blank')
        analytics.recordEvent("click-emailMedicalInformationTeam")
    }

    const handleClickDLinfoDialog01 = () => {
        analytics.recordEvent("click-dLinfoDialog-lymphoma")
        window.open("pdf/Exhibition_Lymphoma_Action_links.pdf", "_blank")
    }
    const handleClickDLinfoDialog02 = () => {
        analytics.recordEvent("click-dLinfoDialog-bloodcancer")
        window.open("pdf/Exhibition_Blood_Cancer_UK_links.pdf", "_blank")
    }
    const handleClickDLinfoDialog03 = () => {
        analytics.recordEvent("click-dLinfoDialog-myeloma")
        window.open("pdf/Exhibition_Myeloma_UK_links.pdf", "_blank")
    }
    const handleClickDLinfoDialog04 = () => {
        analytics.recordEvent("click-dLinfoDialog-canRehab")
        window.open("pdf/Exhibition_CanRehab_links.pdf", "_blank")
    }
    const handleClickDLinfoDialog05 = () => {
        analytics.recordEvent("click-dLinfoDialog-leukaemia")
        window.open("pdf/Exhibition_Leukaemia_Care_links_DR.pdf", "_blank")
    }
    const handleClickvisitHemeHub = () => {
        analytics.recordEvent("click-visitHemeHub-exhibitions")
        setvisitHemeHubOpen(true);
    }
    const handleClickHome = () => {
        analytics.recordEvent("click-navigate-home-from-exhibitions")
        navigate("/")
    }
    // const handleClickChat = () => {
    //     analytics.recordEvent("click-chat-exhibitions")
    //     show()
    // }
    return (
        <>
            {/*Preload images and track progress */}
            <img src="images/exhibitions.jpg" style={{display: "none"}} onLoad={() => loaded()} />
            <img src="images/exhibitionOverlay.png" style={{display: "none"}} onLoad={() => loaded()} />
            <img src="images/dialog_exhibitions.png" style={{display: "none"}} onLoad={() => loaded()} />

            <ExhibitionLinkDialog open={exhibitionLinkDialogOpen} onClose={() => setExhibitionLinkDialogOpen(false)} />

            <ExternalLinkDialog
                title="Visit Janssen Haematology Hub"
                url='https://webaccess-janssen.com/interaction/v2/4be12f32-7858-4929-a80a-e0bccf2c0325/login?client_id=teqgwpkk3pnqf64994bg3rcm5sbzrj65'
                open={visitHemeHubOpen}
                onClose={() => setvisitHemeHubOpen(false)}
                maxWidth="sm"
                fullWidth
            />
            <ExternalLinkDialogExhibition
                title="BoothURL01"
                url='https://lymphoma-action.org.uk'
                open={BoothURL01Open}
                onClose={() => setBoothURL01Open(false)}
                maxWidth="sm"
                fullWidth
            />
            <ExternalLinkDialogExhibition
                title="BoothURL02"
                url='https://bloodcancer.org.uk'
                open={BoothURL02Open}
                onClose={() => setBoothURL02Open(false)}
                maxWidth="sm"
                fullWidth
            />
            <ExternalLinkDialogExhibition
                title="BoothURL03"
                url='https://www.myeloma.org.uk'
                open={BoothURL03Open}
                onClose={() => setBoothURL03Open(false)}
                maxWidth="sm"
                fullWidth
            />
            <ExternalLinkDialogExhibition
                title="BoothURL04"
                url='http://canrehab.co.uk'
                open={BoothURL04Open}
                onClose={() => setBoothURL04Open(false)}
                maxWidth="sm"
                fullWidth
            />
            <ExternalLinkDialogExhibition
                title="BoothURL05"
                url='https://leukaemiacare.org.uk'
                open={BoothURL05Open}
                onClose={() => setBoothURL05Open(false)}
                maxWidth="sm"
                fullWidth
            />
            <MedicalInfoAeMessageDialogExhibitions
                title="Medical Info"
                src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={medicalInfoAeOpen}
                onClose={() => setMedicalInfoAeOpen(false)}
                maxWidth="sm"
                // fullWidth
                // fullScreen
            />

            <Hidden xsDown>
                <div className="click-region-outer">
                    <div className="click-region-inner">
                        <ImageMap
                            className="click-region"
                            src={"images/exhibitions.jpg"}
                            map={mapArea}
                            onMapClick={onMapClick}
                        />
                        <img
                            className="click-region"
                            src="images/exhibitionOverlay.png"
                            width="100%"
                            style={{zIndex: 1000, pointerEvents: "none"}}
                        />
                    </div>
                </div>
            </Hidden>
            <Hidden smUp>
                <Box width="100%" height="100%">
                    <img src='images/exhibitions.jpg' width="100%"  alt=""/>
                    <List>
                        <ListItem button onClick={() => navigate('/')}>
                            <ListItemText
                                primary="◁ Back to the Lobby"
                            />
                        </ListItem>

                        {/*/////////*/}
                        <Divider/>
                        {/*/////////*/}

                        <ListItem button onClick={handleClickMedicalInfoAe}>
                            <ListItemText
                                primary="Prescribing Information"
                                secondary=""
                            />
                        </ListItem>

                        <ListItem button onClick={handleClickvisitHemeHub}>
                            <ListItemText
                                primary="Visit the  Haematology Hub"
                                secondary=""
                            />
                        </ListItem>

                        <ListItem button onClick={handleClickEventContact01}>
                            <ListItemText
                                primary="Email Janssen's Medical Information Team"
                                secondary=""
                            />
                        </ListItem>
                        {/*/////////*/}
                        <Divider/>
                        {/*/////////*/}

                        <ListItem button onClick={handleClickBoothURL01}>
                            <ListItemText
                                primary="Visit www.lymphoma-action.org.uk"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickDLinfoDialog01}>
                            <ListItemText
                                primary="Download Lymphoma Action information"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickBoothContact01}>
                            <ListItemText
                                primary="Contact Lymphoma Action"
                                secondary=""
                            />
                        </ListItem>

                        {/*/////////*/}
                        <Divider/>
                        {/*/////////*/}

                        <ListItem button onClick={handleClickBoothURL02}>
                            <ListItemText
                                primary="Visit www.bloodcancer.org.uk"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickDLinfoDialog02}>
                            <ListItemText
                                primary="Download Blood Cancer UK information"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickBoothContact02}>
                            <ListItemText
                                primary="Contact Blood Cancer UK"
                                secondary=""
                            />
                        </ListItem>

                        {/*/////////*/}
                        <Divider/>
                        {/*/////////*/}

                        <ListItem button onClick={handleClickBoothURL03}>
                            <ListItemText
                                primary="Visit www.myeloma.org.uk"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickDLinfoDialog03}>
                            <ListItemText
                                primary="Download MyelomaUK information"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickBoothContact03}>
                            <ListItemText
                                primary="Contact MyelomaUK Action"
                                secondary=""
                            />
                        </ListItem>

                        {/*/////////*/}
                        <Divider/>
                        {/*/////////*/}

                        <ListItem button onClick={handleClickBoothURL04}>
                            <ListItemText
                                primary="Visit www.canrehab.co,uk"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickDLinfoDialog04}>
                            <ListItemText
                                primary="Download CanRehab information"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickBoothContact04}>
                            <ListItemText
                                primary="Contact CanRehab"
                                secondary=""
                            />
                        </ListItem>

                        {/*/////////*/}
                        <Divider/>
                        {/*/////////*/}

                        <ListItem button onClick={handleClickBoothURL05}>
                            <ListItemText
                                primary="Visit www.leukaemiacare.org.uk"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickDLinfoDialog05}>
                            <ListItemText
                                primary="Download Leukaemia Care information"
                                secondary=""
                            />
                        </ListItem>
                        <ListItem button onClick={handleClickBoothContact05}>
                            <ListItemText
                                primary="Contact Leukaemia Care"
                                secondary=""
                            />
                        </ListItem>

                        {/*/////////*/}
                        <Divider/>
                        {/*/////////*/}

                    </List>
                </Box>
            </Hidden>
        </>
    )
}