import Dialog from "./dialog";
import React from "react";
import Link from "@material-ui/core/Link";
import {Box, Grid} from '@material-ui/core';
import * as analytics from "../libs/analytics";

const handleClickLink = (name) => {
    analytics.recordEvent(`click-${name}-home`);
}

export function MedinfoRequest(props) {

    const { url, title, ...rest } = props;

    return (
        <Dialog {...rest}>
            <Box py={1.0}></Box>
            <img src="images/BlankBanner.jpg" width="100%" />


            <Box py={1}>
            </Box>


            <Grid container>
                <Grid item xs={12} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" align="center" >
                    <div className="custom-text-Myriad-Pro-Bold-MegaPrintHeadline" justify='center'>
                        Medical Information
                        <Box py={0.5}></Box>
                        Request
                        <Box py={1.5}></Box>
                    </div>
                </Grid>
            </Grid>

            <div  className="App">
                <Grid container>
                    {/*<Grid item xs={1}>*/}
                    {/*</Grid>*/}

                    <Grid item xs={12} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" align="center" >
                        Please contact Kyowa Kirin Medical Information at

                        <Link href="mailto:medinfo@kyowakirin.com" underline='always'  target="_blank"
                              onClick={() => handleClickLink("email-adverse-event-dsafety")}
                        > medinfo@kyowakirin.com</Link>.

                        <Box py={0.3}>
                        </Box>
                        or by phone: +44 (0)1896 664000.
                    </Grid>

                </Grid>



            </div>

            <Box py={7}>
            </Box>
            {/*<Grid>*/}
            {/*    <img src="images/CertificateFooter.jpg" width="100%" />*/}
            {/*</Grid>*/}
        </Dialog>
    );
}