import {getToken} from "./authLib";
import config from "../config";

const endpoint = config.analytics_api;

export async function recordEvent(name){
    const token = getToken();

    if(!token) return;  // throw new Error("User not logged in.")

    console.info(`Tracking Event: ${name}`)

    fetch(`${endpoint}/record`, {
        method: 'POST',
        headers: {
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
            event_name: name
        })
    }).then(r => {
        return;
    })
}