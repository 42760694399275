import Dialog from "./dialog";
import React, {useState} from "react";
import Link from "@material-ui/core/Link";
import {Box, Grid} from '@material-ui/core';
import * as analytics from "../libs/analytics";

import Typography from "@material-ui/core/Typography";

const handleClickLink = (name) => {
    analytics.recordEvent(`click-${name}-home`);
}



export function ResourceGallery(props) {

    const { url, title, ...rest } = props;

    return (

        <Dialog {...rest}>

{/*image top banner*/}
            <img src="images/BlankBanner.jpg" width="100%" />


            <div  className="App">

                <Grid container>
                    <Grid item xs={12} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" align="center" >
                        <div className="custom-text-Myriad-Pro-Bold-MegaPrintHeadline" justify='center'>
                            Resource Gallery
                            <Box py={1.5}></Box>
                        </div>
                        <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline" justify='center'>
                            XLH and CRYSVITA materials available from Kyowa Kirin
                            <Box py={1.5}></Box>
                            Please find below a selection of materials available.
                            <Box py={0.0}></Box>
                            If you would be interested in receiving copies of any of the below items,
                            <Box py={0.0}></Box>
                            please contact your local Kyowa Kirin representative.
                            <Box py={1.5}></Box>
                        </div>
                    </Grid>
                </Grid>



{/*top banner text START - if needed*/}
                <Grid
                    container
                    // spacing={0}
                    // direction="column"
                    alignItems="center"
                    justifyContent="center"
                    // style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={10}>
                        <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline"  justify='center'>

                        </div>

                        <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline"  justify='center'>

                        </div>

                        <Typography className="custom-text-Myriad-Pro-Regular-DialogBoxLinkText" align='center'>

                        </Typography>


                        <Box py={1.5}></Box>

                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
{/*top banner text END*/}

{/*two column content block START*/}
                <Grid container  alignItems="center" justifyContent="center" >
                    {/*======================================================================================*/}
                    <Grid item xs={1}>
                        {/*111111*/}
                    </Grid>
                    {/*======================================================================================*/}
                    <Grid item xs={5}>

                        <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline"  justify='center'>

                            <Box py={0.5}></Box>
                            XLH and disease management:
                        </div>

                        <Box py={0.5}></Box>

                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadline"  justify='center'>
                            •<Link href="https://www.dropbox.com/s/hh960f4oye33uxi/SCN11180_XLH%20Working%20group_A4LS_Red%20flags%20for%20XLH%20leaflet_v2_LT.pdf?dl=0" underline='always'  target="_blank"
                              onClick={() => handleClickLink("A PDF")}>Red Flag Signs and Symptoms of XLH in Paediatric Patients</Link>  <Box py={0.0}></Box>

                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/q0jyt7ltn94gugj/SCN11180_XLH%20Working%20group%202022_A4_Assessment%20Algorithm%20leaflet_v0.5_PS.pdf?dl=0" underline='always'  target="_blank"
                              onClick={() => handleClickLink("A PDF")}>Assessment Algorithm for Paediatric Patients with</Link> <Box py={0.0}></Box>
                             <Link href="https://www.dropbox.com/s/1whfvhw73aaqu23/SCN11180_XLH%20Working%20group_A4_Assessment%20Algorithm%20leaflet_v3_LT.pdf?dl=0" underline='always'  target="_blank"
                                     onClick={() => handleClickLink("A PDF")}>Suspected XLH</Link> <Box py={0.0}></Box>

                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/gemcaxzvjmn1m2r/SCN11180_XLH_Monitoring_ALGORITHM_FINAL.pdf?dl=0" underline='always'  target="_blank"
                              onClick={() => handleClickLink("A PDF")}>Monitoring Algorithm for Paediatric Patients Diagnosed</Link> <Box py={0.0}></Box>
                            <Link href="https://www.dropbox.com/s/gemcaxzvjmn1m2r/SCN11180_XLH_Monitoring_ALGORITHM_FINAL.pdf?dl=0" underline='always'  target="_blank"
                                     onClick={() => handleClickLink("A PDF")}>with XLH According to Biochemical/Clinical Changes</Link> <Box py={0.0}></Box>
                            <Link href="https://www.dropbox.com/s/gemcaxzvjmn1m2r/SCN11180_XLH_Monitoring_ALGORITHM_FINAL.pdf?dl=0" underline='always'  target="_blank"
                                     onClick={() => handleClickLink("A PDF")}>while Receiving Conventional Treatment</Link> <Box py={0.0}></Box>

                            {/*<Link href="pdf/resources/SCN11180_XLH Working group_A4_Assessment Algorithm leaflet_v3_LT.pdf" underline='always'  target="_blank"*/}
                            {/*         onClick={() => handleClickLink("A PDF")}>kjlkjlkj</Link> <Box py={0.0}></Box>*/}

                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/vwn4kqeauv0d61e/XLH%20Biochemical%20Monitoring%20Factsheet_v4_APPROVED.pdf?dl=0" underline='always'  target="_blank"
                              onClick={() => handleClickLink("A PDF")}>Biochemical Monitoring Factsheet</Link> <Box py={0.0}></Box>

                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/5jyyo2k6dspizxd/SCN11621_XLH%20Phosphate%20Assays_v5.pdf?dl=0" underline='always'  target="_blank"
                              onClick={() => handleClickLink("A PDF")}>Laboratory Assays for Paediatric Phosphate Ranges</Link> <Box py={0.0}></Box>
                            {/*• Laboratory Assays for Paediatric Phosphate Ranges<Box py={0.0}></Box>*/}

                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/6seje4231myc7ol/XLH%20Case%20Study%201%20Referral%20from%20Primary%20Care.pptx?dl=0" underline='always'  target="_blank"
                              onClick={() => handleClickLink("A PDF")}>XLH Case Study: Supporting Referral from Primary Care</Link> <Box py={0.0}></Box>
                            {/*• XLH Case Study: Supporting Referral from Primary Care<Box py={0.0}></Box>*/}


                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/iyh45oiftbdn7o8/XLH%20Link%20Case%20Study%202_Referral%20from%20Secondary%20Care_v10.pptx?dl=0" underline='always'  target="_blank"
                              onClick={() => handleClickLink("A PDF")}>XLH Case Study: Supporting Referral from Secondary Care</Link> <Box py={0.0}></Box>
                            {/*• XLH Case Study: Supporting Referral from Secondary Care<Box py={0.0}></Box>*/}

                            <Box py={0.5}></Box>

                            {/*•<Link href="" underline='always'  target="_blank"*/}
                            {/*  onClick={() => handleClickLink("A PDF")}>Phosphate homeostasis in XLH: Case study 1</Link> <Box py={0.0}></Box>*/}
                            {/*<Link href="" underline='always'  target="_blank"*/}
                            {/*         onClick={() => handleClickLink("A PDF")}>(for general paediatricians)</Link> <Box py={0.0}></Box>*/}


                            {/*•<Link href="" underline='always'  target="_blank"*/}
                            {/*       onClick={() => handleClickLink("A PDF")}>Phosphate homeostasis in XLH: Case study 2</Link> <Box py={0.0}></Box>*/}
                            {/*<Link href="" underline='always'  target="_blank"*/}
                            {/*      onClick={() => handleClickLink("A PDF")}>(for XLH specialists)</Link> <Box py={0.0}></Box>*/}

                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/pwoqe5zkfzfu1bl/SCN11439_Disease%20Awareness%20Poster%201-4_v7_LT.pdf?dl=0" underline='always'  target="_blank"
                                   onClick={() => handleClickLink("A PDF")}>XLH Disease Awareness Poster series</Link> <Box py={0.0}></Box>
                            {/*•<Link href="pdf/resources/SCN11439_Disease Awareness Poster 2_v7_LT.pdf" underline='always'  target="_blank"*/}
                            {/*  onClick={() => handleClickLink("A PDF")}>XLH Disease Awareness Poster series 1</Link> <Box py={0.0}></Box>*/}
                            {/*•<Link href="pdf/resources/SCN11439_Disease Awareness Poster 3_v7.3_AH.pdf" underline='always'  target="_blank"*/}
                            {/*  onClick={() => handleClickLink("A PDF")}>XLH Disease Awareness Poster series 1</Link> <Box py={0.0}></Box>*/}
                            {/*•<Link href="pdf/resources/SCN11439_Disease Awareness Poster 4_v7_LT.pdf" underline='always'  target="_blank"*/}
                            {/*  onClick={() => handleClickLink("A PDF")}>XLH Disease Awareness Poster series 1</Link> <Box py={0.0}></Box>*/}
                        </div>
                    </Grid>
                    {/*/!*======================================================================================*!/*/}
                    <Grid item xs={1}>
                        <img src="images/dividerBlank.jpg" width="50%" />
                    </Grid>
                    {/*/!*======================================================================================*!/*/}
                    <Grid item xs={5}>

                        <Box py={0.5}></Box>
                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadline"  justify='center'>


                            <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline"  justify='center'>
                                CRYSVITA Information:
                            </div>
                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/cprafvafq13lxic/Global_MOA_Video_Final_v001.mp4?dl=0" underline='always'  target="_blank"
                                   onClick={() => handleClickLink("A PDF")}>CRYSVITA Mode of Action Video</Link> <Box py={0.0}></Box>

                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/zq1sep5jhm0usvu/SCN11436_Crysvita_Adult_KeyFacts_Portrait_v5.pdf?dl=0" underline='always'  target="_blank"
                                   onClick={() => handleClickLink("A PDF")}>CRYSVITA and XLH: Key Facts</Link>  <Box py={0.0}></Box>

                            <Box py={0.5}></Box>

                            •<Link href="https://www.dropbox.com/s/y8n96jvjcwudge5/SCN11436_Crysvita_Paeds_Dosing_Portrait_v2.pdf?dl=0" underline='always'  target="_blank"
                                   onClick={() => handleClickLink("A PDF")}>CRYSVITA Dosing Factsheet for Paediatric</Link> <Box py={0.0}></Box>
                            <Link href="https://www.dropbox.com/s/y8n96jvjcwudge5/SCN11436_Crysvita_Paeds_Dosing_Portrait_v2.pdf?dl=0" underline='always'  target="_blank"
                                  onClick={() => handleClickLink("A PDF")}>Patients with XLH</Link> <Box py={0.0}></Box>


                            <Box py={16.0}></Box>


                        <Box py={2.0}></Box>
                        </div>
                    </Grid>
                </Grid>
{/*two column content block END*/}


                <Box py={1.0}></Box>
                {/*footer text START*/}
                <Grid
                    container
                    // spacing={0}
                    // direction="column"
                    alignItems="center"
                    justifyContent="center"
                    // style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography className="custom-text-Myriad-Pro-Regular-TinyPrintBlack" align='center'>
                            Prescribing Information (please click on your region/country)
                        </Typography>

                        <Typography className="custom-text-Myriad-Pro-Regular-TinyPrintBlack" align='center'>

                            <Link href="pdf/AT Austria PI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>AT</Link> <span></span>
                            <Link href="pdf/BE Belgium.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>BE</Link> <span></span>
                            <Link href="pdf/API Crysvita_Czech_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>CZ</Link> <span></span>
                            <Link href="pdf/CRYSVITA_FI_Stand Mai 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>DE</Link> <span></span>

                            <Link href="pdf/Crysvita DK Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>DK</Link> <span></span>
                            <Link href="pdf/Estonia_SMPC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>EE</Link> <span></span>
                            <Link href="pdf/210719 FTR Crysvita_V04_Spain.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>ES</Link> <span></span>

                            <Link href="pdf/EU PI CRYSVITA.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>EU</Link> <span></span>

                            <Link href="pdf/Crysvita FI Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>FI</Link> <span></span>
                            <Link href="pdf/CRYSVITA PI Full Label_A4_UK_July21_V6_AM.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>GB</Link> <span></span>

                            <Link href="pdf/CRYSVITA Abbreviated Prescribing Information Text_GCC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>GCC</Link> <span></span>

                            <Link href="pdf/HR Crysvita_Croatian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>HR</Link> <span></span>

                            <Link href="pdf/API Crysvita_Hungarian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>HU</Link> <span></span>
                            <Link href="pdf/Prescribing Information IRE  NI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>IE</Link> <span></span>
                            <Link href="pdf/Crysvita 10 20 30 SPC-05-2020-2_Isreal.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>IL</Link> <span></span>
                            <Link href="pdf/LU Luxembourg.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>LU</Link> <span></span>

                            <Link href="pdf/NL Netherlands PI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>NL</Link> <span></span>
                            <Link href="pdf/Crysvita NO Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>NO</Link> <span></span>
                            <Link href="pdf/Burosumab sol for inj (Crysvita) SPC Portugal clean.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>PT</Link> <span></span>
                            <Link href="pdf/API Crysvita_Romanian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>RO</Link> <span></span>
                            <Link href="pdf/Crysvita SE Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>SE</Link> <span></span>

                            <Link href="pdf/API Crysvita_Slovenian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>SI</Link> <span></span>
                        </Typography>
                        {/*</div>*/}

                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                </Grid>
                {/*footer text END*/}



            </div>

            <Box py={2}></Box>

        </Dialog>
    );
}