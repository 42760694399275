import Dialog from "./dialog";
import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import * as analytics from "../libs/analytics";

export function AgendaDialog(props) {

    const {src, type, title, ...rest } = props;

    const handleClickLink = () => {
        // analytics.recordEvent("click-download-agenda-pdf");
    }

    return (
        <Dialog  {...rest}>
            {/*/!*wordly iframe in a div*!/*/}
            {/*    <iframe src="https://attend.wordly.ai/frame/AAAA-0000?bgcolor=33ACFF"*/}

            <Box ml={5} mt={1}>
                <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                    {/*<h1 className="custom-text2"></h1>*/}
                    {/*<Link*/}
                    {/*    href="pdf/SCN11621_XLH Matters_Agenda_A4_Portrait_v0.5_MT.pdf"*/}
                    {/*    target="_blank"*/}
                    {/*    onClick={handleClickLink}*/}
                    {/*>*/}
                    {/*Agenda*/}
                    Agenda
                    {/*</Link>*/}
                </Typography>
            </Box>

            <div style={{ overflowY: "scroll" }}>
                <img src={src} width="100%" style={{minHeight: '80vh', objectFit: "contain"}}  alt=""/>
            </div>

            {/*footer text START*/}
            <Grid
                container
                // spacing={0}
                // direction="column"
                alignItems="center"
                justifyContent="center"
                // style={{ minHeight: '100vh' }}
            >
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={10}>
                    <Typography className="custom-text-Myriad-Pro-Regular-TinyPrintBlack" align='center'>
                        Prescribing Information (please click on your region/country)
                    </Typography>

                    <Typography className="custom-text-Myriad-Pro-Regular-TinyPrintBlack" align='center'>

                        <Link href="pdf/AT Austria PI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>AT</Link> <span></span>
                        <Link href="pdf/BE Belgium.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>BE</Link> <span></span>
                        <Link href="pdf/API Crysvita_Czech_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>CZ</Link> <span></span>
                        <Link href="pdf/CRYSVITA_FI_Stand Mai 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>DE</Link> <span></span>

                        <Link href="pdf/Crysvita DK Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>DK</Link> <span></span>
                        <Link href="pdf/Estonia_SMPC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>EE</Link> <span></span>
                        <Link href="pdf/210719 FTR Crysvita_V04_Spain.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>ES</Link> <span></span>

                        <Link href="pdf/EU PI CRYSVITA.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>EU</Link> <span></span>

                        <Link href="pdf/Crysvita FI Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>FI</Link> <span></span>
                        <Link href="pdf/CRYSVITA PI Full Label_A4_UK_July21_V6_AM.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>GB</Link> <span></span>

                        <Link href="pdf/CRYSVITA Abbreviated Prescribing Information Text_GCC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>GCC</Link> <span></span>

                        <Link href="pdf/HR Crysvita_Croatian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>HR</Link> <span></span>

                        <Link href="pdf/API Crysvita_Hungarian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>HU</Link> <span></span>
                        <Link href="pdf/Prescribing Information IRE  NI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>IE</Link> <span></span>
                        <Link href="pdf/Crysvita 10 20 30 SPC-05-2020-2_Isreal.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>IL</Link> <span></span>
                        <Link href="pdf/LU Luxembourg.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>LU</Link> <span></span>

                        <Link href="pdf/NL Netherlands PI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>NL</Link> <span></span>
                        <Link href="pdf/Crysvita NO Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>NO</Link> <span></span>
                        <Link href="pdf/Burosumab sol for inj (Crysvita) SPC Portugal clean.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>PT</Link> <span></span>
                        <Link href="pdf/API Crysvita_Romanian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>RO</Link> <span></span>
                        <Link href="pdf/Crysvita SE Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>SE</Link> <span></span>

                        <Link href="pdf/API Crysvita_Slovenian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>SI</Link> <span></span>
                    </Typography>
                    {/*</div>*/}

                </Grid>
                <Grid item xs={1}>
                </Grid>
            </Grid>
            {/*footer text END*/}

        </Dialog>
    );
}
