import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    TextField,
    Grid,
    Link, DialogTitle, Typography
} from '@material-ui/core'
import React, {useState} from 'react';
import { navigate } from '@reach/router';
import Dialog from '../components/dialog'
import config from "../config";
import * as analytics from "../libs/analytics";
// import {logout} from "../libs/authLib";

const handleClickLink = (name) => {
    analytics.recordEvent(`click-${name}-loginFromClientPortal`);
}

const codeLength = 5;


export default function LoginClient(){

    const [loading, setLoading] = useState(false);
    const [codeValues, setCodeValues] = useState(Array(codeLength).fill(""));
    const textFields = [];

    const [step, setStep] = useState(0);

    const handleInput = (event) => {
        const index = Number(event.target.id.split('input-')[1]);
        const value = event.target.value;
        const numValue = Number(value.split('').pop());

        if(numValue !== Number.NaN && Number.isInteger(numValue)){
            const tmp = [...codeValues];
            tmp[index] = numValue;
            setCodeValues(tmp);
        }else{
            const tmp = [...codeValues];
            tmp[index] = "";
            setCodeValues(tmp);
            return;
        }

        if(index === codeLength - 1) return;
        const nextId = `input-${index + 1}`;
        const nextInput = document.getElementById(nextId);
        nextInput?.focus();
    }

    for(let i = 0; i < codeLength; i++){
        textFields.push(
            <TextField
                key={i}
                autoFocus={i === 0}
                className="code-input"
                id={`input-${i}`}
                value={codeValues[i]}
                variant="outlined"
                onChange={handleInput}
            />
        )
    }

    const login = async () => {

        setLoading(true);

        try {

            const code = codeValues.toString().replace(/,/g, '');

            const response = await fetch(`${config.auth_api}/login`, {
                method: 'POST',
                body: JSON.stringify({
                    code
                })
            })

            if(response.ok) {
                const result = await response.json();
                localStorage.setItem('access_token', result.token)
                navigate('/login', { replace: true })
            }
        }catch(e){
            console.error(e);
        }

        setLoading(false);
    }



    return (
        <Box bgcolor="primary.main" width="100%" height="100%">
            <Dialog bgcolor="primary.main" open disableClose maxWidth='md' hideBackdrop>
                {/*<DialogTitle>Login with code</DialogTitle>*/}
                <DialogContent>
                    <Box py={1.5}/>
                    <img src="images/bannerLogin2023.svg" width="100%" />


                    <Grid container>
                        <Grid item xs={12} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" align="center" >
                            <Box py={0.5}/>
                            <div className="custom-text-Myriad-Pro-Bold-Big_login"  justify='center'>
                                XLH MATTERS MEETING ON-DEMAND PLATFORM
                                <Box py={1.0}/>
                                CLIENT TEST PORTAL
                                <Box py={1.0}/>
                            </div>

                            {/*<div className="custom-text-Myriad-Pro-Bold-Big_login"  justify='center'>*/}
                            {/*    _____________________________________________________________________________________*/}
                            {/*    <Box py={5.0}/>*/}
                            {/*    The XLH Matters 2021 on-demand platform is currently*/}
                            {/*    <Box py={1.0}/>*/}
                            {/*    being updated and is temporarily unavailable.*/}
                            {/*    <Box py={3.0}/>*/}
                            {/*    Apologies for any inconvenience.*/}
                            {/*    <Box py={1.0}/>*/}

                            {/*</div>*/}

                            {/*<Box py={2.0}/>*/}
                            {/*_____________________________________________________________________________________*/}
                            <div className="custom-text-Myriad-Pro-Light-LoginBoldBlack"  justify='center'>
                                Please enter your 5 digit code:
                                <Box py={1.0}/>
                            </div>

                            <div className="custom-text-Myriad-Pro-Light-LoginBoldBlack"  justify='center'>
                                <Grid bgcolor="primary.main" bgcolor="primary.main" container justify='center' spacing={1}>
                                    {textFields.map((input, index) => (
                                        <Grid key={index} item xs sm={1}>
                                            {input}
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>

                            <Box py={2.0}/>

                            <DialogActions >
                                <Button variant="contained" fullWidth color='primary' disabled={codeValues.length !== codeLength || loading} onClick={login}>CONTINUE TO THE ON-DEMAND PLATFORM</Button>
                            </DialogActions>

                            <Box py={0.5}/>


                        </Grid>

                    </Grid>
                </DialogContent>







            </Dialog>
        </Box>

    )
}