import Dialog from "./dialog";
import React from "react";
import {Typography} from "@material-ui/core";


export function BioDialog(props) {

    const {src, type, title, ...rest } = props;

    return (
        <Dialog {...rest}>

            <Typography color="primary" variant="h5" style={{fontWeight: "bold"}}>
                <h1 className="custom-text2"></h1>
                Speaker Biographies
            </Typography>

            <img src={src} height="100%" style={{minHeight: '80vh', objectFit: "contain"}} />
        </Dialog>
    );
}
