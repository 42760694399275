import Dialog from "./dialog";
import React from "react";
import Link from "@material-ui/core/Link";
import {Box, Grid} from '@material-ui/core';
import * as analytics from "../libs/analytics";
import Typography from "@material-ui/core/Typography";

const handleClickLink = (name) => {
    analytics.recordEvent(`click-${name}-home`);
}

export function CookiePolicy(props) {

    const {url, title, ...rest} = props;

    return (
        <Dialog {...rest}  style={{zIndex: 3000}} >


            <img src="images/BlankBanner.jpg" width="100%"/>


            {/*======================================================================================*/}
            <Grid container>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={10}>
                <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline" justify='center'>
                    <Box py={1.0}></Box>
                    Cookie notice
                    <Box py={0.5}></Box>
                </div>

                <div className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" justify='center'>


                    This cookie notice was last updated in August 2022.



                    <Box py={1.5}></Box>1.	What are cookies?
                    {/*<Box py={0.0}></Box>2.	Who can drop cookies?*/}
                    {/*<Box py={0.0}></Box>3.	How long does a cookie stay on my device?*/}
                    <Box py={0.0}></Box>2.	Types of cookies we use
                    <Box py={0.0}></Box>3.	With whom we share information
                    <Box py={0.0}></Box>4.	Your choices
                    <Box py={0.0}></Box>5.	Contact

                    <Box py={1.0}></Box>

                    <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline" justify='center'>
                    <Box py={0.0}></Box>
                        1. What are cookies?
                    </div>
                    <Box py={0.5}></Box>

                    <Box py={0.0}></Box>
                    Cookies are files that are downloaded on your computer or mobile device when you visit certain websites.
                    Cookies allow a website, for instance, to track, save and gather information about your interactions and browsing habits,
                    recall your preferences or provide users with a tailored experience.

                    <Box py={1.0}></Box>

                    <Box py={0.0}></Box>There are different types of cookies.
                    Essential cookies enable certain functionalities of the website,
                    without these cookies certain parts of the website will not function for you.
                    Non-essential cookies are used to obtain analytics and information about your browsing habits,
                    so we can provide users with content tailored to their preferences.
                    Non-essential cookies can be disabled at any time, and you can continue to browse normally.

                    <Box py={1.0}></Box>
                    First-party cookies are directly stored by the website (or domain) you visit,
                    while third-party cookies are created by domains other than the one you are visiting directly.

                    <Box py={0.0}></Box>If you would like further information about cookies you can visit
                    <Link href="https://www.allaboutcookies.org/" target="_blank"> https://www.allaboutcookies.org/ </Link> <Box py={0.0}></Box>
                    <Box py={1.0}></Box>


                    <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline" justify='center'>
                        <Box py={0.0}></Box>
                        2.	Types of cookies we use
                    </div>
                    <Box py={1.5}></Box>


                    <img src="images/micrositeCookies2.jpg" width="100%"/>



                    <Box py={1.0}></Box>


                    <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline" justify='center'>
                        <Box py={0.0}></Box>
                        3.	With whom we share information
                    </div>
                    <Box py={0.5}></Box>

                    <Box py={0.0}></Box>
                    We share information collected through the use of cookies with technology,
                    hosting and data analytics providers who help us obtain information about your browsing habits and manage our website and content.

                    <Box py={0.5}></Box>

                    <Box py={0.0}></Box>
                    For example, information collected from analytic cookies are transmitted to Mouseflow for the purpose
                    of evaluating your use of our website and providing other services relating to website activity.

                    <Box py={1.0}></Box>





                    <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline" justify='center'>
                        <Box py={0.0}></Box>
                        4.	Your choices
                        <Box py={0.5}></Box>

                    </div>


                    <Box py={0.5}></Box>
                        <div className="custom-text-Myriad-Pro-Bold-DialogBoxLowerTextLight" justify='center'>
                        This website uses a cookie control system allowing you to accept or reject the use of non-essential cookies on your computer/device.
                    </div>


                    <Box py={0.5}></Box>
                    Information captured through cookies is stored for a certain retention period.
                    However, you can eliminate these cookies any time before the expiration date through your browser settings as follows:
                    <Box py={0.5}></Box>

                    <Box py={0.5}></Box>

                    <Link href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target="_blank"> •	Google Chrome </Link> <Box py={0.0}></Box>
                    <Link href="https://support.microsoft.com/en-gb/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank"> •	Microsoft Edge </Link> <Box py={0.0}></Box>
                    <Link href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences" target="_blank"> •	Mozilla Firefox </Link> <Box py={0.0}></Box>
                    <Link href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank"> •	Microsoft Internet Explorer </Link> <Box py={0.0}></Box>
                    <Link href="https://help.opera.com/en/latest/web-preferences/" target="_blank"> •	Opera </Link> <Box py={0.0}></Box>
                    <Link href="https://support.apple.com/en-gb/safari" target="_blank"> •	Apple Safari </Link> <Box py={0.0}></Box>



                    <Box py={0.5}></Box>


                    You can also opt-out of analytics using the Mouseflow browser:
                    <Link href="www.mouseflow.de/opt-out/" target="_blank"> www.mouseflow.de/opt-out/. </Link> <Box py={0.0}></Box>

                    <Box py={1.0}></Box>
                    <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline" justify='center'>
                        <Box py={0.0}></Box>
                        5.	Contact
                    </div>
                    <Box py={0.5}></Box>

                    <Box py={0.0}></Box>If you have questions or concerns regarding our use of cookies you can contact us at:

                    <Box py={0.5}></Box>
                    Kyowa Kirin International Ltd<Box py={0.0}></Box>
                    Att. Legal Department<Box py={0.0}></Box>
                    Galabank Business Park<Box py={0.0}></Box>
                    Galashiels TD1 1QH<Box py={0.0}></Box>
                    Telephone: +44 1896 664 0000<Box py={0.0}></Box>
                    Email:
                    <Link href="mailto:kki-privacy@kyowakirin.com" target="_blank"> kki-privacy@kyowakirin.com</Link>

                    <Box py={1.0}></Box>



                    <Box py={0.0}></Box>DPO contact information:
                    <Link href="mailto:KKIDPOoffice@kyowakirin.com" target="_blank"> KKIDPOoffice@kyowakirin.com</Link>
                    <Box py={0.0}></Box>DPO contact information (Germany):
                    <Link href="mailto:dpoDE@kyowakirin.com" target="_blank"> dpoDE@kyowakirin.com</Link>
                    <Box py={1.0}></Box>
                    <Box py={0.0}></Box>You can also find more information in our
                    <Link href="https://international.kyowa-kirin.com/legal/privacy-notice.html" target="_blank"> Privacy Notice</Link>
                    <Box py={1.5}></Box>
                </div>
            </Grid>
            <Grid item xs={1}>
            </Grid>
            </Grid>
            {/*======================================================================================*/}


            <div className="App">

                <Grid container>

                    {/*======================================================================================*/}
                    <Grid item xs={1}>
                    </Grid>
                    {/*======================================================================================*/}
                    <Grid item xs={10}>



                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>







                        </div>


                    </Grid>



                </Grid>
            </div>

            <Box py={2}></Box>

        </Dialog>
    );
}