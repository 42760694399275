import {
    Button,
    DialogActions,
    DialogContent, DialogTitle, Grid,
    TextField
} from "@material-ui/core";
import Dialog from "./dialog";
import React, {useState} from "react";
import {getToken, hasPermission, parseToken} from "../libs/authLib";
import "easymde/dist/easymde.min.css";
import {IframeDialog} from "./IframeDialog";
import {buildLink} from "../libs/openAudienceLib";
import useCmsString from "../useCmsString";
import config from "../config";
import {recordEvent} from "../libs/analytics";

const url = config.cms_api;

export default function URLImageLink(props){

    const { data, error, revalidate } = useCmsString(props.id)

    const [open, setOpen] = useState(false);
    const [builtLink, setBuiltLink] = useState("");
    const [editOpen, setEditOpen] = useState(false);
    const [value, setValue] = useState();

    const isAdmin = hasPermission("edit_live_image");

    const image_url = data?.value?.image_url || "";
    const room = data?.value?.room || "";
    const analytics_id = data?.value?.analytics_id || "";

    const handleOpenEdit = () => {
        const token = parseToken();
        if(!token.admin){
            console.error("not an admin")
            return;
        }
        setEditOpen(true);
        setValue(data?.value)
    }

    const handleEditCancel = () => {
        setEditOpen(false);
        setValue(data?.value)
    }

    const handleEditConfirm = async () => {
        try {
            const token = getToken();

            await fetch(`${url}/strings/${props.id}`, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    value
                })
            })

            await revalidate();

            setEditOpen(false);
        }catch (e) {
            console.log("Error in update banner value");
            console.error(e);
        }
    }

    const handleChangeValue = (event) => {
        const tmp = {...value};
        tmp[event.target.name] = event.target.value;
        setValue(tmp)
    }

    const imageSrc = image_url || "images/image-placeholder.gif"

    const handleOpenLink = async () => {
        if(analytics_id !== "")
            recordEvent(analytics_id)
        //Chris - I changed this to remove the OA token and just pass a URL
        // const link = await buildLink(room);;
        const link = (room);

        setBuiltLink(link);
        // setBuiltLink("https:www.zonevs.io");

        setOpen(true);
    }

    const handleImageClick = async () => {
        if(isAdmin){
            handleOpenEdit()
        }else {
            await handleOpenLink()
        }
    }

    return (
        <>
            {editOpen && isAdmin && (
                <Dialog disableClose open={true} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        Edit the link to any URL and press confirm to go live
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField label="Image URL" fullWidth name="image_url" variant="outlined" value={value?.image_url || ""} onChange={handleChangeValue} />
                            </Grid>
                            <Grid item xs={12}>
                                <img width="100%" src={value?.image_url || imageSrc} alt="Preview" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Title" fullWidth name="title" variant="outlined" value={value?.title || ""} onChange={handleChangeValue} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Room" fullWidth name="room" variant="outlined" value={value?.room || ""} onChange={handleChangeValue} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Analytics ID" name="analytics_id" fullWidth variant="outlined" value={value?.analytics_id || ""} onChange={handleChangeValue} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditCancel}>
                            Cancel
                        </Button>
                        <Button onClick={handleEditConfirm}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <IframeDialog
                open={open}
                url={builtLink}
                onClose={() => setOpen(false)}
                maxWidth="xl"
                fullWidth
            />
            <img
                className="image-click-region"
                onClick={handleImageClick}
                src={imageSrc}
                {...props}
                style={{
                    ...props.style,
                    border: isAdmin ? "red solid 2px" : "none",
                    cursor: (isAdmin || room) ? "pointer" : "auto",
                    transform: "translate(0, -4px, 0)"
                }}
                alt=""
            />
        </>
    )
}