import Dialog from "./dialog";
import {
    Box,
    Button,
    DialogActions,



} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import React from "react";

export function ExhibitionLinkDialog(props) {

    const { url, title, onClose, ...rest } = props;

    return (
        <Dialog {...rest} onClose={onClose}>
            <img src="images/dialog_exhibitions.jpg" width="100%" />
            <Typography variant="h7" align='center'>
                {/*<Link href="exhibitions" variant="body2" > Click here to go to the Exhibitions</Link>*/}
            </Typography>
            <Box py={1}>
            </Box>

            <DialogActions >
                <Button variant="contained" fullWidth color='primary' onClick={onClose} >OK</Button>
            </DialogActions>
        </Dialog>
    );
}
