import {
    Box,
    Button,
    DialogContent,
    Dialog,
    DialogTitle,
    Grid,
    Paper,
    Typography,
    Switch,
    FormControl, FormLabel, FormGroup, FormControlLabel, DialogActions, Link
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as analytics from "../libs/analytics";
import {CookiePolicy} from "./CookiePolicy";

const defaultOptions = [
    {
        id: "mouse-flow",
        name: "Active",
        accepted: true,
    }
]

const optionInitActions = {
    'mouse-flow': () => {
        // Start Mouseflow
        window.mouseflow.start();
    }
}

export default function CookieConsentPopup(props){

    const [promptOpen, setPromptOpen] = useState(false);
    const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
    const [CookiePolicyOpen, setCookiePolicyOpen] = useState(false);

    const [options, setOptions] = useState(defaultOptions);

    useEffect(() => {
        const status = localStorage.getItem("cookie-consent-status");
        const options = localStorage.getItem("cookie-consent-options");

        if(!status || !options){
            setOptions(defaultOptions);
            setPromptOpen(true);
        }else {
            initialise(JSON.parse(options));
        }
    }, [])

    const initialise = (options) => {
        options.forEach((option) => {
            if(option.accepted){
                try
                {
                    console.log("Initializing: " + option.id);
                    optionInitActions[option.id]();
                }catch (e)
                {
                    console.error("The error below occurred in initializing: " + option.id);
                    console.error(e);
                }
            }else {
                console.log(`Skipping ${option.id} since user has not enabled this option.`)
            }
        })
    }

    const handleConfirmOptions = () => {
        localStorage.setItem("cookie-consent-status", "complete");
        localStorage.setItem("cookie-consent-options", JSON.stringify(options));
        setPromptOpen(false);
        setOptionsMenuOpen(false);
    }
    const handleClose = () => {
        setOptionsMenuOpen(false);
    };

    const handleModifyOptions = () => {
        setOptionsMenuOpen(true);
    }

    const handleChangeOption = (option, accepted) => {
        const temp = [...options];
        const index = temp.findIndex((x) => x.id === option.id);
        temp[index] = {
            ...temp[index],
            accepted
        }
        setOptions(temp);
    }

    const handleClickCookie= () => {
        setCookiePolicyOpen(true);
    }

    const handleConfirmRequiredOnly = () => {
        const temp = [...options];
        temp.forEach((option) => {
            switch (option.id){
                case "mouse-flow":
                {
                    option.accepted = false;
                    break;
                }
            }
        })

        setOptions(temp);
        window.setTimeout(() => {
            handleConfirmOptions();
        }, 500)
    }

    if(!promptOpen && !optionsMenuOpen)
        return null;

    return (
        <>
            <CookiePolicy
                title="Cookie Policy"
                // src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={CookiePolicyOpen}
                onClose={() => setCookiePolicyOpen(false)}
                maxWidth="md"
            />


            <Box
                p={2}
                px={4}
                component={Paper}
                style={{
                    zIndex: 2000,
                    top: 10,
                    left: '50%',
                    transform: "translate(-50%)",
                    position: "absolute"
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="body2">This site uses cookies to improve your online experience and measure
                            traffic to this website. By clicking ACCEPT, you are agreeing to the use of these cookies.
                            You can change your Cookie Preferences at any time. For more information, visit our
                        <Link href="#" onClick={handleClickCookie}> Cookie Notice </Link>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button size="small" variant="contained" style={{backgroundColor: '#f25721', color: 'white'}} onClick={handleConfirmOptions}>ACCEPT </Button>
                    </Grid>
                    <Grid item>
                        <Button size="small" variant="contained" onClick={handleConfirmRequiredOnly}>REQUIRED</Button>
                    </Grid>
                    <Grid item>
                        <Button size="small" variant="contained" style={{backgroundColor: '#f25721', color: 'white'}} onClick={handleModifyOptions}>COOKIE PREFERENCES</Button>
                    </Grid>

                </Grid>
            </Box>
            <Dialog open={optionsMenuOpen} maxWidth='sm' fullWidth  style={{zIndex: 2001,}}  >
                <DialogTitle>Cookie Preferences</DialogTitle>
                <DialogContent>

                    <Typography variant="body2">
                        Please select your cookie preferences below.
                    </Typography>

                    <Box py={1.5}></Box>

                    <Typography variant="Headline">
                        Necessary Cookies
                    </Typography>
                    <Box py={0}></Box>

                    <Typography variant="caption">
                        These are essential for the site and its elements to function as intended.
                    </Typography>
                    <Box py={0}></Box>


                    <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch checked={true} disabled />
                                }
                                label="Always active"

                            />
                        </FormGroup>
                    </FormControl>

                    {/*<Typography variant="caption" color={"f25721"} >*/}
                    {/*    Always active*/}
                    {/*</Typography>*/}

                    <Box py={1.5}></Box>


                        <Typography variant="Headline">
                            Analytical  Cookies
                        </Typography>

                    <Box py={0}></Box>

                        <Typography variant="caption">
                            These cookies are used to analyse how you interact with the site and its elements
                            and will inform future optimisation and maintenance activities for the best possible service.
                        </Typography>

                    <Box py={0}></Box>

                    <FormControl component="fieldset" variant="standard">
                        {/*<FormLabel component="legend">Options</FormLabel>*/}
                        <FormGroup>
                            {options.map((option) => (
                                <FormControlLabel
                                    key={option.id}
                                    control={
                                        <Switch checked={option.accepted} name={option.name}
                                                onChange={(event, checked) => handleChangeOption(option, checked)}
                                        />
                                    }
                                    label={option.name}

                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>

                    <Button size="small" variant="contained" style={{backgroundColor: '#f25721', color: 'white'}} onClick={handleConfirmOptions}>SAVE</Button>
                    <Button size="small" variant="contained"  onClick={handleClose}>CANCEL</Button>
                </DialogActions>
            </Dialog>
        </>

    )
}