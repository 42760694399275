import { ImageMap } from '@qiuz/react-image-map';
import React, {useEffect, useState} from 'react';
import {Backdrop, Box, CircularProgress, Divider, Fade, Hidden, List, ListItem, ListItemText} from '@material-ui/core';
import {navigate} from "@reach/router";
import {MedicalInfoAeMessageDialogSessions} from "../components/MedicalInfoAeMessageDialogSessions";
import * as analytics from "../libs/analytics";
import {BioDialog} from "../components/BioDialog";
import {getLinkData} from "../libs/openAudienceLib";
import OARoomImageLink from "../components/OARoomImageLink";
import OARoomListLink from "../components/OARoomListLink";
import {AgendaDialogBreakouts} from "../components/AgendaDialogBreakouts";
import {CookiePolicy} from "../components/CookiePolicy";
import LiveImageLink from "../components/LiveImageLink";
import {AdverseDialog} from "../components/AdverseDialog";
import URLImageLink from "../components/URLImageLink";
import URLListLink from "../components/URLListLink";
// not used in project
// import TimeBasedImage from "../components/TimeBasedImage";
// import {OAIframeDialog} from "../components/OAIframeDialog";

//todo - we need mobile session links
//todo - special shaped css borders
//todo - css borders on live links
//todo - make sure as many images are preloading as possible
//todo - rename images to match project id
//todo - test analytics on desktop and mobile
//todo - are mobile links changeable?

const mapArea = [
    // {//not used
    //     "id" : "session01",
    //     "width": "27.5%",
    //     "height": "2.0%",
    //     "left": "3.4%",
    //     "top": "37.0%",
    //     "href": "01"
    // },
    // {
    //     "id" : "medicalInfoAe2",
    //     "width": "6.0%",
    //     "height": "9%",
    //     "left": "7.2%",
    //     "top": "59.1%",
    //     "href": "09"
    // },
    // {
    //     "id" : "bio",
    //     "width": "6.0%",
    //     "height": "7.2%",
    //     "left": "7.5%",
    //     "top": "69.1%",
    //     "href": "11"
    // },
    {
        "id" : "back",
        "width": "6.0%",
        "height": "11.5%",
        "left": "7.2%",
        "top": "64.8%",
        "href": "10"
    },
    {
        "id": "agenda",
        "width": "21.5%",
        "height": "34.0%",
        "left": "32.0%",
        "top": "43.5%",
        "href": "01"

    },
    // /////////////
    {
        "id": "medicalInfoAeAT",
        "width": "2.25%",
        "height": "6.0%",
        "left": "17.36%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeBE",
        "width": "2.25%",
        "height": "6.0%",
        "left": "19.75%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeCZ",
        "width": "2.25%",
        "height": "6.0%",
        "left": "22.3%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeDE",
        "width": "2.25%",
        "height": "6.0%",
        "left": "24.85%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeDK",
        "width": "2.25%",
        "height": "6.0%",
        "left": "27.40%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeEE",
        "width": "2.25%",
        "height": "6.0%",
        "left": "29.9%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeES",
        "width": "2.25%",
        "height": "6.0%",
        "left": "32.1%",
        "top": "92.5%",
        "href": "07"
    },
    // /////////////////////////////////////////////////////////////
    {
        "id": "medicalInfoAeEU",
        "width": "2.25%",
        "height": "6.0%",
        "left": "34.35%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeFI",
        "width": "2.25%",
        "height": "6.0%",
        "left": "36.2%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeGB",
        "width": "2.25%",
        "height": "6.0%",
        "left": "38.4%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeGCC",
        "width": "3.25%",
        "height": "6.0%",
        "left": "41.05%",
        "top": "92.5%",
        "href": "07"
    },
/////////////////////////////////////////////////////////////////////
    {
        "id": "medicalInfoAeHR",
        "width": "2.25%",
        "height": "6.0%",
        "left": "44.1%",
        "top": "92.5%",
        "href": "07"
    },

    {
        "id": "medicalInfoAeHU",
        "width": "2.25%",
        "height": "6.0%",
        "left": "46.85%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeIE",
        "width": "2.25%",
        "height": "6.0%",
        "left": "49.1%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeIL",
        "width": "2.25%",
        "height": "6.0%",
        "left": "51.0%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeLU",
        "width": "2.25%",
        "height": "6.0%",
        "left": "53.2%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeNL",
        "width": "2.25%",
        "height": "6.0%",
        "left": "55.7%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeNO",
        "width": "2.25%",
        "height": "6.0%",
        "left": "58.3%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAePT",
        "width": "2.25%",
        "height": "6.0%",
        "left": "60.9%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeRO",
        "width": "2.25%",
        "height": "6.0%",
        "left": "63.45%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeSE",
        "width": "2.25%",
        "height": "6.0%",
        "left": "66.05%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "medicalInfoAeSI",
        "width": "2.25%",
        "height": "6.0%",
        "left": "68.2%",
        "top": "92.5%",
        "href": "07"
    },

    {
        "id": "privacy",
        "width": "4.0%",
        "height": "6.0%",
        "left": "71.25%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "cookie",
        "width": "4.0%",
        "height": "6.0%",
        "left": "76.25%",
        "top": "92.5%",
        "href": "07"
    },
    {
        "id": "adverse",
        "width": "17.0%",
        "height": "5.7%",
        "left": "81.75%",
        "top": "92.6%",
        "href": "07"
    },
]

const baseUrl = "https://openmeet.com/attendee/"

export default function LocalWorkshops2(){

    const [token, setToken] = useState()

    const [loading, setLoading] = useState(2);
    const loaded = () => setLoading(count => count - 1);

    const buildLink = (room) => {
        return `${baseUrl}${room}?i=${token}`;
    }
    useEffect(() => {
        getLinkData().then((token) => {
            setToken(token);
        })
    }, [])

    // const [session01Open, setSession01Open] = useState(false);
    const [medicalInfoAe2Open, setMedicalInfoAe2Open] = useState(false);
    const [bioOpen, setBioOpen] = useState(false);
    const [back, setBackOpen] = useState(false);
    const [agendaOpen, setAgendaOpen] = useState(false);
    const [adverseOpen, setAdverseOpen] = useState(false);

    const [CookiePolicyOpen, setCookiePolicyOpen] = useState(false);

    useEffect(() => {
        //track entry
        analytics.recordEvent("navigate-enter-sessions")

        return () => {
            //track exit
            analytics.recordEvent("navigate-exit-session")
        }
    }, [])

    const onMapClick = async (area) => {
        if(area.id){
            switch (area.id){
                //not used
                // case "session01": {
                //     handleClickSession1();
                //     break;
                // }
                case "medicalInfoAe2": {
                    handleClickMedicalInfoAe2();
                    break;
                }
                case "bio": {
                    handleClickBio();
                    break;
                }
                case "back": {
                    // TODO - I changed this for conformity
                    handleClickHome();
                    // navigate("/")
                }
                case "agenda": {
                    handleClickAgenda();
                    break;
                }
                case "medicalInfoAeAT": {handleClickmedicalInfoAeAT();break;}
                case "medicalInfoAeBE": {handleClickmedicalInfoAeBE();break;}
                // case "medicalInfoAeCH": {handleClickmedicalInfoAeCH();break;}
                case "medicalInfoAeCZ": {handleClickmedicalInfoAeCZ();break;}
                case "medicalInfoAeDE": {handleClickmedicalInfoAeDE();break;}
                //
                case "medicalInfoAeDK": {handleClickmedicalInfoAeDK();break;}
                case "medicalInfoAeEE": {handleClickmedicalInfoAeEE();break;}
                case "medicalInfoAeES": {handleClickmedicalInfoAeES();break;}
                case "medicalInfoAeEU": {handleClickmedicalInfoAeEU();break;}

                case "medicalInfoAeFI": {handleClickmedicalInfoAeFI();break;}
                case "medicalInfoAeGB": {handleClickmedicalInfoAeGB();break;}
                //
                case "medicalInfoAeGCC": {handleClickmedicalInfoAeGCC();break;}
                case "medicalInfoAeHR": {handleClickmedicalInfoAeHR();break;}

                case "medicalInfoAeHU": {handleClickmedicalInfoAeHU();break;}
                case "medicalInfoAeIE": {handleClickmedicalInfoAeIE();break;}
                case "medicalInfoAeIL": {handleClickmedicalInfoAeIL();break;}
                case "medicalInfoAeLU": {handleClickmedicalInfoAeLU();break;}
                //
                case "medicalInfoAeNL": {handleClickmedicalInfoAeNL();break;}
                case "medicalInfoAeNO": {handleClickmedicalInfoAeNO();break;}
                case "medicalInfoAePT": {handleClickmedicalInfoAePT();break;}
                case "medicalInfoAeRO": {handleClickmedicalInfoAeRO();break;}
                case "medicalInfoAeSE": {handleClickmedicalInfoAeSE();break;}
                //
                case "medicalInfoAeSI": {handleClickmedicalInfoAeSI();break;}
                case "privacy": {
                    handleClickPrivacy();
                    break;
                }
                case "cookie": {
                    handleClickCookie();
                    break;
                }
                case "adverse": {
                    handleClickAdverse();
                    break;
                }
            }
        }
    }

    // Replicate this function for each session -- it opens the dialog and tracks the event

    // not used
    // const handleClickSession1 = () => {
    //     setSession01Open(true);
    //     analytics.recordEvent("click-session-1-09:15am")
    // }
    const handleClickMedicalInfoAe2 = () => {
        analytics.recordEvent("click-medicalInfoAe-sessions")
        setMedicalInfoAe2Open(true);
    }
    const handleClickBio = () => {
        analytics.recordEvent("click-bio")
        // setBioOpen(true);
        window.open("pdf/Masterclass biographies_v5.pdf", "_blank")
    }
    const handleClickHome = () => {
        analytics.recordEvent("click-navigate-home-from-sessions")
        navigate("/")
    }
    const handleClickAgenda = () => {
        analytics.recordEvent("click-agenda-breakouts")
        setAgendaOpen(true);
    }
    const handleClickmedicalInfoAeAT= () => {analytics.recordEvent("click-medicalInfoAeAT")
        window.open("pdf/AT Austria PI.pdf", "_blank")}
    const handleClickmedicalInfoAeBE= () => {analytics.recordEvent("click-medicalInfoAeBE")
        window.open("pdf/BE Belgium.pdf", "_blank")}
    // const handleClickmedicalInfoAeCH= () => {analytics.recordEvent("click-medicalInfoAeCH")
    //     window.open("pdf/null.pdf", "_blank")}
    const handleClickmedicalInfoAeCZ= () => {analytics.recordEvent("click-medicalInfoAeCZ")
        window.open("pdf/API Crysvita_Czech_05_2021_approved.pdf", "_blank")}
    const handleClickmedicalInfoAeDE= () => {analytics.recordEvent("click-medicalInfoAeDE")
        window.open("pdf/CRYSVITA_FI_Stand Mai 2021.pdf", "_blank")}
    //
    const handleClickmedicalInfoAeDK= () => {analytics.recordEvent("click-medicalInfoAeDK")
        window.open("pdf/Crysvita DK Prescribing Information - July 2021.pdf", "_blank")}
    const handleClickmedicalInfoAeEE= () => {analytics.recordEvent("click-medicalInfoAeEE")
        window.open("pdf/Estonia_SMPC.pdf", "_blank")}
    const handleClickmedicalInfoAeES= () => {analytics.recordEvent("click-medicalInfoAeES")
        window.open("pdf/210719 FTR Crysvita_V04_Spain.pdf", "_blank")}
    const handleClickmedicalInfoAeEU= () => {analytics.recordEvent("click-medicalInfoAeEU")
        window.open("pdf/EU PI CRYSVITA.pdf", "_blank")}


    const handleClickmedicalInfoAeFI= () => {analytics.recordEvent("click-medicalInfoAeFI")
        window.open("pdf/Crysvita FI Prescribing Information - July 2021.pdf", "_blank")}
    const handleClickmedicalInfoAeGB= () => {analytics.recordEvent("click-medicalInfoAeGB")
        window.open("pdf/CRYSVITA PI Full Label_A4_UK_July21_V6_AM.pdf", "_blank")}
    //
    const handleClickmedicalInfoAeGCC= () => {analytics.recordEvent("click-medicalInfoAeGCC")
        window.open("pdf/CRYSVITA Abbreviated Prescribing Information Text_GCC.pdf", "_blank")}
    const handleClickmedicalInfoAeHR= () => {analytics.recordEvent("click-medicalInfoAeHR")
        window.open("pdf/HR Crysvita_Croatian_05_2021_approved.pdf", "_blank")}

    const handleClickmedicalInfoAeHU= () => {analytics.recordEvent("click-medicalInfoAeHU")
        window.open("pdf/API Crysvita_Hungarian_05_2021_approved.pdf", "_blank")}
    const handleClickmedicalInfoAeIE= () => {analytics.recordEvent("click-medicalInfoAeIE")
        window.open("pdf/Prescribing Information IRE  NI.pdf", "_blank")}
    const handleClickmedicalInfoAeIL= () => {analytics.recordEvent("click-medicalInfoAeIL")
        window.open("pdf/Crysvita 10 20 30 SPC-05-2020-2_Isreal.pdf", "_blank")}
    const handleClickmedicalInfoAeLU= () => {analytics.recordEvent("click-medicalInfoAeLU")
        window.open("pdf/LU Luxembourg.pdf", "_blank")}
    //
    const handleClickmedicalInfoAeNL= () => {analytics.recordEvent("click-medicalInfoAeNL")
        window.open("pdf/NL Netherlands PI.pdf", "_blank")}
    const handleClickmedicalInfoAeNO= () => {analytics.recordEvent("click-medicalInfoAeNO")
        window.open("pdf/Crysvita NO Prescribing Information - July 2021.pdf", "_blank")}
    const handleClickmedicalInfoAePT= () => {analytics.recordEvent("click-medicalInfoAePT")
        window.open("pdf/Burosumab sol for inj (Crysvita) SPC Portugal clean.pdf", "_blank")}
    const handleClickmedicalInfoAeRO= () => {analytics.recordEvent("click-medicalInfoAeRO")
        window.open("pdf/API Crysvita_Romanian_05_2021_approved.pdf", "_blank")}
    const handleClickmedicalInfoAeSE= () => {analytics.recordEvent("click-medicalInfoAeSE")
        window.open("pdf/Crysvita SE Prescribing Information - July 2021.pdf", "_blank")}
    //
    const handleClickmedicalInfoAeSI= () => {analytics.recordEvent("click-medicalInfoAeSI")
        window.open("pdf/API Crysvita_Slovenian_05_2021_approved.pdf", "_blank")}

    const handleClickPrivacy= () => {analytics.recordEvent("click-medicalInfoAeSI")
        window.open("https://international.kyowa-kirin.com/legal/privacy-notice.html", "_blank")}
    const handleClickCookie= () => {
        analytics.recordEvent("click-cookies")
        analytics.recordEvent("click-cookiePolicy")
        setCookiePolicyOpen(true);
        // window.open("https://www.kyowakirinhub.com/en-gb/cookie-notice", "_blank")}
    }
    const handleClickAdverse = () => {
        analytics.recordEvent("click-adverse")
        setAdverseOpen(true);
    }

    if(!token){
        return (
            <Backdrop open={true} style={{zIndex: 99999 }} >
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    return (
        <>
            {/*Preload images and track progress */}
            <img src="images/LocalWorkshops.jpg" style={{display: "none"}} onLoad={() => loaded()} />
            <img src="images/LocalWorkshops_Overlay.png" style={{display: "none"}} onLoad={() => loaded()} />


            {/*Default breakpoints:*/}
            {/*xs, extra-small: 0px*/}
            {/*sm, small: 600px*/}
            {/*md, medium: 960px*/}
            {/*lg, large: 1280px*/}
            {/*xl, extra-large: 1920px*/}

            <MedicalInfoAeMessageDialogSessions
                title="Medical Info"
                // src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={medicalInfoAe2Open}
                onClose={() => setMedicalInfoAe2Open(false)}
                maxWidth="sm"
            />

            <BioDialog
                title="Bios"
                src='images/null.jpg'
                open={bioOpen}
                onClose={() => setBioOpen(false)}
                maxWidth="xl"
                fullWidth
                // fullScreen
            />

            <AgendaDialogBreakouts
                title="Agenda"
                src='images/workshopsAgenda.jpg'
                open={agendaOpen}
                onClose={() => setAgendaOpen(false)}
                maxWidth="md"
                fullWidth
            />

            <CookiePolicy
                title="Cookie Policy"
                // src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                open={CookiePolicyOpen}
                onClose={() => setCookiePolicyOpen(false)}
                maxWidth="md"
            />

            {/*<OAIframeDialog*/}
            {/*//not used*/}
            {/*    title="Parallel Session 1 ***NEED URL***"*/}
            {/*    url={buildLink('nsd_room1')}*/}
            {/*    open={session01Open}*/}
            {/*    onClose={() => setSession01Open(false)}*/}
            {/*    maxWidth="xl"*/}
            {/*    fullWidth*/}
            {/*    // fullScreen*/}
            {/*/>*/}

            <Hidden xsDown>
                <div className="click-region-outer">
                    <div className="click-region-inner">
                        <ImageMap
                            className="click-region"
                            src={"images/LocalWorkshops.jpg"}
                            map={mapArea}
                            onMapClick={onMapClick}

                        />
                        <img
                            className="click-region"
                            src="images/LocalWorkshops_Overlay.png"
                            width="100%"
                            style={{zIndex: 1000, pointerEvents: "none"}}
                        />

                        <AdverseDialog
                            title="Adverse Events"
                            // src='images/EDIT-Prostate Cancer Summit_Agenda-01.jpg'
                            open={adverseOpen}
                            onClose={() => setAdverseOpen(false)}
                            maxWidth="sm"
                        />


                        <div
                            id="image-wrapperURL_LocalWorkshop2b_1"
                            className="click-region"
                            style={{width: '8.0%', height: "16.0%", left: "5.0%", top: "24.0%", zIndex: 1000}}
                        >
                            <URLImageLink id="image-slot-31b" style={{ width: "100%", height: "100%" }} />
                        </div>

                        <div
                            id="image-wrapperURL_LocalWorkshop2b_2"
                            className="click-region"
                            style={{width: '8.0%', height: "16.0%", left: "21.0%", top: "24.0%", zIndex: 1000}}
                        >
                            <URLImageLink id="image-slot-32b" style={{ width: "100%", height: "100%" }} />
                        </div>






                    </div>
                </div>
            </Hidden>
            <Hidden smUp>
                <Box width="100%" height="100%">
                    <img src='images/LocalWorkshops.jpg' width="100%"  alt=""/>

                    <List>
                        <ListItem button onClick={() => navigate('/')}>
                            <ListItemText
                                primary="◁ Back to the Lobby"
                            />
                        </ListItem>
                        <Divider/>

                        <URLListLink
                            id="image-slot-31b"
                            name="Local Workshop 1 - day2"
                        />
                        <URLListLink
                            id="image-slot-32b"
                            name="Local Workshop 2 - day2"
                        />

                        {/*<ListItem button onClick={handleClickSession1}>*/}
                        {/*//not used*/}
                        {/*    <ListItemText*/}
                        {/*        primary="09:15–09:30 Introduction and welcome from our Chairs and UKONS"*/}
                        {/*        secondary="(Sarah Henshaw, Karen Stanley, Sam Wigfall)"*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        {/*<OARoomListLink*/}
                        {/*    id="image-slot-1"*/}
                        {/*    name="Breakfast Session A 08:00-09:00"*/}
                        {/*/>*/}
                        {/*<OARoomListLink*/}
                        {/*    id="image-slot-2"*/}
                        {/*    name="Breakfast Session B 08:00-09:00"*/}
                        {/*/>*/}
                        {/*<OARoomListLink*/}
                        {/*    id="image-slot-3"*/}
                        {/*    name="Lunchtime Session A 13:00-14:00"*/}
                        {/*/>*/}
                        {/*<OARoomListLink*/}
                        {/*    id="image-slot-4"*/}
                        {/*    name="Lunchtime Session B 13:00-14:00"*/}
                        {/*/>*/}
                        {/*<OARoomListLink*/}
                        {/*    id="image-slot-5"*/}
                        {/*    name="Evening Session 13:00-14:00"*/}
                        {/*/>*/}

                        <Divider/>

                        {/*<Divider/>*/}
                        {/*<ListItem button onClick={handleClickMedicalInfoAe2}>*/}
                        {/*    <ListItemText*/}
                        {/*        primary="Prescribing Information"*/}
                        {/*        secondary=""*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                        {/*<ListItem button onClick={handleClickBio}>*/}
                        {/*    /!*not working*!/*/}
                        {/*    /!*    <ListItem button onClick={window.open("pdf/Faculty Biographies Nurse Study Day 2021.pdf", "_blank")}>*!/*/}
                        {/*    <ListItemText*/}
                        {/*        primary="View Faculty Biographies"*/}
                        {/*        secondary=""*/}
                        {/*    />*/}
                        {/*</ListItem>*/}

                    </List>
                </Box>
            </Hidden>
        </>
    )
}