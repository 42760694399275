import Dialog from "./dialog";
import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";


export function PointsDialogDisplay(props) {

    const {src, type, title, ...rest } = props;

    return (
        <Dialog  {...rest}>
            <Typography color="primary" variant="h5" style={{fontWeight: "bold"}}>
                <h1 className="custom-text2"></h1>
                {/*<Link href="pdf/FAQs.pdf" target="_blank">Click here to download the FAQ</Link>*/}
                CPD Credits
            </Typography>

            <Grid container>

                <img src="images/Main_AEPI-popup_header.jpg" width="100%" />
                {/*======================================================================================*/}
                <Grid item xs={2}>
                </Grid>
                {/*======================================================================================*/}
                <Grid item xs={8}>

                    <Box py={2}></Box>

                    {/*<Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>*/}
                    {/*CPD Points*/}
                    {/*</Typography>*/}
                    {/*<Box py={1}></Box>*/}

                    {/*<Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>*/}
                    {/*CPD Points text goes here*/}
                    {/*</Typography> <Box py={0.2}></Box>*/}
                    {/*<Link href="sessions">Click here </Link>*/}
                    {/*to take a look at what sessions are on and join a stream (OA to link to the session room)*/}
                    {/*<Box py={1}></Box>*/}

                    <Typography color="primary" variant="h4" style={{fontWeight: "bold"}}>
                        7 CPD credits available for attendance at all sessions
                        {/*<Box py={1}></Box>*/}
                        {/*( *** this text will be revised *** )*/}
                    </Typography> <Box py={0.2}></Box>
                    {/*CPD Points text goes here*/}
                    <Box py={1}></Box>






                    <Box py={5}></Box>


                </Grid>
                {/*======================================================================================*/}
                <Grid item xs={2}>
                </Grid>
                {/*======================================================================================*/}
            </Grid>


        </Dialog>
    );
}
