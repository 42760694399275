import {
    Button,
    DialogActions,
    DialogContent, DialogTitle, Grid,
    TextField
} from "@material-ui/core";
import useSWR from "swr";
import Dialog from "./dialog";
import React, {useState} from "react";
import {getToken, hasPermission, parseToken} from "../libs/authLib";
import "easymde/dist/easymde.min.css";
import config from "../config";

const url = config.cms_api;

export default function LiveImageLink(props){

    const fetcher = url => fetch(url).then(r => r.json())
    const { data, error, revalidate } = useSWR(`${url}/strings/${props.id}`, fetcher, { refreshInterval: 30000 })

    const [editOpen, setEditOpen] = useState(false);
    const [imageUrlValue, setImageUrlValue] = useState("");

    const isAdmin = hasPermission("edit_live_image");

    const image_url = data?.value?.image_url || "";

    const handleOpenEdit = () => {
        const token = parseToken();
        if(!token.admin){
            console.error("not an admin")
            return;
        }
        setEditOpen(true);
        setImageUrlValue(image_url)
    }

    const handleEditCancel = () => {
        setEditOpen(false);
        setImageUrlValue(image_url)
    }

    const handleEditConfirm = async () => {
        try {
            const token = getToken();

            await fetch(`${url}/strings/${props.id}`, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    value: {
                        image_url: imageUrlValue
                    }
                })
            })

            await revalidate();

            setEditOpen(false);
        }catch (e) {
            console.log("Error in update image value");
            console.error(e);
        }
    }

    const onChangeImage = (event) => {
        setImageUrlValue(event.target.value);
    };

    const imageSrc = image_url || "images/image-placeholder.gif"

    const handleImageClick = async () => {
        if(isAdmin){
            handleOpenEdit()
        }
    }

    return (
        <>
            {editOpen && isAdmin && (
                <Dialog disableClose open={true} maxWidth="sm" fullWidth>
                    <DialogTitle>
                        Edit the link and press confirm to go live
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField label="Image URL" fullWidth variant="outlined" value={imageUrlValue} onChange={onChangeImage} />
                            </Grid>
                            <Grid item xs={12}>
                                <img width="100%" src={imageUrlValue || imageSrc} alt="Preview" />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditCancel}>
                            Cancel
                        </Button>
                        <Button onClick={handleEditConfirm}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <img
                onClick={handleImageClick}
                src={imageSrc}
                {...props}
                style={{
                    ...props.style,
                    pointerEvents: isAdmin ? "auto" : "none",
                    border: isAdmin ? "red solid 2px" : "none",
                    cursor: (isAdmin) ? "pointer" : "auto"
                }}
                alt=""
            />
        </>
    )
}