import useCmsString from "../useCmsString";
import React, {useState} from "react";
import {ListItem, ListItemText} from "@material-ui/core";
import {buildLink} from "../libs/openAudienceLib";
import {IframeDialog} from "./IframeDialog";
import {recordEvent} from "../libs/analytics";
import Link from "@material-ui/core/Link";
import Dialog from "./dialog";
import {Box} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';

export default function URLListLink(props){

    const { url, ...rest } = props;
    const { data, error, revalidate } = useCmsString(props.id)
    const [builtLink, setBuiltLink] = useState("");

    const [open, setOpen] = useState(false);

    const room = data?.value?.room || "";
    const title = data?.value?.title || "";
    const analytics_id = data?.value?.analytics_id || "";

    const handleOpenLink = async () => {
        if(analytics_id !== "")
            recordEvent(analytics_id)

       // // const link = await buildLink(room);
        const link = (room);
       //  console.log(link);
       //  setBuiltLink(link);
       //  setOpen(true);
        window.open(room, '_blank')
    }

    return (
        <>
            {/*{open && (*/}
            {/*    <IframeDialog*/}
            {/*        open={open}*/}
            {/*        url={builtLink}*/}
            {/*        onClose={() => setOpen(false)}*/}
            {/*        maxWidth="xl"*/}
            {/*        fullWidth*/}
            {/*    />*/}
            {/*)}*/}
            {/*<Link href="https://www.crysvita.eu/" underline='always' target="_blank"> Click here to go to www.crysvita.eu/</Link>.*/}

            <ListItem button onClick={handleOpenLink}>
                <ListItemText
                    primary={props.name}
                    secondary={title}
                />
            </ListItem>
        </>

    )
}