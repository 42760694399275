import Dialog from "./dialog";
import {Box} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import React from "react";
import Link from "@material-ui/core/Link";

export function ExternalLinkDialogXlhlink(props) {

    const { url, title, ...rest } = props;

    return (



    <Dialog {...rest}>
        <img src="images/BlankBanner.jpg" width="100%" />
        <Box py={5}>    </Box>
        <Typography className="custom-text-Myriad-Pro-Regular-DialogBoxLinkText" align='center'>Please note: xlhlinkhcp.eu is an external website.
            <Box py={0}>
            </Box>
            <Link href="https://www.xlhlinkhcp.eu/" underline='always' target="_blank"> Click here to go to www.xlhlinkhcp.eu/</Link>.
        </Typography>
        <Box py={8}>
        </Box>
    </Dialog>

    );
}
