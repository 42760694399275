import Dialog from "./dialog";
import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";


export function FaqDialogDisplay(props) {

    const {src, type, title, ...rest } = props;

    return (
        <Dialog  {...rest}>
            <Typography color="primary" variant="h5" style={{fontWeight: "bold"}}>
                <h1 className="custom-text2"></h1>
                {/*<Link href="pdf/FAQs.pdf" target="_blank">Click here to download the FAQ</Link>*/}
                FAQ
            </Typography>

            <Grid container>

                <img src="images/FaqHeader.jpg" width="100%" />
                {/*======================================================================================*/}
                <Grid item xs={2}>
                </Grid>
                {/*======================================================================================*/}
                <Grid item xs={8}>

                    <Box py={2}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                    General FAQs
                    </Typography>
                    <Box py={1}></Box>




                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        How do I get to the main meeting sessions?
                    </Typography> <Box py={0.2}></Box>
                    On the meeting home page, please click on the ‘Main plenary session’ button.
                    {/*or click*/}
                    {/*<Link href="sessions"> here </Link>*/}
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        How do I get to the workshop breakouts?
                    </Typography> <Box py={0.2}></Box>
                    On the meeting home page, please click on the ‘Breakouts’ button or click
                    <Link href="breakouts"> here </Link>
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        How do I know which workshop breakout room to go into?
                    </Typography> <Box py={0.2}></Box>
                    In the ‘Workshop breakout lobby’, accessed via the ‘Breakouts’ button on the homepage, there
                    is a list directing to you to the workshop ‘rooms’. For the workshop on day 1 (Diagnosing XLH:
                    Interactive case studies), please join the ‘room’ for your country region. For the workshops on day 2,
                    please refer to your confirmation email as a reminder of the workshop sessions you chose
                    <Box py={1}></Box>



                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        I’ve lost my email with the details of my chosen workshop sessions on day 2. What do I do?
                    </Typography> <Box py={0.2}></Box>
                    Email
                    <Link href="mailto:XLH@sciterion.com" underline='always' target="_blank"> XLH@sciterion.com </Link>
                    (who can resend the email) or just join the workshop sessions you would
                    like to attend (remember you will have time to attend two of the sessions within the workshop time)
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        How do I attend the local workshop at the end of the day?
                    </Typography> <Box py={0.2}></Box>
                    On the meeting home page, please click on the ‘Local workshops’ button or click
                    <Link href="LocalWorkshops"> here. </Link>
                    Please refer to the ‘Agenda’ on the meeting home page for the countries that the local workshops
                    are available for. If there is a not a workshop available for your country and you would like to
                    speak to Kyowa Kirin please use the ‘Contact Kyowa Kirin’ function on the meeting homepage
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        I have a technical problem. How do I get help?
                    </Typography> <Box py={0.2}></Box>
                    Please click the ‘Technical chat’ box at the welcome desk on the meeting homepage.
                    A technician can answer your queries.
                    Please note that the platform does not work on the Internet Explorer browser
                    <Box py={1}></Box>


                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        How do I contact the meeting organisers? (For logistical questions about the meeting)
                    </Typography> <Box py={0.2}></Box>
                    Please email
                    <Link href="mailto:XLH@sciterion.com" underline='always' target="_blank"> XLH@sciterion.com </Link>
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        How do I contact Kyowa Kirin? (For any other queries)
                    </Typography> <Box py={0.2}></Box>
                    Please click the ‘Contact Kyowa Kirin’ button at the welcome desk on the meeting homepage
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        Where is the meeting agenda?
                    </Typography> <Box py={0.2}></Box>
                    The meeting agenda is available on the meeting home page
                    <Box py={1}></Box>



                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        How do I ask a speaker a question?
                    </Typography> <Box py={0.2}></Box>
                    When you are in the meeting sessions, you can ask questions via the question or chat functions
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        Is the meeting link and password the same for day 2?
                    </Typography> <Box py={0.2}></Box>
                    Yes
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        My colleague wants to attend the meeting. Can they use my password?
                    </Typography> <Box py={0.2}></Box>
                    No, everyone has to be registered to attend the meeting and needs their own link
                    <Box py={1}></Box>


                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        I would like a copy of one of the materials in the ‘Resources’ section. How do I get this?
                    </Typography> <Box py={0.2}></Box>
                    Please click ‘Contact Kyowa Kirin’ and you can request a copy of these
                    (availability of each may depend on your country)
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        Where can I find the prescribing information?
                    </Typography> <Box py={0.2}></Box>
                    The prescribing information can be found at the bottom of the screen. Please select the correct
                    country for the prescribing information
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        How do I get a meeting certificate?
                    </Typography> <Box py={0.2}></Box>
                    Please click ‘Request Meeting Certificate’ at the welcome desk in the lobby
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        How do I give feedback about the event?
                    </Typography> <Box py={0.2}></Box>
                    Please click the ‘Evaluation survey’ button on the information desk. Please note this form
                    autosaves so you can start your feedback on day 1 and return to complete the survey on day 2
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        Is translation of the sessions available?
                    </Typography> <Box py={0.2}></Box>
                    Live translation of the presenter’s speech is available as subtitles. The slides and meeting platform
                    are provided in English only. To access subtitling in the main plenary sessions, please click
                    ‘Language on demand’ at the bottom of the screen. To access subtitling in the workshops, please
                    click the link provided in the chat function. Translation is available to Dutch, Arabic, French, German,
                    Italian, Polish, Portuguese, Romanian, Spanish and Swedish
                    <Box py={1}></Box>





                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        Technical FAQs
                    </Typography>
                    <Box py={1}></Box>


                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        I can’t access the presentations
                    </Typography> <Box py={0.2}></Box>
                    If you’re having issues watching the presentations, your place of work may have blocked access
                    to these live presentations. We suggest you speak to your IT department and request them to
                    fully approve this website
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        I’ve done the steps above but still can’t get access the presentations
                    </Typography> <Box py={0.2}></Box>
                    Please make sure you are not using Internet Explorer to access the meeting (please use Google
                    Chrome or Microsoft Edge). If you still have technical issues accessing the platform, please contact
                    via the ‘Technical chat’ box at the welcome desk on the meeting homepage
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        I want to watch the presentations in maximum screen view
                    </Typography> <Box py={0.2}></Box>
                    Once you gain access to the presentation, to maximise your viewing experience,
                    look for the symbol with the four arrows in your presentation player on the main screen.
                    Click the image to maximise the view
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        I have another access/technical question about this event
                    </Typography> <Box py={0.2}></Box>

                    For all other enquiries, please email
                    <Link href="mailto:xlhmatters@openaudience.com" underline='always' target="_blank"> xlhmatters@openaudience.com </Link>
                    <Box py={1}></Box>

                    <Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>
                        Can I watch the meeting on my phone?
                    </Typography> <Box py={0.2}></Box>
                    The platform for the meeting is only accessible through a computer or a tablet device.
                    It is not compatible with mobile phones
                    <Box py={1}></Box>


                    {/*Slide and video content from the 2021 Masterclass  will be available on the Hub over the coming weeks. Please go to*/}
                    {/*<Typography color="primary"  variant="h7" align='left' >*/}
                    {/*    <Link href="https://www.janssenmedicalcloud.co.uk/oncology/haematology-hub" variant="body2" target="_blank"> https://www.janssenmedicalcloud.co.uk/oncology/haematology-hub.</Link>*/}
                    {/*</Typography>*/}
                    {/*. If you have given your consent to be contacted by email, we will inform you when these are ready to view.*/}
                    {/*<Box py={1}></Box>*/}

                    {/*<Typography color="primary" variant="h7" style={{fontWeight: "bold"}}>*/}
                    {/*    How do I contact the Janssen Medical Information team?*/}
                    {/*</Typography> <Box py={0.2}></Box>*/}
                    {/*Please go to the Janssen Medical Information booth in the Exhibition Hall and you can contact them via email at:*/}
                    {/*<Link href="mailto:medinfo@its.jnj.com" underline='always' target="_blank">medinfo@its.jnj.com</Link>*/}



                    {/*Please share your e-mail permission via this link:*/}

                    {/*<Box py={0}></Box>*/}
                    {/*<Link href="https://www.janssenmedicalcloud.co.uk/stay-in-touch" variant="body2" target="_blank">https://www.janssenmedicalcloud.co.uk/stay-in-touch </Link>*/}
                    {/*and share your contact details with the team.*/}


                    <Box py={1}></Box>



                    <Box py={5}></Box>


                </Grid>
                {/*======================================================================================*/}
                <Grid item xs={2}>
                </Grid>
                {/*======================================================================================*/}
            </Grid>


        </Dialog>
    );
}
