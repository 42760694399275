import Dialog from "./dialog";
import React from "react";
import Link from "@material-ui/core/Link";
import {Box, Grid} from '@material-ui/core';
import * as analytics from "../libs/analytics";
import Typography from "@material-ui/core/Typography";

const handleClickLink = (name) => {
    analytics.recordEvent(`click-${name}-home`);
}

export function RecentPublications(props) {

    const {url, title, ...rest} = props;

    return (
        <Dialog {...rest}>


            <img src="images/BlankBanner.jpg" width="100%"/>
            <Box py={0.5}></Box>

            <Grid container>
                <Grid item xs={12} className="custom-text-Myriad-Pro-Light-DialogBoxLowerTextLight" align="center" >
                    <div className="custom-text-Myriad-Pro-Bold-MegaPrintHeadline" justify='center'>
                        XLH Publications of Interest
                        <Box py={1.5}></Box>
                    </div>
                    <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadline" justify='center'>
                        Please find below publications on XLH that may be of interest.
                        <Box py={0.5}></Box>
                        Please click on the link below the paper to review the full publication.
                        <Box py={1.5}></Box>
                    </div>
                </Grid>
            </Grid>


            {/*======================================================================================*/}



            <div className="App">

                <Grid container>

                    {/*======================================================================================*/}
                    <Grid item xs={1}>
                    </Grid>
                    {/*======================================================================================*/}
                    <Grid item xs={10}>



                        <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadlineSmaller" justify='center'>
                            Patient-reported outcomes from a randomized, active-controlled, open-label, Phase 3 trial of
                            burosumab versus conventional therapy in children with X-linked hypophosphatemia
                        </div>
                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            <Box py={0.0}></Box>
                            Padidela R, et al. <i>Calcif Tissue Int.</i> 2021 Jan 23.
                            doi: 10.1007/s00223-020-00797-x. [Epub ahead of print]
                            <Box py={0.5}></Box>
                        </div>

                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            • This article discusses the patient-reported outcomes from a Phase 3 trial  of burosumab
                             versus conventional therapy in children with XLH
                            <Box py={0.5}></Box>ㅤㅤ
                            <Link href="https://www.dropbox.com/s/h6t6t0au4p1k0s0/2021_Padidela_Calcif%20Tissue%20Int.pdf?dl=0" underline='always'  target="_blank"
                                  onClick={() => handleClickLink("A PDF")}>View this paper here</Link><Box py={1.5}></Box>
                        </div>

                        <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadlineSmaller" justify='center'>
                            Genotype and phenotype analysis in X-linked hypophosphatemia
                        </div>
                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            <Box py={0.0}></Box>
                            Park PG, et al. <i>Front Pediatr. 2021;9:699767.</i> <Box py={0.5}></Box>
                        </div>

                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            •	This study from South Korea analysed genotype–phenotype correlations in patients with XLH and confirmed <i>PHEX</i> mutations
                            <Box py={0.5}></Box>ㅤㅤ
                            <Link href="https://www.dropbox.com/s/d76g29u2fdv75nd/2021_Park_Front%20Pediatr.pdf?dl=0" underline='always'  target="_blank"
                                  onClick={() => handleClickLink("A PDF")}>View this paper here</Link><Box py={1.5}></Box>
                        </div>

                            {/*Hereditary hypophosphatemic rickets and craniosynostosis*/}
                            {/*<Box py={0.0}></Box>*/}
                            {/*Arenas MA, et al. J Pediatr Endocrinol Metab. 2021. doi: 10.1515/jpem-2021-0042.*/}
                            {/*<Box py={0.5}></Box>ㅤ*/}
                            {/*•	An Argentinian study describing the clinical and auxological characteristics and treatment of children with hypophosphataemic rickets compared with those in children without craniosynostosis*/}
                            {/*<Box py={0.5}></Box>ㅤㅤ*/}
                            {/*<Link href="https://www.dropbox.com/s/kt5luw3pgh1ycy9/2021_Arenas_J%20Pediatr%20Endocrinol%20Metab.pdf?dl=0" underline='always'  target="_blank"*/}
                            {/*      onClick={() => handleClickLink("A PDF")}>View this paper here</Link><Box py={1.5}></Box>*/}

                        <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadlineSmaller" justify='center'>
                            X-linked hypophosphatemic rickets:  Multisystemic disorder in children requiring multidisciplinary management
                        </div>
                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            <Box py={0.0}></Box>
                            Baroncelli GI, Mora S. <i>Front Endocrinol (Lausanne). 2021;12:688309.</i> <Box py={0.5}></Box>
                        </div>

                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            •	This Italian review summarises the clinical phenotype, biochemical and radiological findings, treatment and multidisciplinary management of patients with XLH ㅤㅤ
                            <Box py={0.5}></Box>ㅤㅤ
                            <Link href="https://www.dropbox.com/s/d1svfjto7ybdyo0/2021_Baroncelli_Front%20Endocrinol%20%28Lausanne%29_with%20PI.pdf?dl=0" underline='always'  target="_blank"
                                  onClick={() => handleClickLink("A PDF")}>View this paper here</Link><Box py={1.5}></Box>
                        </div>

                        <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadlineSmaller" justify='center'>
                            Phenotypic characterization of X-linked hypophosphatemia in pediatric Spanish population
                        </div>
                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            <Box py={0.0}></Box>
                            Rodríguez-Rubio E, et al. <i>Orphanet J Rare Dis. 2021;16:104.</i> <Box py={0.5}></Box>
                        </div>

                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            •	The RenalTube database was used to perform a retrospective analysis of 48 Spanish paediatric patients with XLH confirmed by genetic analysis. The aim of this study was to report the clinical manifestations at diagnosis and follow-up of patients with XLH and facilitate the finding of a potential phenotype-genotype correlation
                            <Box py={0.5}></Box>ㅤㅤ
                            <Link href="https://www.dropbox.com/s/jwpdfh38iiknqn3/2021_Rodr%C3%ADguez-Rubio_Orphanet%20J%20Rare%20Dis.pdf?dl=0f" underline='always'  target="_blank"
                                  onClick={() => handleClickLink("A PDF")}>View this paper here</Link><Box py={1.5}></Box>
                        </div>

                        <div className="custom-text-Myriad-Pro-Bold-MediumPrintHeadlineSmaller" justify='center'>
                            Improving the diagnosis of X-linked hypophosphatemia: recommendations to optimize diagnostic flow and
                            clinician/geneticist cooperation in the Italian clinical practice
                        </div>
                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            <Box py={0.0}></Box>
                            Agolini E, et al. <i>AO. 2021; 8:29–33.</i>  <Box py={0.5}></Box>
                        </div>

                        <div className="custom-text-Myriad-Pro-Regular-MediumPrintHeadlineSmaller" justify='center'>
                            •	This Italian expert opinion paper provides practical recommendations to improve cooperation between clinicians and geneticists to optimise the diagnosis and management of XLH
                            <Box py={0.5}></Box>ㅤㅤ
                            <Link href="https://www.dropbox.com/s/mn27xr43k090412/2021_Agolini_AO.pdf?dl=0" underline='always'  target="_blank"
                                  onClick={() => handleClickLink("A PDF")}>View this paper here</Link><Box py={1.5}></Box>
                        </div>


                    </Grid>
                </Grid>
            </div>

            {/*footer text START*/}
            <Grid
                container
                // spacing={0}
                // direction="column"
                alignItems="center"
                justifyContent="center"
                // style={{ minHeight: '100vh' }}
            >
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={10}>
                    <Typography className="custom-text-Myriad-Pro-Regular-TinyPrintBlack" align='center'>
                        Prescribing Information (please click on your region/country)
                    </Typography>

                    <Typography className="custom-text-Myriad-Pro-Regular-TinyPrintBlack" align='center'>

                        {/*/!*UAE	AE	https://www.dropbox.com/s/0m9be31jo0fompc/UAE%2C%20KUWAIT%2C%20BAHRAIN_CRY%20PIL.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/UAEKUWAITBAHRAIN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    AE*/}
                        {/*</Link> <span></span>*/}

                        {/*Belgium	BE	https://www.dropbox.com/s/pvazjp5y2icypad/BELGIUM_Crysvita_short%20SPC%28nl%29_BE_Dec2022.pdf?dl=0*/}
                        {/*<Link href="pdf/PIs_for_October_2023_update/BE-BELGIUM_Crysvita_shortSPC(nl)_BE_Dec2022.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    BE*/}
                        {/*</Link> <span></span>*/}

                        {/*/!*BULGARIA	BG	https://www.dropbox.com/s/302l4bfp7la0kca/BG-BULGARIA_PI%20for%20XLH%20only_CLEAN.pdf?dl=0*!/*/}
                        <Link href="pdf/PIs_for_October_2023_update/Bulgaria PI for XLH only_BG_07-2023.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            BG
                        </Link> <span></span>

                        {/*/!*BAHRAIN	BH	https://www.dropbox.com/s/0m9be31jo0fompc/UAE%2C%20KUWAIT%2C%20BAHRAIN_CRY%20PIL.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/UAEKUWAITBAHRAIN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    BH*/}
                        {/*</Link> <span></span>*/}

                        {/*Czech Republic	CZ	https://www.dropbox.com/s/wsymbs7ii2aad2i/CZECH%20REP_Crysvita%20short%20SmPC%2020220922.pdf?dl=0*/}
                        <Link href="pdf/PIs_for_October_2023_update/Czech Republic Crysvita short SmPC 20230531.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            CZ
                        </Link> <span></span>

                        {/*/!*Germany	DE	https://www.dropbox.com/s/kzfb0grqapxia9t/GERMANY_2022-10-11_Crysvita_Pflichttext_Stand_09_2022_f%C3%BCr%20extern.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/DE-GERMANY_2022-10-11_Crysvita_Pflichttext_Stand_09_2022_furextern.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    DE*/}
                        {/*</Link> <span></span>*/}

                        {/*/!*Denmark	DK	https://www.dropbox.com/s/7ta3zoykjojv9pj/DENMARK_Crysvita%20Prescribing%20Information%20DK%20Oct%20%282%29.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/DK-DENMARK_CrysvitaPrescribingInformationDKOct(2).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    DK*/}
                        {/*</Link> <span></span>*/}

                        {/*/!*Spain	ES	https://www.dropbox.com/s/apcrxwx895sn13s/SPAIN_220930%20FT%20Crysvita_V08_Spain.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/ES-SPAIN_220930FTCrysvita_V08_Spain.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    ES*/}
                        {/*</Link> <span></span>*/}

                        {/*/!*Finland	FI	https://www.dropbox.com/s/pyqngi9a7ih5szc/FINLAND_Crysvita%20Prescribing%20Information%20FI%20Sept%2022%20%282%29.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/FI-FINLAND_CrysvitaPrescribingInformationFISept22(2).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    FI*/}
                        {/*</Link> <span></span>*/}

                        {/*Greece	GR	https://www.dropbox.com/s/a805euq0ba07z1i/GREECE_Crysvita_el%20prescr%20inform_09.2022.pdf?dl=0*/}
                        <Link href="pdf/PIs_for_October_2023_update/Greece CRYSVITA_INJ.SOL_10MG_20MG_30MG_SPC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            GR
                        </Link> <span></span>

                        {/*Croatia	HR	https://www.dropbox.com/s/anv6b16bm4oics0/CROATIA_Crysvita_skraceni%20SPC_rev%2021.9.pdf?dl=0*/}
                        <Link href="pdf/PIs_for_November_2023_updateB/CROATIA_English_Crysvita_abbreviated SPC_rev 31.5.2023_For platform" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            HR
                        </Link> <span></span>

                        {/*Hungary	HU	https://www.dropbox.com/s/f8ue7if3nu8dkee/HUNGARY_PI%20for%20XLH%20only_Updated%20for%20Oct%202022_1772_Hungary_draft_HUversion.pdf?dl=0*/}
                        <Link href="pdf/PIs_for_November_2023_update/Crysvita_ XLH only_July2023_Hungary_abbrSmPC_HUversion_V2.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            HU
                        </Link> <span></span>

                        {/*/!*Ireland	IE	https://www.dropbox.com/s/xa96pg2qgcu0cm9/IE-IRELAND_PI%20Crysvita%20Aug22%20IRE%20-%20NI%20%2811%29.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/IE-IRELAND_PICrysvitaAug22IRE-NI(11).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    IE*/}
                        {/*</Link> <span></span>*/}

                        {/*Israel	IL	https://www.dropbox.com/s/p6xlulqws8qoqek/ISRAEL_Crysvita-10mg-20mg-30mg_solution-for-injection_SPC_01-2022-V2_Israel.pdf?dl=0*/}
                        <Link href="pdf/PIs_for_October_2023_update/Israel Crysvita-10mg-20mg-30mg_solution-for-injection_SPC_01-2022-V2 (002).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            IL
                        </Link> <span></span>

                        {/*<Link href="pdf/FINAL PIS/UAEKUWAITBAHRAIN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    KW*/}
                        {/*</Link> <span></span>*/}



                        {/*/!*Luxembourg	LU	https://www.dropbox.com/s/p6ghzdq8gcq1qxs/LUXEMBOURG_Crysvita_short%20SPC%28fr%29_BE-LU_Dec202.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/LU-LUXEMBOURG_Crysvita_shortSPC(fr)_BE-LU_Dec202.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    LU*/}
                        {/*</Link> <span></span>*/}

                        {/*/!*Netherlands	NL	https://www.dropbox.com/s/ghgdqtj6sqkmhux/NETHERLANDS_vSmPC%20Crysvita%20NL%20v102022.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/NL-NETHERLANDS_vSmPCCrysvitaNLv102022.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    NL*/}
                        {/*</Link> <span></span>*/}

                        {/*/!*Norway	NO	 https://www.dropbox.com/s/q8nsm204yttzl97/NORWAY_Crysvita%20Prescribing%20Information%20NO%20Oct%2022%20%282%29.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/NO-NORWAY_CrysvitaPrescribingInformationNOOct22(2).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    NO*/}
                        {/*</Link> <span></span>*/}

                        {/*<Link href="pdf/FINAL PIS/QA-QATAROMAN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    OM*/}
                        {/*</Link> <span></span>*/}

                        {/*Poland	PL	https://www.dropbox.com/s/xhfoyy5rrrs9lt1/POLAND_Crysvita%20PL%20Short%20SmPC_XLH_Oct2022.pdf?dl=0*/}
                        <Link href="pdf/PIs_for_October_2023_update/Poland Crysvita PL Short SmPC_XLH_Oct2023.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            PL
                        </Link> <span></span>

                        {/*/!*Portugal	PT	https://www.dropbox.com/s/8t78wlg544fzcqx/PORTUGAL_Crysvita_IECRCM_with%20sections%204.4%264.5_clean_September2022_Portugal.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/PT-PORTUGAL_Crysvita_IECRCM_withsections4.4&4.5_clean_September2022_Portugal.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    PT*/}
                        {/*</Link> <span></span>*/}

                        {/*/!*Qatar	QA	https://www.dropbox.com/s/q6sex4kefzkyows/QATAR%2C%20OMAN_CRY%20PIL.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/QA-QATAROMAN_CRYPIL.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    QA*/}
                        {/*</Link> <span></span>*/}

                        {/*Romania	RO	https://www.dropbox.com/s/xnqwdhid3vsa85k/ROMANIA_Crysvita%20RO%20API.pdf?dl=0*/}
                        <Link href="pdf/PIs_for_October_2023_update/Romania_Crysvita_sSmPC_07.2023.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            RO
                        </Link> <span></span>

                        {/*/!*Saudi Arabia	SA	https://www.dropbox.com/s/ehn43jj6reotsb7/SAUDI_sa-spc.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/SA-SAUDI_sa-spc.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    SA*/}
                        {/*</Link> <span></span>*/}

                        {/*/!*Sweden	SE	https://www.dropbox.com/s/e12nec0jinn2f2s/SWEDEN_Crysvita%20Prescribing%20Information%20SE%20Oct%2022%20%282%29.pdf?dl=0*!/*/}
                        {/*<Link href="pdf/FINAL PIS/SE-SWEDEN_CrysvitaPrescribingInformationSEOct22(2).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>*/}
                        {/*    SE*/}
                        {/*</Link> <span></span>*/}

                        {/*Slovenia	SI	https://www.dropbox.com/s/5btotwllwg9rejo/SLOVENIA_Crysvita_short%20SmPC_10.2022_clean_prepared%2010.2.2023%20%28003%29.pdf?dl=0*/}
                        <Link href="pdf/PIs_for_October_2023_update/Slovenia Crysvita_short SmPC_IA0036_07.2023_clean (002).pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            SI
                        </Link> <span></span>

                        {/*SLOVAKIA	SK*/}
                        <Link href="pdf/PIs_for_November_2023_updateB/SK PI for XLH only_Updated for May 2023_1772_Final edited_CLEAN dt_V2.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>
                            SK
                        </Link> <span></span>

                        {/*<Link href="pdf/AT Austria PI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>AT</Link> <span></span>*/}
                        {/*<Link href="pdf/BE Belgium.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>BE</Link> <span></span>*/}
                        {/*<Link href="pdf/API Crysvita_Czech_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>CZ</Link> <span></span>*/}
                        {/*<Link href="pdf/CRYSVITA_FI_Stand Mai 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>DE</Link> <span></span>*/}

                        {/*<Link href="pdf/Crysvita DK Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>DK</Link> <span></span>*/}
                        {/*<Link href="pdf/Estonia_SMPC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>EE</Link> <span></span>*/}
                        {/*<Link href="pdf/210719 FTR Crysvita_V04_Spain.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>ES</Link> <span></span>*/}

                        {/*<Link href="pdf/EU PI CRYSVITA.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>EU</Link> <span></span>*/}

                        {/*<Link href="pdf/Crysvita FI Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>FI</Link> <span></span>*/}
                        {/*<Link href="pdf/CRYSVITA PI Full Label_A4_UK_July21_V6_AM.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>GB</Link> <span></span>*/}

                        {/*<Link href="pdf/CRYSVITA Abbreviated Prescribing Information Text_GCC.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>GCC</Link> <span></span>*/}

                        {/*<Link href="pdf/HR Crysvita_Croatian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>HR</Link> <span></span>*/}

                        {/*<Link href="pdf/API Crysvita_Hungarian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>HU</Link> <span></span>*/}
                        {/*<Link href="pdf/Prescribing Information IRE  NI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>IE</Link> <span></span>*/}
                        {/*<Link href="pdf/Crysvita 10 20 30 SPC-05-2020-2_Isreal.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>IL</Link> <span></span>*/}
                        {/*<Link href="pdf/LU Luxembourg.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>LU</Link> <span></span>*/}

                        {/*<Link href="pdf/NL Netherlands PI.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>NL</Link> <span></span>*/}
                        {/*<Link href="pdf/Crysvita NO Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>NO</Link> <span></span>*/}
                        {/*<Link href="pdf/Burosumab sol for inj (Crysvita) SPC Portugal clean.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>PT</Link> <span></span>*/}
                        {/*<Link href="pdf/API Crysvita_Romanian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>RO</Link> <span></span>*/}
                        {/*<Link href="pdf/Crysvita SE Prescribing Information - July 2021.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>SE</Link> <span></span>*/}

                        {/*<Link href="pdf/API Crysvita_Slovenian_05_2021_approved.pdf" underline='always'  target="_blank" onClick={() => handleClickLink("A PDF")}>SI</Link> <span></span>*/}

                    </Typography>
                    {/*</div>*/}

                </Grid>
                <Grid item xs={1}>
                </Grid>
            </Grid>
            {/*footer text END*/}
            <Box py={2}></Box>

        </Dialog>
    );
}
