import Dialog from "./dialog";
import {Box} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import React from "react";
import Link from "@material-ui/core/Link";

export function ExternalLinkDialog(props) {

    const { url, title, ...rest } = props;

    return (
        <Dialog {...rest}>
            <img src="images/HaemHub.jpg" width="100%" />
            <Typography className="custom-text-Myriad-Pro-Regular-DialogBoxLinkText" align='center'>Please note: The Haematology Hub is an external website.
                <Box py={0}>
                </Box>
                <Link href="https://www.janssenmedicalcloud.co.uk/oncology/haematology-hub" underline='always' target="_blank"> Click here to go to the Haematology Hub</Link>.
            </Typography>
            <Box py={2}>
            </Box>
        </Dialog>
    );
}
